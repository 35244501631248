﻿#notificationConnStatus {
	position: sticky;
	top: 0px;
	z-index: 999999;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 40px;
	color: $white;
	font-size: $font-12;
	line-height: normal;
	text-align: left;
	background-color: $black;
	padding: 10px 100px;

	@media screen and (max-width: 1690px) {
		padding: 10px 30px;
	}

	@media screen and (min-width: 2076px) {
		margin: 0 auto;
	}

	.close-btn {
		position: relative;
		flex: 0 0 24px;
		height: 24px;
		padding: 0;
		margin: 0;
		background-color: $transparent;
		outline: none;
		border: none;
		color: $transparent;

		&:after,
		&:before {
			width: 24px;
			height: 1px;
			content: "";
			display: inline-block;
			position: absolute;
			right: 0;
			top: 11px;
			background-color: $grey-100;
		}

		&:after {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}
	}

	.msg {
		padding-left: 0;

		&:first-letter {
			text-transform: uppercase;
		}
	}

	.action-btn {
		flex: 0 0 115px;
		height: 32px;
		border: none;
		outline: none;
		background-color: $black;
		color: $white;
		font-size: $font-16;
		line-height: 16px;
		font-weight: 500;
		margin-left: auto;
		padding: 0;
	}
}
