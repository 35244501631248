﻿.PagineMiniWS {
	position: relative;
	z-index: 1001;

	> * {
		font-family: $roboto;
	}

	.page {
		max-width: 100%;

		h1 {
			@include dvu-titlebar();
			height: 22px;
			line-height: 20px;
			margin-top: 24px;
			margin-bottom: 12px;
		}

		h2 {
			@include dvu-titlebar();
			@include dvu-font-regular($font-16);
			line-height: 16px;
			margin-top: 24px;
			margin-bottom: 12px;
			height: auto;
			padding-right: 12px;
		}

		h3 {
			@include dvu-titlebar();
			@include dvu-font-regular($font-14);
			height: 14px;
			line-height: 12px;
			margin-top: 24px;
			margin-bottom: 12px;
			font-weight: bold;
		}

		table {
			margin: 0 12px;
		}
	}

	.text {
		word-wrap: break-word;
		display: block;
	}

	.page, .section {
		display: none;
		line-height: 15px;

		a {
			color: $yellow-300d !important; //DVU DELETE - delete inline style
			&:hover {
				color: $yellow-200d !important;
			}
		}

		p {
			padding: 0px 12px;
			margin: 12px 0px;
		}

		ul, ol {
			margin-left: 27px;
			margin-right: 12px;
			list-style: disc;
		}

		ol {
			list-style: revert-layer;
		}
	}

	.subsection {
		display: none;
		line-height: 15px;
		/*padding: 0 10px;*/
		p {
			padding: 0px 12px;
			margin: 12px 0px;

			b {
				padding: 0px;
			}
		}

		b {
			padding: 0px 12px;
		}

		ol {
			list-style: auto;
			margin-left: 30px;

			li {
				&::marker {
					font-size: $font-16;
				}
			}
		}

		a {
			color: $yellow-300d !important; //DVU DELETE - delete inline style
			&:hover {
				color: $yellow-200d !important;
			}
		}

		.regulationsTable {
			width: 60%;
		}

		//btn on privacy page leading to cookie preferences
		#btnCookiePreference {
			@include dvu-button-small-m();
			float: right;
			margin: 18px 12px 12px auto;
		}

		#PrivacyCookiePolicy {
			display: inline-block;
			width: auto;
		}
		//end region
	}

	.NormalPageContent {
		display: block;
	}

	.title {
		display: block;
		cursor: pointer;
		line-height: 1.6em;
		margin: 4px 0;
		padding-left: 5px;
	}

	.page > .title {
		background-color: $grey-200d;
		cursor: default;
	}

	.section > .title {
		background-color: $grey-150d;
		cursor: default;
	}

	.subsection > .title {
		background-color: $grey-100d;
		cursor: default;
	}

	span {
		color: $yellow-300d;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.27;

		a {
			color: $yellow-300d !important; //DVU DELETE - delete inline style
			&:hover {
				color: $yellow-200d !important;
			}
		}
	}
}

.menulist {
	display: block;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;

	span {
		color: #ffbb03;
		width: 100%;
		line-height: 1.5em;
		cursor: pointer;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		transition: opacity 0.25s;
	}

	.first-level {
		display: block;
		float: left;
		position: relative;
		width: 109px;
		min-width: 80px;
		margin-right: 5px;

		@media screen and (max-width: 1770px) {
			width: 78px;
			min-width: unset;
			font-size: 12px;
		}

		> {
			li {
				position: relative;

				a {
					text-transform: lowercase;
					line-height: 16px;
					padding: 10px;
				}

				&:first-letter {
					text-transform: uppercase;
				}
			}

			ul {
				position: relative;

				&.second-level {
					display: none;
					opacity: 0;

					span, a {
						color: $white;
						font-size: $font-14;
						font-family: $roboto;
						font-weight: 400;

						@media screen and (max-width: 1770px) {
							font-size: 10px;
						}

						&:hover {
							color: $yellow-400d;
						}
					}

					li {
						background-color: $grey-500d;
						border-radius: 0px;
						padding: 0 2px;
						border: 1px solid $grey-400d;
						height: auto;
						text-align: left;
					}
				}
			}
		}
	}

	.second-level > ul.third-level {
		position: absolute;
		left: 100%;
		top: 0;
		width: auto;
		min-width: 150px;
		display: none;
		opacity: 0;
	}

	.first-level:hover > ul.second-level, .second-level:hover > ul.third-level {
		display: block;
		opacity: 1;
		-webkit-animation: menuFadeIn 1s;
		-moz-animation: menuFadeIn 1s;
		animation: menuFadeIn 0.3s;
	}

	.third-level a {
		display: table-cell;
		vertical-align: middle;

		@media screen and (max-width: 1770px) {
			font-size: 10px;
		}

		&:hover {
			color: $yellow-400d;
		}
	}

	a {
		color: $white;
		width: 100%;
		line-height: 1.5em;
	}

	li {
		height: 36px;
		border-radius: 6px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;
		background-color: $grey-300d;
	}

	.first-level.active > li {
		background-color: $yellow-400d;

		&:hover {
			background-color: $yellow-300d;
		}

		a {
			color: $black;
		}
	}

	li:hover {
		background-color: $grey-200d;
	}
}

@-webkit-keyframes menuFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-moz-keyframes menuFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes menuFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
