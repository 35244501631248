.mainMenu {
	background: none;
	height: auto;
	background: $grey-500d;

	.menu {
		background: transparent;
		height: 80px;
		padding-bottom: 8px;
		position: relative;

		@media screen and (min-width: 2076px) {
			width: 1876px;
			margin: 0 auto;
		}

		ul {
			float: initial;

			li {
				padding: 10px 11px 8px 11px;
				float: initial;
				display: inline-block;

				&:first-child {
					padding-left: 0px;
				}

				a {
					color: $white;
					letter-spacing: 1px;

					@media screen and (max-width:1600px) {
						letter-spacing: normal;
					}

					&.menuItemSelected {
						opacity: 1;
						position: relative;
						padding-bottom: 3px;
					}
				}
			}
		}

		.primaryMenu {
			min-width: 1350px;
			padding: 0 100px;

			@media screen and (max-width: 1690px) {
				padding: 0 30px;
			}

			ul {
				li {
					a {
						color: $white;
						letter-spacing: 0.5px;
						@include dvu-font-regular($font-20);
						opacity: 0.9;

						&:hover {
							opacity: 1;
						}

						&.menuItemSelected {
							opacity: 1;
							position: relative;
							padding-bottom: 4px;
						}
					}
				}
			}

			>ul {
				>li {
					>a.menuItemSelected:after {
						content: "";
						position: absolute;
						left: calc(50% - 15px);
						bottom: 0;
						height: 2px;
						width: 30px;
						border-bottom: 2px solid $yellow-400d;
					}
				}
			}
		}		

		.secondaryMenu {
			display: inline-block;
			padding-left: 100px;			

			@media screen and (max-width: 1690px) {
				padding: 0 30px;
			}

			ul {
				clear: left;
				display: inline-block;

				li {
					a {
						color: $white;
						letter-spacing: 0.28px;
						@include dvu-font-regular($font-14);
						opacity: 0.7;

						&:hover {
							opacity: 1;
						}

						&.menuItemSelected {
							opacity: 1;
							position: relative;
							padding-bottom: 4px;

							&:after {
								content: "";
								position: absolute;
								left: calc(50% - 15px);
								bottom: 0;
								height: 2px;
								width: 30px;
								border-bottom: 2px solid $yellow-400d;
							}
						}
					}
				}
			}
		}
	}

	.btnsDepoMybets {
		position: absolute;
		top: 6px;
		right: 0;
		padding-right: 100px;

		@media screen and (max-width: 1690px) {
			padding-right: 30px;
		}

		.myBetsButton {

			@media screen and (max-width: 1550px) {
				width: 86px;
			}
		}

		.depositNowBtn {
			margin-left: 8px;

			@media screen and (max-width: 1550px) {
				width: 86px;
			}
		}
	}

	.oddsMappingToggle {
		float: right;
		color: #868a91;
		padding: 6px 100px 0 0;
		font-size: 13px;
		line-height: 16px;

		&__label {
			&__decimal {
				padding: 0 5px 0 0;
			}
		}
	}
}
.mainHeader {
	&#divHeader {
		min-width: 1008px;
		position: relative;
		z-index: 2000;

		.Top {
			position: relative;
			background: $yellow-400d;

			.Content {

				.mainMenu__header {
					width: 50%;
					display: inline-block;
					text-align: right;
					float: left;
					height: auto;

					@media screen and (max-width:1660px) {
						padding-bottom: 0px;
					}

					@media screen and (max-width:1550px) {
						padding-left: 60px;
					}

					> ul {
						margin: 0;
						position: absolute;
						height: 76px;
						padding: 0;

						> li {
							padding: 45px 16px 10px 16px;

							@media screen and (max-width:1550px) {
								padding: 45px 12px 10px 12px;
							}

							a {
								@include dvu-font-medium($font-20);
								color: $black;
								opacity: 0.7;
								line-height: 22px;
								letter-spacing: 1px;
								text-transform: none;
								display: inline-block;
								padding: 0;

								&:hover {
									opacity: 1;
								}
							}
							//label NEW added as custom class over WA
							.lbNEW {
								@include dvu-new-label;
							}

							.menuItemSelected {
								font-weight: bold;
								opacity: 1;
								padding-bottom: 8px;
								border-bottom: 1px solid $grey-600d;
							}
						}
					}

					> [id$="myBonuses"] {
						text-align: center;
						padding: 0;
						width: 45px;
						height: 36px;
						position: relative;
						top:12px;
						display:inline-block;

						> span {

							a {
								width: 46px;
								height: 11px;
								border-right: 1px solid $grey-200d;
								position: relative;
								margin-top: 16px;
								display: inline-block;
								font-size: 0;

								&::before {
									background-color: $grey-500d;
									height: 24px;
									left: 10px;
									mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/bonus-alt.svg');
									top: -8px;
									position: absolute;
									width: 24px;
									content: '';

									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										mask-image: none;
										background: none;
										background-color: $transparent;
										background-position: center;
										background-repeat: no-repeat;
										background-size: 22px;
										background-image: url('../../images/IEIcons/miobonus.svg');
									}
								}

								&:hover {
									&::before {
										background: $grey-300d;

										@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
											mask-image: none;
											background: none;
											background-color: $transparent;
											background-position: center;
											background-repeat: no-repeat;
											background-size: 22px;
											background-image: url('../../images/IEIcons/miobonus.svg');
										}
									}
								}
							}

							[id$="lblBonusCount"] {
								background: $green-300d;
								width: 16px;
								height: 16px !important;
								line-height: 13px;
								right: 3px;
								font-size: $font-11;
								left: auto;
								bottom: 10px;
								z-index: 0;
								top: 2px;
								border: none;
								padding: 2px;
								font-weight: normal;
								border-radius: 50%;
								position: absolute;
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
