.personalMenu__body {
	&__title {
		display: block;
		font-size: $font-16;
		font-weight: 500;
		letter-spacing: 0.32px;
		height: 28px;
		margin-top: 10px;
		margin-bottom: 15px;
		width: 414px;

		&__name {
			display: inline-block;
			float: left;
			line-height: 28px;
		}

		&__button {
			float: right;

			.btnSaldoVisHid {
				background: $transparent;
				display: inline-block;
				height: 28px;
				margin: 0;
				padding: 0 18px 0 26px;
				position: relative;
				line-height: 28px;
				width: auto;

				&:before {
					@include transition(all 0.35s ease);
					background: $black;
					content: '';
					display: block;
					height: 18px;
					left: 8px;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/visible.svg');
					position: absolute;
					top: 5px;
					width: 18px;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/visible.svg') no-repeat center !important;
						mask-image: none;
					}
				}

				span {
					display: inline-block;
					font-size: 12px;
					padding-left: 3px;
					position: relative;
					bottom: 1px;

					&.hidden {
						display: none;
					}
				}

				&:hover {
					&:before {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/hide.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/hide.svg') no-repeat center !important;
							mask-image: none;
						}
					}

					span {
						color: $black;
					}
				}
			}

			&.hide {
				.btnSaldoVisHid {
					&:before {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/hide.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/hide.svg') no-repeat center !important;
							mask-image: none;
						}
					}

					&:hover {
						&:before {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/visible.svg');

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/visible.svg') no-repeat center !important;
								mask-image: none;
							}
						}
					}
				}
			}
		}

		&__deposit {
			&.depositNowBtn {
				float: right;
				position: relative;
				bottom: 5px;
				right: 4px;

				&:hover {
					bottom: 5px;
					right: 4px;
				}
			}
		}
	}

	&.activeActive {
		.personalMenu__body__title {
			width: calc(100% + 5px);
		}
	}
}
