﻿.mainFooter.footerHCNew {
	@media (max-width: 1350px) {
		.footerLic {
			display: block;
    		width: 100%;
   			margin: auto;	

			.footerLicLeft {
				text-align: center;
			}
		}
	}

	.footerProviders {
		@media (max-width: 1350px) {
			display: none;
		}

	}
}

.embeddedServiceHelpButton {
	.helpButton {
		.helpButtonEnabled, .helpButtonDisabled {
			visibility: hidden;
		}
	}
}