﻿.tblPrintOdds {
	font-size: 12px;

	.cellaInfo {
		padding: 16px 0 5px 10px;
		font-size: $font-12;

		b {
			font-size: $font-14;
		}

		.quoteLnkSelAll {
			line-height: 24px;

			&:hover{
				color:$yellow-300d;
			}
		}
	}

	.cellaDx {
		padding: 16px 10px 12px;
		background-color: $grey-300d;
		border-radius: 6px;
		line-height:16px;

		table {
			width: 100%;

			td {
				padding: 5px 0;
				line-height: 16px;
			}
		}

		label{
			margin-right:3px;
		}

		input[type="checkbox"] {
			margin: 0 5px 3px 0;
		}

		input[type="radio"] {
			margin-bottom: 3px;
		}
	}
}
