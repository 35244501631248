﻿#popupMEX {
	display: none;
	position: fixed;
	_position: absolute; /* hack for internet explorer 6*/
	width: 400px;
	z-index: 15000;
	top:30% !important;
}

.hpMessages {
	padding: 0;
	background-color: $white;
	border-radius: 6px;

	.divTitle {
		text-align: center;
		padding-top: 12px;

		&:after {
			content: '';
			width: 26px;
			height: 26px;
			display: inline-block;
			background: $red-200d;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
		}

		#popupMEXClose {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
			width: 15px;
			height: 15px;
			background-color: $grey-300d;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				IMG {
					display: inline;
					border-radius: 6px;
				}
			}

			&:hover IMG {
				border: 1px solid #fff;
			}
		}
	}

	#divMessaggi {
		color: $grey-300d;

		#divMessaggio {
			min-width: 380px;
			margin: 10px;
			position: relative;

			&:after {
				display: block;
				position: absolute;
				width: 100%;
				height: 45px;
				content: '';
				bottom: 0;
				z-index: 1000;
				background: linear-gradient( 0deg, $white 35%,transparent);
			}

			LI.liCarousel {
				min-width: 380px;
				display: flex;
				flex-direction: column;
				align-items: center;
				overflow-y: scroll !important;
				height: 190px !important;

				&::-webkit-scrollbar {
					width: 0px;
					background: transparent; /* make scrollbar transparent */
				}
			}

			.ltlData {
				@include dvu-font-regular($font-10);
				padding: 10px 0;
				color: $blue-200d;
				order: 2;
			}

			.lblOggetto {
				@include dvu-font-medium($font-16);
				line-height: 18px;
				color: $red-200d;
				order: 1;
			}

			.lblMessage {
				order: 3;
				padding: 0 50px;

				.lblMsgTxt {
					@include dvu-font-regular($font-12);
					color: $grey-300d;
					display: block;
					max-height: 300px;
					max-width: 350px;
					text-align: left;
					letter-spacing: 0.24px;
					line-height: 1.17;
					padding-bottom: 30px;

					a {
						color: $yellow-400d;
					}
				}
			}

			.navBtn {
				position: relative;
				width: 100%;
				height: 190px;
				padding: 0 10px;

				button {
					@include dvu-button-link-small-s($dark-theme: true);
					color: $transparent;
					width: 24px;
					height: 24px;
					top: 50%;
					position: absolute;
					z-index: 1;
					border-radius: 50%;
					background: $grey-200d;

					&.disabled {
						opacity: 0.6;
						pointer-events: none;
					}
				}

				.next {
					right: 0;
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-right.svg')
				}

				.prev {
					left: 0;
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-left.svg')
				}
			}
		}
	}

	.SearchContainerStyle {
		padding: 0 10px 6px 10px;
		width: 65%;
		margin: 0 auto;

		.SearchSectionStyle {
			background-image: none;

			.SearchDescStyle {
				@include dvu-font-regular($font-11);
				color: $red-300d;
				width: 100%;
				letter-spacing: 0.17px;
			}
		}
	}

	.btnClosePopUpMEX {
		text-align: center;
		padding-bottom: 10px;
	}

	.button {
		@include dvu-button-small-m();
	}
}
