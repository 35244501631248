﻿/* in this file, main layout element properties related to positioning */
#aspnetForm {
	height: auto;
}

#divMain {
	width: 100%;
	margin: 16px 0 0 0;
	overflow: initial;
	padding: 0 100px;

	@media screen and (max-width: 1690px) {
		padding: 0 30px;
	}

	@media screen and (min-width: 2076px) {
		margin: 0 auto;
	}
}

.divMainHome {
	width: 100%;
	margin: 0 auto;
	min-height: 80vh;
}

.divMainLive {
	width: 100%;
	margin: 0;
	min-height: initial;
	min-width: 1350px;
	padding: 0 100px;

	#divContent {
		padding-bottom: 0;
	}

	#divHeader {
		padding: 0 12px 0 13px;
	}
}

#tblMainContent {
	width: 100%;
	clear: initial;

	.tdSX {
		width: 258px;
		padding-right: 6px;
		margin: 0px;
		vertical-align: top;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-width: 258px;
		}
	}

	.tdSXOdds {
		width: 26px
	}

	.tdCN,
	.tdCNMaxAccount {
		vertical-align: top;
		height: auto;
		min-width: 762px;
		padding-right: 6px;
	}

	.tdCNMax {
		width: 812px;
		vertical-align: top;
		height: 500px;
		padding: 0;
	}

	.tdCNLive {
		width: 730px;
		vertical-align: top;
		padding: 0 0 0 0;
	}

	.tdCNOdds {
		width: 712px;
		vertical-align: top;
		height: 500px;
	}

	.tdDX {
		width: 258px;
		vertical-align: top;
		padding: 0px;
		margin: 0px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-width: 258px;
		}
	}

	.divCouponHome,
	.divCoupon {
		width: 258px; //fixed width for coupon block functionality
	}
}

#tblMainLive {
	width: 100%;
	clear: initial;

	.tdCNLive {
		width: 100%;
		padding: 0;
		margin: 0;
	}

	.tdDX {
		width: 100%;
		vertical-align: top;
		padding: 0;
	}

	.divCouponHome,
	.divCouponContent {
		width: 100%;
	}
}

#divFooter {
	margin: 0;
	width: 100%;
	min-width: initial;
	clear: none;
}

.mainFooter {
	width: 100%;
	min-width: initial;
}

.mainHeader {
	&#divHeader {
		min-width: initial;
	}
}

.header {
	width: 100%;
	display: none;
}

.nuovoUtente1,
.nuovoUtente2,
.nuovoUtente3 {
	float: none;
	margin: 35px auto 0 auto;

	.sx,
	.dx {
		float: none;
	}
}

.NewUserData {
	width: 100%;
}

@supports (grid-area: auto) {

	.divMainHome {
		margin: 0;
	}

	#tblMainContent {

		.tdSX {
			width: 100%;
			padding:0px;
		}

		.tdCN,
		.tdCNMaxAccount {
			width: 100%;
			vertical-align: top;
			height: auto;
			padding: 0px;
		}

		.tdDX {
			width: 100%;
		}
	}

	.mainFooter {
		width: 100%;
		min-width: initial;
	}

	.mainHeader {
		&#divHeader {
			min-width: initial;
		}
	}

	.header {
		width: 100%;
	}

	.nuovoUtente1,
	.nuovoUtente2,
	.nuovoUtente3 {
		float: none;
		margin: 35px auto 0 auto;

		.sx,
		.dx {
			float: none;
		}
	}
	
	.NewUserData {
		width: 100%;
	}
}
