﻿#divMainEvents {
	.divBblu {
		height: 32px;
		width: 100%;
		position: relative;
		background-color: $yellow-400d;
		display: inline-block;
		border-radius: 6px 6px 0 0;
		padding: 6px 6px 0 16px;
		z-index: 2;

		.ImgGroup {
			float: left;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			margin-left: 12px;
			border: 1px solid $grey-500d;
		}

		.pulsPref {
			height: 20px;
			width: 20px;
			background-color: $grey-300d;
			border-radius: 4px;
			cursor: pointer;
			float: left;
			text-align: center;
			line-height: 24px;

			&:after {
				content: '';
				display: inline-block;
				width: 14px;
				height: 14px;
				mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/o/favorites.svg");
				background-color: $grey-100d;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-color: $transparent;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 16px;
					mask-image: none;
					background-image: url('../../images/IEIcons/favorites.svg');
				}
			}

			&:hover {
				cursor: pointer;
				background-color: $grey-200d;
			}

			.infoMsg {
				outline: none;
				@include dvu-tooltip-default-simple($font-14);
				text-transform: none;
				position: absolute;
				top: 32px;
				left: 15px;
				width: 150px;
				overflow: hidden;
			}

			&.sel {
				&:after {
					mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/favorites.svg");
					background-color: $yellow-400d;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background-color: $transparent;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 16px;
						mask-image: none;
						background-image: url('../../images/IEIcons/favorites_yellow.svg');
					}
				}

				&:hover {
					&:after {
						background-color: $yellow-300d;
					}
				}
			}
		}

		.sezQuota {
			display: inline-block;

			&:hover {
				cursor: default;
			}

			&.oddsEvent {
				width: 75%;
				max-width: 1000px;
				color:black;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;

				SPAN {
					width: 100%;
					padding-left: 6px;
				}
			}

			SPAN {
				font-size: $font-20;
				color: $black;
				line-height: 20px;

				@media screen and (max-width: 1454px) {
					font-size: 16px;
				}
			}
		}

		.divBtnSx {
			display: inline-block;
			float: right;
			line-height: 100%;

			.divBtnGroup {
				border: 1px solid rgba($black, 0.3);
				border-radius: 6px;
				overflow: hidden;
			}

			a {
				display: block;
				width: 20px;
				height: 18px;
				background-size: 12px;
				background-repeat: no-repeat;
				background-position: center center;
				float: right;

				&:hover {
					background-color: $yellow-300d;
				}
			}

			.lnkOddsBack {
				background-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/play.svg");
				transform: rotate( 180deg );
				border-left: none;

				&:hover {
					//background-position: 0px top;
				}
			}

			.lnkOddsPDF {
				background-image: url("../../images/icons/pw365-iconpack/current/system/cond/solid/info.svg");
				position: relative;

				&:before {
					content: '|';
					width: 1px;
					height: 1px;
					display: block;
					color: $black;
					position: absolute;
					left: 0px;
					top: 2px;
					font-size: $font-12;
				}

				&:after {
					content: '|';
					width: 1px;
					height: 1px;
					display: block;
					color: $black;
					position: absolute;
					right: 1px;
					top: 2px;
					font-size: $font-12;
				}

				&:hover {
					//background-position: -25px top;
				}
			}

			.lnkOddsRfh {
				background-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg");
				border-left: none;

				&:hover {
					//background-position: -50px top;
				}
			}

			.lnkOddsSta {

				&:hover {
					//background-position: -75px top;
				}
			}

			.lnkOddsPrn {
				position: relative;
				background-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/print.svg');

				&:before {
					content: '|';
					width: 1px;
					height: 1px;
					display: block;
					color: $black;
					position: absolute;
					left: -1px;
					top: 2px;
					font-size: $font-12;
				}

				&:hover {
					//background-position: -100px top;
				}
			}

			.lnkOddsCls {
				border: 1px solid rgba($black, 0.3);
				border-radius: 6px;
				float: right;
				margin-left: 6px;
				background-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg");
				height: 20px;

				&:hover {
					//background-position: -125px top;
				}
			}
		}
	}
	//Classi quota
	.divBtn {
		width: auto;
		position: relative;
		@include dvu-font-regular($font-11);
		padding: 6px 0 0 6px;
		z-index: 1;
		overflow: hidden;
		background-color: $grey-300d;

		> div:not(.divBtnMaster):not(.prMch_CollapseBtn) {
			float: left;
			width: 7.03%;
			height: 28px;
			line-height: 28px;
			text-align: center;
			padding: 0;
			margin-right: 1px;
			box-sizing: border-box;
			margin-bottom: 1px;

			a {
				text-decoration: none;
				display: inline-block;
				width: 101%;
				letter-spacing: 0.17px;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					width: 1px;
					height: 12px;
					display: inline-block;
					right: 0;
					top: 7px;
					background-color: $grey-150d;
				}
			}

			@media screen and (min-width: 1706px) {
				&:nth-child(14n+1) {
					a:after {
						content: none;
					}
				}
			}

			@media (min-width:1625px) and (max-width:1705px) {
				width: 7.56%;

				&:nth-child(13n+1) {
					a:after {
						content: none;
					}
				}
			}

			@media (min-width:1541px) and (max-width:1624px) {
				width: 8.21%;

				&:nth-child(12n+1) {
					a:after {
						content: none;
					}
				}
			}

			@media (min-width:1455px) and (max-width:1540px) {
				width: 8.96%;

				&:nth-child(11n+1) {
					a:after {
						content: none;
					}
				}
			}
			/*@media (min-width: 1540px) and (max-width:1620px) {
				&:nth-child(12n+1) {
					a:after {
						content: none;
					}
				}
			}

			@media (min-width:1441px) and (max-width: 1639px) {
				width: 10.98%;

				&:nth-child(9n+1) {
					a:after {
						content: none;
					}
				}
			}*/
			@media screen and (max-width: 1454px) {
				width: 9.8%;

				&:nth-child(10n+1) {
					a:after {
						content: none;
					}
				}
			}

			&:hover {
				background-color: $grey-200d;
			}

			&[data-new="True"] {
				position: relative;

				&:after {
					content: 'new';
					display: block;
					background: $green-200d;
					color: $white;
					width: 26px;
					height: 10px;
					position: absolute;
					top: -1px;
					right: 2px;
					line-height: 0.8;
					text-align: center;
					border-radius: 4px;
					box-sizing: border-box;
					font-size: $font-10;
				}
			}
		}

		> div:nth-last-child(2) {
			a::after {
				content: none !important;
			}
		}

		.divBtnMaster {
			/*DVU2 Redesign
			width: 28px;
			height: 28px;
			cursor: pointer;
			display: inline-block;
			border-radius: 4px;
			background-color: $grey-200d;
			position: absolute;
			left: 12px;
			line-height: 33px;
			text-align: center;

			&:after {
				content: '';
				width: 12px;
				height: 12px;
				display: inline-block;
				background-color: $grey-100d;
				mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg");
			}

			&:hover {
				background-color: $grey-150d;
			}

			&.sel {
				&:after {
					mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");
				}
			}*/
		}

		.OddsDetailsMenuItem {
			//test
		}

		.OddsDetailsMenuSelected {
			background-color: $yellow-400d;
			font-weight: 500;
			color: $black;

			&:hover {
				background-color: $yellow-300d !important;
			}

			a {
				border: none !important;
			}

			&[data-new="True"] {
				&:after {
					content: none !important;
				}
			}
		}

		// Styling for collapsing prematch markets
		&.collapsable {
			.prMch_CollapseBtn {		
				position: relative;
				float: left;
				width: 90px;
				height: 22px;
				margin: 3px 0px 6px 6px;
				padding: 0px 0px 0px 6px;
				box-sizing: border-box;
				background: $grey-200d;
				border-radius: 5px;
				line-height: 22px;
				text-align: left;
				cursor: pointer;
	
				&:hover {
					background: $grey-150d;
				}
	
				&::after {
					content: '';
					position: absolute;
					left: 73px;
					top: 5px;
					width: 12px;
					height: 12px;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg');
					background: $grey-100d;
				}
	
				&.collapsed {
					width: 91px;

					&::after {
						left: 75px;
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');
					}
				}
			} 
			
			@mixin prematch-collapsing-manipulation ($marketsPerRow, $Rows: 2) {
				&:not(.collapsed) > div:not(.divBtnMaster):nth-child(-n + #{$marketsPerRow * $Rows + 2}).prMch_CollapseBtn,
				&.collapsed > div:not(.divBtnMaster):not(.prMch_CollapseBtn):not(:nth-child(-n + #{$marketsPerRow * $Rows - 1})) {
					display: none;
				}
				&.collapsed > div:not(.divBtnMaster):not(.prMch_CollapseBtn):not(:nth-child(-n + #{$marketsPerRow * $Rows - 2})) a::after {
					content: none;
				}
			}

			@media screen and (min-width: 1706px) {
				@include prematch-collapsing-manipulation(14);
			}

			@media (min-width:1625px) and (max-width:1705px) {
				@include prematch-collapsing-manipulation(13);
			}

			@media (min-width:1541px) and (max-width:1624px) {
				@include prematch-collapsing-manipulation(12);
			}

			@media (min-width:1455px) and (max-width:1540px) {
				@include prematch-collapsing-manipulation(11);
			}

			@media screen and (max-width: 1454px) {
				@include prematch-collapsing-manipulation(10);
			}
		}
		//End of styling for collapsing prematch markets
	}

	.oddsToolTipTQDetails {
		z-index: 200;
		@include dvu-tooltip-default-simple($font-12);
		position: fixed;
		display: block;
		text-align: left;
		hyphens: auto;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		-o-hyphens: auto;

		&:after {
			content: '';
			width: 25px;
			height: 25px;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
			background-color: $white;
			position: absolute;
			display: inline-block;
			top: -6px;
			left: 18%;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				content: '';
				height: 14px;
				width: 14px;
				background-color: $white;
				position: absolute;
				transform: rotate(45deg);
				top: -7px;
				left: 77px;
			}
		}
	}
}
