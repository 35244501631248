﻿/*This file contains common css properties for central new live widget*/
#live-widget-center-container {
	display: block;
	position: relative;
	background-color: $transparent;
	border-radius: 0px;
	margin: 0 0 8px 0;
	min-height: 100px;

	.HomeCNTitleCentralLiveWidget {
		@include dvu-titlebar();
		display: none;
		border: none;
	}

	.loader_indicator {
		display: none;
		position: absolute;
		width: 50px;
		height: 50px;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		z-index: -1;
		background-color: transparent;
		border: 8px solid rgba(235, 169, 23, 0.2);
		border-left-color: rgba(235, 169, 23, 1);
	}

	.loading {
		.loader_indicator {
			display: block;
		}

		.HomeCNTitleCentralLiveWidget {
			display: block;
		}
	}
}
