﻿#divPopCmb {
	position: absolute;
	background-color: $white;
	padding: 12px 5px 12px 10px;
	width: 180px;
	border-radius: 4px 0 4px 4px;
	display: none;
	max-height:324px;
	z-index: 6000;

	> UL {
		color: $black;
		font-weight: bold;
		font-size: $font-13;
		overflow-y: auto;
		max-height:300px;

		&::-webkit-scrollbar {
			width: 2px;
		}

		&::-webkit-scrollbar-track {
			background: $grey-100d;
		}

		&::-webkit-scrollbar-thumb {
			height: 46px;
			border-radius: 8px;
			background-color: $yellow-200d;
		}

		LI {
			UL {
				padding: 3px 0 6px 0;

				LI {
					font-size: $font-10;
					font-weight: normal;

					A {
						padding-left: 20px;
						text-decoration: none;
						display: inline-block;
						width: 100%;

						&:hover {
							color: $yellow-400d;
						}
					}
				}
			}
		}

		UL LI SPAN {
			cursor: pointer;
		}

		UL .cmbSel A {
			background-color: $yellow-400d;
			color: $white;
			padding: 2px 0 2px 20px;

			&:hover {
				background-color: $yellow-300d;
				color: $white;
			}
		}
	}
}

.divQt {
	#panelSport {
		border: 1px solid $yellow-400d;
		position: relative;

		&:after {
			content: '';
			mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg");
			display: block;
			width: 12px;
			height: 12px;
			background-color: $yellow-400d;
			position: absolute;
			top: -13px;
			right: 10px;
		}
	}
}

#divDett {
	.OddsDetailsSE {
		padding: 0 0 0 10px;
		height: 32px;
		line-height: 32px;
		font-size: $font-20;
		background-color: $yellow-400d;
		color: $black;
		border-radius: 6px 6px 0 0;
	}

	.OddsDetailsMid {

		.OddsDetailsClasseQuotaDett, .OddsDetailsClasseQuotaDettSel {
			background-color: rgba($white, 0.05);
			line-height: 32px;
			position: relative;
			padding-left: 10px;
			margin-bottom: 2px;

			&:after {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				position: absolute;
				top: 8px;
				right: 6px;
				background-color: $grey-150d;
			}

			&:hover {
				cursor: pointer;

				&:after {
					background-color: $grey-100d;
				}
			}
		}

		.OddsDetailsClasseQuotaDett {
			&:after {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg');
			}
		}

		.OddsDetailsClasseQuotaDettSel {
			&:after {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');
			}
		}

		.dgQuoteDett {
			width: auto;
			height: 100%;
			float: right;
			padding-bottom: 4px;

			.oddsTQ {
				line-height: 13px;
				font-size: $font-11;
				font-weight: normal;
				padding: 8px 0 4px;
				width: 72px;
				text-align: center;
			}

			.oddsQ {
				@include dvu-button-small-s();
				width: 72px;
				height: 20px;
				padding: 0;
				line-height: 20px;

				a {
					display: block;
				}
			}

			&.sel {
				.oddsQ {
					background-color: $yellow-400d;
					color: $black;

					&:hover {
						background-color: $yellow-300d;
					}
				}
			}

			.OddGB_0_0, .OddGB_0_1 {
				.oddsQ {
					border-bottom: 2px solid $unica;					
				}
			}

			.OddGB_3_0, .OddGB_3_1 {
				.oddsQ {
					border-bottom: 2px solid $tripla;
				}
			}

			.OddGb_Empty_0, .OddGb_Empty_1 {
				display: none;
			}
		}

		.OddsDetailsQuote {
			text-align: right;
		}
	}

	.SETQCon {
		.SEItem {
			float: left;
			width: 100%;
			padding: 6px 12px;

			&:nth-child(odd) {
				background-color: rgba($white, 0.05);
			}
		}

		.SECQ {
			line-height: 15px;
		}

		.SEOdds {

			.SEOdd {
				float: left;
				text-align: center;
				padding-right: 3px;
				position: relative;

				.SEOddsTQ {
					line-height: 13px;
					font-size: $font-11;
					font-weight: normal;
					padding: 12px 0 4px;
					width: 72px;
				}

				.SEOddLnk {
					@include dvu-button-small-s();
					width: 72px;
					height: 20px;
					padding: 0;
					line-height: 20px;
				}

				&.sel {
					.SEOddLnk {
						background-color: $yellow-400d;
						color: $black;

						&:hover {
							background-color: $yellow-300d;
						}
					}
				}

				&.g {
					display: none;
				}

				&.g0 {
					.SEOddLnk {
						border-bottom: 2px solid $unica;
					}
				}

				&.g3 {
					.SEOddLnk {
						border-bottom: 2px solid $tripla;
					}
				}
			}
		}
	}
}