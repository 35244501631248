div[id$='updTwoFactorAuthenticationUser'] {
  background: $grey-300d;
  border-radius: 6px;
  padding: 24px 90px;
  width: 100%;
  max-width: 555px;
  margin: 36px auto 0;

  .Msg {
    text-align: start;
  }

  .logo {
    width: 160px;
    height: 160px;
    margin: auto;
    margin-bottom: 16px;
    background: url('../../Images/icons/2FA.svg') no-repeat;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .title {
    text-align: center;
    font-size: $font-24;
    line-height: $font-24;
    margin-bottom: 14px;
  }

  .description-1, .description-2 {
    text-align: start;
    font-size: $font-14;
    line-height: $font-16;
  }

  .description-1 {
    color: $white;
    margin-bottom: 8px;
  }

  .description-2 {
    color: $grey-100;
    margin-bottom: 24px;
  }

  input[id$="btnDisable2FA"], input[id$="btnEnable2FA"], input[id$="btnSubmitCode"] {
    width: 100% !important;
  }

  input[id$="btnDisable2FA"] {
    background-color: $transparent !important;

    &:hover {
      background-color: $grey-200d !important;
    }
  }

  input[id$="btnSubmitCode"] {
    margin-top: 20px !important;
  }

  .qr-container {
    background: $white;
    color: $black;
    font-size: $font-12;
    border-radius: 6px;
    padding: 16px 10px;
    margin-bottom: 24px;
    text-align: center;

    img[id$="imgQrCode"] {
      width: 100%;
      max-width: 320px;
    }

    [id$="lblAuthKey"] {
      font-size: $font-14;
      line-height: $font-14;
      font-weight: 500;
      margin: 12px 0;
      display: block;
    }

    .copy-button {
      font-weight: 500;
      border-radius: 4px;
      padding: 9px 12px 9px 42px;
      background-color: $grey-300;
      color: $white;
      display: inline-block;
      position: relative;

      &:hover {
        background-color: $grey-200;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: $white;
        width: 44px;
        height: 100%;
        mask-size: 16px;
        -webkit-mask-size: 16px;
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/copy.svg');
        -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/copy.svg');
      }
    }
  }

  ol {
    list-style: decimal inside;
    text-align: start;
    margin-bottom: 12px;

    li {
      color: $white;
      margin-bottom: 8px;
      line-height: normal;
    }
  }

  input[id$="txtCode"] {
    width: 100%;
    margin-bottom: 4px;
    padding: 0 8px;
    height: 42px;
    font-size: $font-14;
  }

  span:has(.error:not(:empty)) input[id$="txtCode"] {
    border-color: $red-200 !important;
  }

  .error {
    &:empty {
      display: none;
    }

    text-align: start;
    margin-bottom: 4px;
  }
}

div[id$='pnlTwoFactorVerificationPopup'] {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba($black,0.5);
  color: $black;

  > div {
    background-color: $white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 387px;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    overflow: hidden;

    p {
      width: 100%;
      margin-bottom: -2px;

      &.title {
        font-size: 20px;
        margin-bottom: 4px;
      }

      &.error {
        &:empty {
          display: none;
        }

        margin-top: 4px;
        margin-bottom: 0;
      }
    }

    input[type="text"] {
      width: 100%;
      background-color: $white;
      color: $black;
      padding: 0 8px;
      height: 42px;
      font-size: $font-14;
    }

    span:has(.error:not(:empty)) input[type="text"] {
      border-color: $red-200 !important;
    }

    [id$="btnCancelVerification"], [id$="btnConfirmVerification"] {
      min-width: 72px;
      width: auto !important;
    }

    div[id$="prgTwoFactorVerificationPopup"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white,0.5);

      .loader_indicator {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
        background-color: $transparent;
        border: 8px solid rgba($yellow-400,0.2);
        border-left-color: $yellow-400;
      }
    }
  }
}

.labeledInput {
  width: 100%;
  position: relative;

  .label {
    display: none;
    color: $grey-100;
    position: absolute;
    top: 2px;
    left: 2px;
    margin-left: 7px;
  }

  &.focusedOrDirty {
    .label {
      display: initial;
    }

    input {
      padding-top: 10px !important;

      &::placeholder {
        color: $transparent;
      }
    }
  }
}

.invisible {
  display: none !important;
}
