﻿.bonus_page_container {
	position: relative;
	width: 100%;
	margin: 64px auto 0px auto;

	.bonus_page_nav {
		width: 100%;
		height: 28px;
		margin-bottom: 24px;

		> ul {
			display: flex;
			width: 100%;
			height: auto;
			margin: auto;
			padding: 0 33px;

			> li {
				@include dvu-font-regular($font-14);
				margin: 0px 6px 0px 0px;
				height: 28px;
				flex: 1 1 0px;
				background-color: $grey-300d;
				border: none;
				border-radius: 6px;
				text-align: center;
				line-height: 28px;
				overflow: hidden;

				&:hover {
					background-color: $grey-200d;
				}

				&:last-of-type {
					margin-right: 0px;
				}

				&.active {
					background-color: $yellow-400d;
					color: $black;
				}

				a {
					display: block;
					width: 100%;
					height: 100%;

					sup {
						position: relative;
						top: -5px;
						right: 2px;
						display: inline-block;
						width: 14px;
						height: 14px;
						line-height: 15px;
						color: $white;
						border: none;
						border-radius: 50%;
						background-color: $green-200;
					}
				}
			}
		}
	}

	.bonus_tab_content {
		position: relative;

		.bonus_tab_title {
			text-align: center;
			position: absolute;
			top: -85px;
			width: 100%;
			font-size: 20px;
		}

		.active_bonus_message {
			@include dvu-font-regular($font-14);
			margin: 0px auto 24px auto;
			width: 100%;
			line-height: 1.5;
			letter-spacing: 0.28px;
			text-align: center;

			h3 {
				color: $yellow-400d !important;
				font-weight: 500;
			}
		}

		> ul {

			&.attivabile > li {
				background: none;
				border-radius: 0px;

				.bonus_tab_top {
					border-radius: 6px;
					background-color: $grey-300d;
					overflow: hidden;
				}

				.bonus_tab_bottom {
					background: none;
					margin: 12px 0px 0px 0px;
					padding: 0px 12px;

					> p {
						@include dvu-font-medium($font-11);
						line-height: 1.25;
						letter-spacing: 0.18px;
					}

					ul.checkpoints {

						li {
							@include dvu-font-medium($font-12);
							position: relative;
							padding: 0px 0px 0px 30px;
							margin: 10px 0px 0px 0px;
							line-height: 1.17;
							letter-spacing: 0.24px;

							&.achieved:before {
								content: "";
								position: absolute;
								left: 7px;
								bottom: 0px;
								width: 18px;
								height: 18px;
								-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
								-webkit-mask-repeat: no-repeat;
								-webkit-mask-position: center;
								-webkit-mask-size: 18px;
								mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
								mask-repeat: no-repeat;
								mask-position: center;
								mask-size: 18px;
								background-color: $green-200d;
							}

							&.not_achieved:before {
								content: "-";
								position: absolute;
								left: 13px;
							}
						}
					}
				}
			}

			&.storico > li {

				&.achieved,
				&.not_achieved {

					.bonus_tab_banner {
						position: relative;

						&::after {
							content: "";
							position: absolute;
							bottom: 14px;
							right: 16px;
							width: 19px;
							height: 19px;
							border-radius: 50%;
							border: none;
							z-index: 9;
							background: $white;
						}

						&::before {
							content: "";
							position: absolute;
							bottom: 12px;
							right: 14px;
							width: 24px;
							height: 24px;
							z-index: 10;
							-webkit-mask-size: 24px;
							-webkit-mask-position: center;
							-webkit-mask-repeat: no-repeat;
							mask-size: 24px;
							mask-position: center;
							mask-repeat: no-repeat;
						}
					}
				}

				&.achieved {
					border: 1px solid $green-200d;

					.bonus_tab_banner {

						&::before {
							-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg');
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg');
							background: $green-200d;
						}
					}
				}

				&.not_achieved {
					border: 1px solid $red-300d;

					.bonus_tab_banner {

						&::before {
							-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/error.svg');
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/error.svg');
							background: $red-300d;
						}
					}
				}

				.bonus_tab_table {
					display: none;
				}
			}

			&.disponibile > li {

				.bonus_tab_data {

					.bonus_tab_info {
						min-height: 94px;
					}
				}
			}

			> li {
				position: relative;
				width: 100%;
				margin: 0px 0px 15px 0px;
				border: none;
				border-radius: 6px;
				background-color: $grey-300d;
				overflow: hidden;

				.bonus_tab_data {
					position: relative;
					width: 100%;
					height: 100%;

					.bonus_tab_banner {
						width: 100%;
						max-height: 214px;
						overflow: hidden;

						@media screen and (max-width:2075px) {
							max-height: 248px;
						}

						@media screen and (max-width:1690px) {
							max-height: 202px;
						}

						@media screen and (max-width:1655px) {
							max-height: 196px;
						}

						@media screen and (max-width:1368px) {
							max-height: 142px;
						}

						img {
							width: 100%;
							height: auto;
							vertical-align: bottom;
						}
					}

					.bonus_tab_info {
						display: flex;
						justify-content: space-between;
						padding: 12px;
						margin: 0px;
						width: 100%;

						.bonus_expires {
							@include dvu-font-medium($font-11);
							order: 2;
							margin: 0 0 0 35px;
							color: $blue-100d;
							text-align: right;
							font-stretch: condensed;
							line-height: 1.50;
							letter-spacing: 0.17px;

							&:first-line {
								color: $grey-100d;
								font-stretch: normal;
							}
						}

						.bonus_title {
							order: 1;
							flex: 1;

							h4 {
								@include dvu-font-medium($font-14);
								position: relative;
								line-height: 1.14;
								letter-spacing: 0.28px;
								text-align: left;

								span {
									position: absolute;
									right: 0px;
									top: 0px;
									margin-left: 0 !important;
									color: inherit !important;
									text-align: right;
									font-size: $font-11 !important;
									line-height: 1.50;
									letter-spacing: 0.17px;

									strong {
										display: block;
									}
								}
							}

							.bonus_notice {
								@include dvu-font-regular($font-11);
								margin-top: 8px;
								max-width: 60%;
								line-height: 1.27;
								letter-spacing: 0.17px;
								text-align: left;
								color: $grey-100d;

								p {
									position: relative;
									padding-left: 30px;

									&::before {
										content: "";
										position: absolute;
										width: 19px;
										height: 19px;
										z-index: 10;
										left: 0px;
										top: 50%;
										background: $white;
										-webkit-transform: translate(0, -50%);
										transform: translate(0, -50%);
										-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
										-webkit-mask-repeat: no-repeat;
										-webkit-mask-position: center;
										-webkit-mask-size: 19px;
										mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
										mask-repeat: no-repeat;
										mask-position: center;
										mask-size: 19px;
										background-size: 19px;
									}

									a {
										text-decoration: none;
										color: $yellow-300d;

										&:hover {
											color: $yellow-200d;
										}
									}
								}
							}
						}
					}

					.clearfix {
						width: auto;
						display: block;
						height: auto;
						overflow: unset;
						clear: unset;

						&::after {
							clear: none;
							display: none;
						}
					}

					.activate_btn {
						@include dvu-button-green-small-m();
						position: absolute;
						bottom: 12px;
						right: 12px;
					}

					.easyprogress {
						position: relative;
						width: calc(100% - 24px);
						padding-top: 28px;
						margin: 0px 12px;
						overflow: hidden;

						.progress_tooltip {
							@include dvu-font-medium($font-12);
							position: absolute;
							bottom: 10px;
							padding: 0px;
							min-width: 125px;
							height: 20px;
							border: none;
							background-color: $green-200d !important;
							color: $white !important;
							text-align: center;
							letter-spacing: 0.24px;
							line-height: 20px;
							box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.54);

							&.tooltip_left {
								border-radius: 10px 10px 10px 0px;
							}

							&.tooltip_right {
								border-radius: 10px 10px 0px 10px;
							}
						}

						.progress_achieved,
						.progress_remaining {
							float: left;
							height: 2px;
							width: 0px;
							transition: 0.8s;
						}

						.progress_achieved {
							background-color: $yellow-300d !important;
						}

						.progress_remaining {
							background-color: $white !important;
						}
					}

					.bonus_tab_table {
						padding: 12px;
						margin: 0px;
						width: 100%;

						.bonus_table {
							width: 100%;
							border-spacing: 0px;

							tr {
								background: none;
								height: 19px;
								line-height: 19px;
								text-align: center;

								> :first-child {
									text-align: left;
								}

								> :last-child {
									text-align: right;
								}
							}

							thead tr {

								th {
									@include dvu-font-regular($font-10);
									color: $yellow-100d;
									letter-spacing: 0.15px;
									padding: 0px 20px;
								}
							}

							tbody tr {

								&:nth-child(odd) {
									background-color: rgba($grey-200d, 0.6);
								}

								td {
									@include dvu-font-medium($font-11);
									color: $white;
									letter-spacing: 0.17px;
									padding: 0px 20px;
								}
							}
						}
					}

					.table_content_trigger {
						display: block;
						text-align: center;
						padding: 5px 0;

						&::before {
							content: '';
							position: relative;
							display: inline-block;
							left: 0.15em;
							top: 0.15em;
							height: 0.6em;
							width: 0.6em;
							border-style: solid;
							border-color: $white;
							border-width: 2px 2px 0 0;
							transform: rotate(-45deg);
							vertical-align: top;
						}

						&:hover {

							&::before {
								border-color: $yellow-200d;
							}
						}

						&.bottom {
							top: 0;
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
}

.bonus-page-loader {
	display: none;

	img {
		display: none;
	}
}