//PAYMENT PROVIDERS LOGOS INSERT
// @mixin providersLogos - inserts css properties for payment providers logos in footer, in desired order
// $providers - Payment providers are added from left to right, according to appearance. Payment providers name must be equal to PP name contained in image file name.
// $path1, $path2 - Url is built by concating $path1 + PPname + $path2
$providersList: "visa", "mastercard", "maestrocard", "postepay", "paypal", "applepay", "mybank", "onshop", "skrill", "neteller", "banktransfer", "paysafecard";
$providersPath1: "../../images/Footer-payment-providers/footer_";
$providersPath2: "_logo.svg";
$singleLogoWidth: 66px;
$gapBetweenLogos: 6px;
$providersListWidth: ($singleLogoWidth + $gapBetweenLogos) * length($providersList);

@mixin providersLogos($providers, $path1, $path2, $logoWidth, $gapBetween, $positionY: bottom) {
	$urls: ();
	$position-x-all: ();

	@each $provider in $providers {
		$i: index($providers, $provider);
		$url: url($path1 + $provider + $path2);
		$urls: append($urls, $url, comma);
		$position-x: calc(100% - ($logoWidth + $gapBetween) * (length($providers) - $i));
		$position-x-all: append($position-x-all, $position-x, comma);
	}

	background-image: $urls;
	background-position-y: $positionY;
	background-position-x: $position-x-all;
	background-repeat: no-repeat;
};