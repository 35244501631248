﻿.divMainShowEvents {
	.TopDX {
		h3 {
			@include dvu-titlebar();
		}
	}

	.RiquadroSrc {
		div[id$='panForm'] {
			background-color: $grey-300d;
			border-radius: 6px;
			border-collapse: collapse;
			padding: 16px 12px 12px;

			.SearchTitleStyle {
				display: none; //DVIDelete
			}

			ul {
				padding: 0 20px 16px;
				list-style: disc;
			}

			div[id$='updPanel'] {
				.SearchTitleStyle {
					padding: 12px 0;
				}

				.divShowEvents, .divShowEventsMain, .divShowEventsGruppi {
					background-color: $grey-400d;
					border: 1px solid $grey-200d;
					color: $white;
					overflow: auto;
					height: 200px;
					padding: 0 5px;
					border-radius: 6px;
					margin: 0 10px 10px 10px;

					A {
						text-decoration: none;
						color: #FFFFFF;
						font-weight: bold;
					}

					TABLE {
						width: 100%;

						tr {
							td:last-child {
								width: 400px !important;
							}

							td:first-child {
								width: 100px;
							}

							&.divShowEventsSel {
								a {
									color: $yellow-400d;
								}
							}
						}
					}
				}

				.divShowEventsPuls {
					padding: 12px 0;

					input[type="submit"]{
						margin-right:10px;
					}
				}            
			}
		}
	}
}
