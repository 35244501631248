﻿.dvuContainer, .dvuButtonContainer {
	background-color: $grey-300d;
}

.dvuContainer{
	border-radius:6px 6px 0 0;
	padding: 16px 21px 0 19px;
}

.dvuButtonContainer{
	padding: 12px 21px 25px 19px;
	border-radius: 0 0 6px 6px;
	margin-top:0px !important;
	width:100%;
}

.ImpProvvisorio {
	color: $yellow-300d;
}

.ImpProvvisorioLegenda {
	color: $yellow-300d;
	padding-left:15px;
	margin-left:75px;
		
	&:before{
		content:'';
		width:7px;
		height:7px;
		display:inline-block;
		background-color: $yellow-300d;
		margin:0 6px 1px 0;
	}
}

.dgStyle {
	input[id$='btnEliminaConto'] {
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/recycle-bin.svg');
		--webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/recycle-bin.svg');
		width: 18px;
		height: 18px;
		background-color: $grey-150d;

		&:hover {
			background-color: $yellow-200d;
		}
	}

	.dgHdrStyle th,
	.dgItemStyle td,
	.dgAItemStyle td {
		text-align: center;
		padding: 0px 8px;
	}
}

.UserListVerificationStatus {

	span.status {
		float: left;
		width: 16px;
		height: 16px;

		&.status0 {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/question.svg');
			background-color: $grey-150d;
		}

		&.status1 {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/cond/solid/hourglass.svg');
			background-color: $yellow-200d;
		}

		&.status2 {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			background-color: $red-300d;
		}

		&.status3 {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
			background-color: $green-300d;
		}

		&.status4 {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
			background-color: $blue-200d;
		}
	}
}
