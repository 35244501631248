﻿#ctrlTopLeagues {
	height: auto;
	border-radius: 4px;
	min-height: 30px;
	line-height: 30px;
	display: none;
	position: relative;
	margin-bottom: 8px;

	.GroupOddsTitle {
		display: none;
	}

	.head {
		height: 38px;
		line-height: 38px;

		.ChkAllHead {
			display: inline-block;
			left: 18px;
			top: 11px;
			vertical-align: top !important;
		}

		a {
			display: inline-block;
			position: relative;
			left: 10px;
			margin-left: 12px
		}

		#tlTitle {
			@include dvu-titlebar();
			text-transform: capitalize;
			padding: 0px;

			&:hover {
				color: $yellow-200d;
			}
		}

		div {
			position: absolute;
			right: 10px;
			top: 0px;

			a:first-child {
				display: none;
			}

			a.printSelectedTopLeagues {
				display: none;
			}

			a.showSelectedTopLeagues {
				display: none;
				width: 18px;
				height: 18px;
				-webkit-mask-position: 5px center;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-size: 10px;
				top: 0;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-color: transparent;
					background: url("../../images/icons/pw365-iconpack/current/system/regular/solid/play.svg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 12px;
					left: 2px;
				}

				img {
					display: none;
				}
			}
		}
	}

	#liLeague {
		background-color: $grey-400;
		border-radius: 6px;
		padding: 0 20px 20px 18px;

		li {
			display: inline-block;
			white-space: normal;
			line-height: 14px;
			min-height: 21px;
			top: 5px;
			position: relative;
			padding-top: 6px;
			padding-bottom: 6px;
			vertical-align: top;
			width: 25%;
			margin: 0;
			font-size: $font-14;

			@media screen and (max-width: 1485px) {
				width: 33.3%;
			}

			input[type="checkbox"] {
				float: left;
				top: 4px;
			}

			span {
				border: none;
				border-radius: 100%;
				width: 16px;
				height: 16px;
				display: inline-block;
				position: relative;
				top: 4px;
				left: 5px;
				float: left;
				background-size: 16px;
				background-position: center;
			}

			a {
				top: 5px;
				position: relative;
				display: block;
				width: 75%;
				margin-left: 43px;

				&:hover {
					color: $yellow-200d;
				}
			}
		}
	}

	#divButtonForward {
		display: none;
		padding: 0px 20px 15px 20px;
		background-color: $grey-400;
		border-radius: 0px 0px 6px 6px;

		hr {
			border-width: 1px 0px 0px 0px;
			margin-top: -4px;
		}

		.buttonForward {
			@include dvu-button-green-small-m();
			float: right;
			position: absolute;
			right: 20px;
			bottom: 7px;
		}

		#numberSelectedTopLeagues, #singular, #plural {
			top: 5px;
			opacity: 0.8;
			font-family: Roboto;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.28px;
		}

		#numberSelectedTopLeagues {
			float: left;
			line-height: 29px;
		}

		#singular, #plural {
			display: none;
			position: relative;
			left: 5px;
			line-height: 20px;
		}
	}
}

#groupsDivMain {

	#ctrlTopLeagues {
		margin: 0 0 17px 0;

		#liLeague {
			padding: 16px 0 5px 10px;
			background-color: $grey-300d;
			border-radius: 6px;

			li {
				height: 40px;
				width: 20%;
				margin: 0;
				padding: 0 10px 0 0px;
				top: 0;

				@media screen and (max-width: 1630px) {
					width: 25%;
				}

				@media screen and (max-width: 1485px) {
					width: 33.33%;
				}

				input[type="checkbox"] {
					float: left;
					top: 0;
				}

				span {
					display: inline-block;
					left: 6px;
					top: 0px;
				}

				a {
					cursor: pointer;
					color: $white;
					top: 5px;
					position: relative;
					display: block;
					width: 72%;
					@include dvu-font-regular($font-14);
					margin-left: 43px;
					line-height: 16px;
					position: static;

					&:hover {
						color: $yellow-200d;
					}
				}
			}
		}

		.head {
			height: 38px;
			line-height: 38px;
			background: none;

			.ChkAllHead {
				display: inline-block;
				left: 10px;
				vertical-align: middle;
				z-index: 0;
				bottom: 1px;
			}

			a {
				display: inline;
				bottom: auto;
				position: relative;
				left: 2px;
				margin-left: 32px;

				&:not(.printSelectedTopLeagues):not(.showSelectedTopLeagues) {
					position: absolute;
				}
			}

			div {
				right: 0;
				top: 5px;
				width: 50px;
				padding: 6px 6px 0 0;

				a {
					@include dvu-button-play();

					&:first-child {
						position: relative;
						z-index: 2;
						display: initial;
						float: left;
					}

					&.showSelectedTopLeagues {
						@include dvu-button-play(); //DVUDelete - override top leagues default style
						float: right;
						margin: 0;
					}

					&.printSelectedTopLeagues {
						float: left;
						background-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/print.svg');
						background-position: center;
						margin: 0;
					}
				}
			}
		}

		#tlTitle {
			@include dvu-titlebar();
			padding-left: 0px;
			text-transform: capitalize;

			&:hover {
				color: $yellow-200d;
			}
		}
	}

	.GroupOddsTitle {
		display: none; //DVUDelete
	}
}

/*This file contains common css properties for angular top leagues widget*/
#topleagues-angular-container {
	display: block;
	position: relative;
	background-color: $transparent;
	border-radius: 0px;
	margin: 0 0 8px 0;

	.TopLeaguesAngularTitle {
		@include dvu-titlebar();
		display: none;
		border: none;
	}

	.loader_indicator {
		display: none;
		position: absolute;
		width: 50px;
		height: 50px;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		z-index: -1;
		background-color: transparent;
		border: 8px solid rgba(235, 169, 23, 0.2);
		border-left-color: rgba(235, 169, 23, 1);
	}

	&.loading {
		min-height: 100px;

		.loader_indicator {
			display: block;
		}

		.TopLeaguesAngularTitle {
			display: block;
		}
	}
}