.alertable {
	position: fixed;
	z-index: 9999999;
	top: 30vh;
	left: calc(50% - 215px);
	width: 400px;
	background-color: $white;
	border-radius: 6px;
	padding: 15px 40px;
	margin: 0 auto;

	.okButton {
		@include dvu-button-green-small-m();
		margin: 0 6px;
	}

	.alertable-cancel, .cancelButton {
		@include dvu-button-link-small-m($dark-theme: false);
		margin: 0 6px;
	}

	.alertable-close {
		top: 2px;
		right: 2px;
		padding: 0;
		background-color: $grey-300d;
		mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg");
		-webkit-mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg");
		width: 20px;
		height: 20px;
		position: absolute;
		outline: none;
		float: right;
	}

	&.promozioni {

		.alertable-message {

			.alertable-message-icon {
				display: block;
				width: 80px;
				height: 80px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/success.svg');
				-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/success.svg');
				background-color: $green-300d;
				margin: 12px auto;
			}

			.alertable-message-title {
				margin: -17px auto 12px auto;
				font-size: 16px;
			}
		}
	}

	&.admLottoPopup_157 {
		padding: 5px;
		height: 350px;

		.alertable-buttons {
			.button{
				margin: 0 5px;
			}
			.okButton {
				position: absolute;
				right: 0;
				width: 30px;
				font-size: 20px;
				line-height: 14px;
				padding: 6px 0px;
			}
		}

		.alertable-message{
			height:100%;
			margin:0;
		}
	}

	&.admLottoPopup_162,
	&.admLottoPopup_164,
	&.admLottoPopup_165 {
		padding: 5px;
		height: 98%;
		width: 800px;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);

		.alertable-buttons {
			.button{
				margin: 14px 23px;
			}
			.okButton {
				position: absolute;
				right: 0;
				width: 36px;
				font-size: 20px;
				line-height: 14px;
				height: 30px;
			}
		}

		.alertable-message{
			height:100%;
			margin:0;
		}
	}

	&.multibonus {
		background: $white;
		max-height: 45%;
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 640px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	
		.alertable-buttons {
			margin: 0;
			padding: 0;
			width: 100%;
			float: right;
			text-align: right;
	
			.button {
				&.okButton {
					width: 24px;
					padding: 0;
					color: $grey-200;
					margin: 0;
					height: 24px;
					line-height: 24px;
					letter-spacing: initial;
					background: initial;
	
					&:after {
						content: "+";
						transform: rotate(45deg);
						display: block;
						font-size: 20px;
						color:$grey-300d;
					}
				}
			}
		}
	
		.title {
			color: $black;
			font-family: Roboto;
			font-size: 20px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.1;
			height: 22px;
			letter-spacing: normal;
			width: 100%;
			text-align: center;
		}
	
		.alertable-message {
			flex: 1;
			overflow: auto;
			margin-bottom: initial;
	
			#fullBonusTable {
				border-spacing: initial;
				padding: 10px 12px;
				width: 100%;
	
				tr {
					td {
						width: 50%;
						border-bottom: 1px solid $grey-100;
						font-family: Roboto;
						font-size: 14px;
						color: $black;
						font-stretch: normal;
						font-style: normal;
						height: 36px;
						line-height: 36px;
	
						&.left {
							text-align: left;
							font-weight: 500;
						}
	
						&.right {
							text-align: right;
							font-weight: normal;
						}
					}
				}
			}

			&::-webkit-scrollbar {
				width: 2px;
			}
			
			&::-webkit-scrollbar-track {
				background: $grey-100d;
			}
			
			&::-webkit-scrollbar-thumb {
				height: 46px;
				border-radius: 8px;
				background-color: $yellow-200d;
			}
		}
	}
}

.alertableV2 {
  position: fixed;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 16px);
  max-width: 640px;
  background-color: $white;
  border-radius: 6px;
  padding: 12px 16px;

  .alertable-message {
    margin: 0 0 25px 0;
    color: $grey-300d;
    line-height: 14px;
    text-overflow: ellipsis;
    text-align: left;
    letter-spacing: 0.24px;
  }

  .alertable-buttons {
    text-align: right;

    .alertable-button {
      @include mvu-button-grey(28px, auto, $font-12);
      min-width: 72px;
      margin-left: 8px;
    }
  }
}

.alertable-overlay {
	position: fixed;
	z-index: 9999998;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,.5);
}

.alertable-message {
	@include dvu-font-regular($font-12);
	margin-top: 10px;
	margin-bottom: 20px;
	color: $grey-300d;
	line-height: 14px;
	text-overflow: ellipsis;
	text-align: center;
	letter-spacing: 0.24px;

	b {
		@include dvu-font-medium($font-12);
	}
}

.alertable-prompt {
	margin-bottom: 20px;
}

.alertable-input {
	width: 100%;
	border-radius: 4px;
	box-shadow: none;
	border: solid 1px #ccc;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	padding: 6px 12px;
	display: block;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.alertable-buttons {
	text-align: center;

	.button {
		margin: 0 3px;
	}
}

.alertable-ok {
	@include dvu-button-green-small-m();
}

.alertable-image {
	width: 390px;
	height: 75px;
	background-image: url('../../images/jurisdictionchange.jpg');
	background-repeat: no-repeat;
	background-position: center
}

.alertable-title {
	@include dvu-font-regular($font-16);
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 20px;
	color: $grey-300d;
	line-height: 14px;
	text-overflow: ellipsis;
	text-align: center;
	letter-spacing: 0.24px;
}
