.personalMenu__body {
	&__nav {
		width: 100%;
		padding: 0px 15px 10.5px 2px;
		border-bottom: 1px solid $grey-100d;
		margin-bottom: 24px;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 4px 0px 4px 6px;
				margin-left: -6px;

				&:hover {
					background-color: $yellow-400d;
				}
			}
		}

		a {
			@include transition(all 0.35s ease);
			color: $grey-300d;
			font-size: $font-12;
			display: inline-block;
			line-height: 14px;
			letter-spacing: 0.24px;
			font-weight: 500;
			text-transform: lowercase;
			position: relative;
			width: 100%;
			text-align:left;

			&:first-letter {
				text-transform: uppercase;
			}

			&:first-line {
				text-transform: capitalize !important;
			}

			&::after {
				@include transform(rotate(-45deg));
				@include transition(all 0.35s ease);
				border: solid $grey-400d;
				border-width: 0 1.5px 1.5px 0;
				content: "";
				display: inline-block;
				height: 0;
				padding: 2.5px;
				position: absolute;
				right: 16px;
				top: 5px;
				visibility: visible;
				width: 0;
			}

			&.btn-logout {
				color: $red-300d;
				position: absolute;
				text-align: center;
				right: 14px;
				bottom: 6px;
				width: 126px;
				height: 28px;
				padding-top: 8px;

				&::after {
					display: none;
				}

				&:hover {
					color: $red-300d;
					right: 14px;
					bottom: 6px;
					width: 126px;
					height: 28px;
					background: $red-100d;
					padding-top: 8px;
					border-radius: 4px;
				}
			}
		}

		&__left {
			border-right: 1px solid $grey-100d;
			display: inline-block;
			float: left;
			width: 50%;
		}

		&__right {
			display: inline-block;
			float: right;
			width: calc(50% - 18px);

			a {
				&:after {
					right: 7px;
				}
			}
		}

		&__title {
			display: inline-block;
			font-weight: bold;
			margin: 0 12px 12px 0;
		}
	}

	.btn-logout {
		color: $red-300d;
		position: absolute;
		text-align: right;
		right: 14px;
		bottom: 6px;
		width: 126px;
		height: 28px;
		padding: 7px 40px 0px 0px;

		&::after {
			display: none;
		}

		&:hover {
			color: $red-300d;
			right: 14px;
			bottom: 6px;
			width: 126px;
			height: 28px;
			background: $red-100d;
			padding: 7px 40px 0px 0px;
			border-radius: 4px;
		}
	}
}
