.goldenPrediction {
  max-width: 787px;
  margin: auto;

  .surveysDescription {
    font-size: $font-20;
    line-height: $font-20;
    padding: 32px 0px;
  }

  .surveysContainer {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;

    .survey {
      width: 190px;
      height: 108px;
      border-radius: 6px;
      background-size: cover;
      background-position: center;
      background-image: url('../../images/GoldenPrediction/Survey.jpg');
      position: relative;
      overflow: hidden;

      .surveyCreationDate {
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: left;
        padding: 6px 0 0 6px;
      }

      .surveyCounter {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        padding: 6px 6px 0 0;
      }

      .surveyTitle {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        width: 100%;
        text-align: center;
        font-size: $font-20;
      }

      .surveyLink {
        @include dvu-button-green-small-m();
        text-transform: none;
        line-height: 1;
        padding-left: 6px;
        padding-right: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translate(-50%,0);
        width: 106px;
        height: 28px;

        &:first-letter {
          text-transform: none;
        }
      }

      .surveyCompleted {
        width: 100px;
        height: 22px;
        background: linear-gradient(to right, #5CA3CD, #00C4B0);
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 22px;
        transform: translate(50%, -50%) translate(-25px, 25px) rotate(45deg);
        text-transform: uppercase;
        font-weight: bold;
        font-size: $font-9;
      }
    }

    .surveysMessage {
      width: 100%;
      height: 120px;
      border-radius: 6px;
      background-size: cover;
      background-position: center;
      background-image: url('../../images/GoldenPrediction/Message.png');
      position: relative;
      margin-top: 60px;

      .surveysMessageText {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        width: 100%;
        text-align: left;
        font-size: $font-24;
        line-height: $font-24;
        padding: 0 234px 0 123px;
      }
    }
  }
}
