﻿div[id$='upAnagrafiche'] {
	width: 1008px;
	margin: 12px auto 20px;
	min-height: 100%;
	color: $white;

	.cellaDx {
		padding-right: 5px;
	}

	.cellaSx {
		padding-left: 0;
	}

	.tblTabs + div {
		background-color: $grey-300d;
		border-radius: 0 6px 6px 6px;
		padding: 12px;
	}

	.anagrafica-details-new.table {
		font-size: $font-14;

		.cell {
			width: 30%;
			display: inline-block;

			&.midd {
				width: 39%;

				.cell.third {
					width: calc((100% / 3) - 3px);
				}

				.TotalAmlAmountHeader {
					float: left;
					font-weight: bold;
					color: $yellow-400d;
				}

				.TotalAmlAmountValue {
					float: right;
					font-weight: bold;
					color: $yellow-400d;
				}
			}
		}

		input[type="text"],
		select {
			width: 100%;
		}

		.section-title {
			font-size: $font-16;
			text-align: center;
			padding-top: 10px;
		}

		.section-body {
			.row {
				min-height: 32px;

				.cell.left {
					text-align: right;
					padding-right: 5px;
				}

				div [id$='upCodFiscale'] {
					display: inline;
				}
			}

			.lvl-1 .cell.left {
				vertical-align: top;
				padding-top: 3px;
			}

			.ddlPaymentMethods {
				width: 60%;
				float: left;

				input[type=checkbox] {
					margin: 8px 0;

					+ label {
						line-height: 32px;
						padding-left: 5px;
					}
				}
			}

			.txtPaymentAmounts {
				width: 40%;
				float: right;

				input {
					margin: 2px 0;
				}
			}

			&.buttons {
				.cell.right {
					input {
						float: right;
					}
				}
			}
		}

		.cell:empty {
			min-height: 0px;
		}
	}

	.dgStyle {
		margin-top:12px;

		tr {
			height: 32px;

			&.dgAItemStyle {
				background-color: rgba($white, 0.05);
			}

			&.dgHdrStyle {
				background-color: rgba($white, 0.05);
				color: $white;
			}

			th a,
			td a {

				&:hover {
					color: $yellow-200d;
				}
			}

			a.tdSearch {
				background-color: $grey-150d;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
				width: 16px;
				height: 16px;
				display: block;
				margin-left: 12px;

				&:hover {
					background-color: $grey-100d;
				}

				img {
					display: none;
				}
			}

			&.dgPagerStyle {
				background-color: rgba($white, 0.05);
				height: 48px;

				table {
					background-color: $grey-300d;
					border-collapse: collapse;
					margin-left: 7px;

					tr {
						height: 24px;

						td {
							a {
								text-decoration: none;
								font-weight: bold;
								border: 1px solid $white;
								display: block;
								padding: 0 3px 0 3px;

								&:hover {
									background-color: #ffffff;
									color: #54585e;
								}
							}
						}
					}
				}

				div {
					border-top: 1px solid $grey-150d;
					height: 24px;
					padding: 5px;
					font-weight: bold;
				}

				td span {
					text-decoration: none;
					color: $black;
					font-weight: bold;
					border: 1px solid $white;
					display: block;
					padding: 0 3px 0 3px;
					background-color: $yellow-300d;
				}
			}
			//odds search

			.ricercaSE {
				padding: 0 0 6px 12px;
				width: 100%;

				.ricercaEevento {
					color: $yellow-200d;
					line-height: 22px;
				}

				a {
					font-size: $font-14;

					&:hover {
						color: $yellow-200d;
					}
				}
			}

			.ricercaData {
				padding: 0 12px 0;
				white-space: nowrap;
			}

			&.dgEmptyStyle {
				text-align: center;
			}
		}
	}
}
/*.row.lvl-1 > .left,
.row.lvl-1 > .midd,
.row.lvl-1 > .right {
	width: 33.333333%
}

.anagrafica-details-new {
	min-width: 922px;
	margin: 0 auto;
}

.cell.right span {
	min-height: 24px;
	line-height: 24px;
	margin-bottom: 5px;
	margin-left: 5px;
}

input[type='radio'] {
	margin-left: 0;
}

.Telefono select {
	width: 85px;
}

.Telefono input[type='text'] {
	width: calc(100% - 89px);
}

.Gender.row select {
	width: 95%;
}

.Birthday.row .third,
.DataRilascio.row .third,
.DataScadenza.row .third,
.Gender.row .third {
	width: 33.333333%;
	text-align: center;
}

.Birthday.row .third:first-child,
.DataRilascio.row .third:first-child,
.DataScadenza.row .third:first-child,
.Gender.row .third:first-child {
	text-align: left;
}

.Birthday.row .third:last-child,
.DataRilascio.row .third:last-child,
.DataScadenza.row .third:last-child {
	text-align: right;
}

.Birthday.row .third select,
.DataRilascio.row .third select,
.DataScadenza.row .third select {
	width: 95%;
}

.Politico.row.lvl-1 > .cell.midd > div,
.DomicileSelect.row.lvl-1 > .cell.midd > div {
	display: block;
	min-height: 22px;
}

.Politico.row input[type='radio'],
.DomicileSelect input[type='radio'] {
	margin-top: 3px;
}

.Politico.row input[type='radio'],
.DomicileSelect input[type='radio'], label {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

.FundsOrigin .ddlFundsOrigin label,
.ddlPaymentMethods label {
	min-height: 22px;
}

.ddlPurposeOfOperation + div > input,
.ddlOccupation + div > input,
.ddlFundsOrigin + div > input {
	float: right;
	width: calc(100% - 15px);
}

.anagrafica-details-new .buttonCFRollIn,
.anagrafica-details-new .buttonCF {
	background-image: none;
	background-color: #ffc000;
	border: 1px solid #ffc000;
	border-radius: 0;
}

.anagrafica-details-new .buttonCFRollIn {
	background-color: #ffae00;
	border: 1px solid #ffae00;
}

.Birthday.row .midd select:last-child,
.DataRilascio.row .midd select:last-child,
.DataScadenza.row .midd select:last-child {
	margin-right: 0;
}

.txtPaymentAmounts {
	width: 40%;
	float: left;
}

.txtPaymentAmounts input[type='text'] {
	text-align: right;
}

.txtPaymentAmounts input[type='text']:disabled,
.purposeOfOperation.row input[type='text']:disabled,
.occupation.row input[type='text']:disabled,
.fundsorigin.row input[type='text']:disabled {
	cursor: not-allowed;
}

.Paymentmethods .cell.right span {
	margin-bottom: 5px;
	margin-left: 5px;
}

.Paymentmethods .cell.right span:after {
	content: '';
	display: table;
	width: 100%;
	height: 0;
}

.section.buttons.insert .cell.right {
	padding-top: 15px;
}

.section.buttons.insert .cell.right input {
	float: right;
}

.pop-up-blocking-div-wait {
	position: fixed;
	width: 50px;
	height: 10px;
	background-color: black;
	opacity: 0.7;
	top: calc(50% - 5px);
	left: calc(50% - 25px);
	//background-image: url('../images/icons/wait_top.gif');
}

.anagraficapopup-save-errors-list li {
	color: red
}

.anagraficapopup-save-errors-list li span {
	color: red
}*/