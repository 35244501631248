.clearfix {
	@include clearfix;
}

body {
	height: 100%;

    &.stopScroll {
		height: 100vh !important;
		overflow-y: hidden;
	}

	&.noScroll {
		overflow: hidden;
	}
}

.header {
	overflow: hidden;
}

.bodyMain {
	background-color: $grey-400d !important; //cashier page style, remove when user specific background is deleted
	margin: 0;
	min-height: 100%;
	@include dvu-font-regular($font-12);
	line-height: 12px;
	color: $white;
	background-repeat: no-repeat;
	background-position: center 60px;
	background-attachment: fixed;
	/*--- Plus18 ---*/
	.plus18 {
		display: none;
	}

	#sessionEndWarning {
		display: none;
	}

	#SessionTimeoutDiv {
		text-align: center;
		padding-top: 30px;

		a {
			color: $yellow-300;
			text-decoration: none;

			&:hover {
				color: $yellow-200;
			}
		}
	}

	.divNewUserWide.Mail {
		.nuovoUtente2 {
			height: 310px;

			.sx {
				width: 260px;
			}

			.dx {
				left: 315px;
			}

			.cellaSx {
				height: 27px;

				&.Top {
					vertical-align: middle;
				}
			}

			.cellaDx {

				INPUT {
					text-align: left;
					padding-left: 6px;
				}
			}
		}

		.nuovoUtente1 {
			.cellaDx {

				INPUT {
					text-align: left;
					padding-left: 6px;
				}

				SELECT {
					padding-left: 1px;
				}
			}
		}

		.Msg {
			position: relative;
			top: -500px;
			margin: 0 auto;
			z-index: 20;
		}

		.openAccountBtn.Mail:hover {
			cursor: pointer;
			background-position: center top;
		}
	}

	&.Logged {
		.MarkerMoversOdds {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&.it-IT {

		&.Anonymous {
			&.it-IT {
				.plus18 {
					display: block;
					position: absolute;
					top: 2px;
					right: 390px;
					height: 21px;
					line-height: 20px;
					padding-left: 27px;
					font-size: 11px;
					color: #fff;
					text-decoration: none;

					SPAN {
						display: inherit;
						height: 17px;
						line-height: 17px;
						margin-top: 2px;
					}
				}
			}
		}
	}

	&.err {
		margin: 0;
		min-height: 100%;
		font-family: Roboto,sans-serif;
		font-size: 12px;
		line-height: 14px;
		color: white;
		background-color: #22262b;

		.divMainHome {
			width: 60%;
			margin: 0 auto;
			min-height: 100%;
		}

		#tblMainContent {
			width: 100%;
			margin-top: 20px;
		}

		.ErrorTitleStyle {
			color: #fab615;
			font-size: 14px;
		}

		.ErrorMessageStyle {
			margin-top: 20px;
		}

		.ErrorRetry {
			margin-top: 50px;
			font-size: 14px;

			a {
				color: #F8CB24;
				text-decoration: none;

				&:hover {
					color: #ffe480
				}
			}
		}

		#divFooter {
			height: auto;
			margin: auto;
		}
	}

	&:hover {
		cursor: default;
	}

	#btnHide {
		display: none;
		background-color: $green-200d;
		background-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-left.svg");
		background-repeat: no-repeat;
		background-position: center;
		z-index: auto;
		width: 12px;
		height: 104px;
		position: absolute;
		left: -12px;
		top: 365px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		cursor: pointer;

		&:hover {
			background-color: $green-100d;
		}

		&.hid {
			background-color: $green-200d;
			background-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-right.svg");
			background-repeat: no-repeat;
			background-position: center;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			border-left: 0;
			right: auto;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			left: 0;
			position: fixed;

			&.min {
				left: 0;
				border-bottom-left-radius: 0px;
				border-left: 1px solid #000;
			}

			&:hover {
				background-color: $green-100d;
			}
		}
	}

	#tooltipHide {
		display: none;
	}

	.CntSX {
		.txtResendEmail {
			width: fit-content;
			margin: 5px auto;
			line-height: 18px;
			letter-spacing: .4px;
			position: relative;
			padding: 10px 12px 0px;

			a {
				color: $yellow-300;

				&:hover {
					color: #ffe480;
				}
			}
		}
	}
}

.bodyMainLive {
	margin: 0;
	background-image: none;
	background-color: $grey-400d !important;

	[id$="atlasCoupon"] {
		margin: 0;
	}

	#sessionEndWarning {
		display: none;
	}

	.divMainLive {
		padding: 0;

		#divContent {
			width: 100% !important;
			margin: 0;
			overflow: hidden;
			background-color: #22262B;

			#divCoupon {
				padding: 20px 12px 0 10px;
				z-index: auto;
			}

			#divDisclaimer {
				clear: both;
				padding: 15px 0px 15px 10px;
				font-size: 11px;
				color: #888;
				width: 86%;

				a {
					color: $white;
	
					&:hover {
						color: $yellow-200;
					}
				}		
			}
		}

		.app__live-root {
			width: calc(100% - 280px);
			float: left;
		}

		#divCoupon {
			width: 280px;
			padding-left: 15px;
			background-color: none;

			.divCouponContent {
				width: 100%;
			}
		}
	}

	.plus18 {
		display: none;
	}

	#div {
		.Top {
			background-color: #000;
		}
	}

	#divHeader {

		.topMenu {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.headerContent {
		width: auto !important;
	}

	&.Anonymous {
		&.it-IT {
			.plus18 {
				display: block;
				position: absolute;
				top: 2px;
				right: 390px;
				height: 21px;
				line-height: 20px;
				padding-left: 27px;
				font-size: 11px;
				color: #fff;

				SPAN {
					display: block;
					height: 17px;
					line-height: 17px;
					margin-top: 2px;
					padding-left: 9px;
					//background-image: url('images/header_menu_sep.gif');
					background-position: left center;
					background-repeat: no-repeat;
				}
			}

			&:hover {
				color: #ffbb03;
			}
		}
	}
}

.spacer9 {
	height: 9px;
}

.spacer3 {
	height: 3px;
}

.spacer30 {
	height: 30px;
}

.customToolTip {
	@include dvu-tooltip-default-simple($font-12);
	position: fixed;
	z-index: 999999;
	box-shadow: 1px 2px 6px rgba(58, 62, 69, 0.44);
	-webkit-box-shadow: 1px 2px 6px rgba(58, 62, 69, 0.44);
	-moz-box-shadow: 1px 2px 6px rgba(58, 62, 69, 0.44);

	&:after {
		content: '';
		width: 25px;
		height: 25px;
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
		-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
		background-color: $white;
		position: absolute;
		display: inline-block;
		top: -6px;
		left: 18%;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			content: '';
			height: 14px;
			width: 14px;
			background-color: $white;
			position: absolute;
			transform: rotate(45deg);
			top: -7px;
			left: 77px;
		}
	}
}

.bubble {
	@extend .customToolTip;
	white-space: pre-wrap;
	transform: translateX(-50%);

	&:after {
		left: 50%;
		transform: translateX(-50%);
	}
}

.loader_indicator {
	animation: spin 1s ease-in-out infinite;
	border-radius: 50%;
	border-top-color: $yellow-300;
	display: inline-block;
	position: absolute;
}

@keyframes spin {
	to {
		@include transform(rotate(360deg));
	}
}

.minimizedContainer.cChatTest2 {
	display: block !important;
	right: 30px;
	bottom: 70px;
	border-radius: 100%;
}

.sidebarHeader {
	h2 {
		overflow: unset !important;
	}

	.minimizeButton {
		outline: none !important;

		&:focus-visible {
			outline: none !important;
		}
	}
}

.embeddedServiceSidebarButton.startButton {
	background: #3b9b22 !important;
}
