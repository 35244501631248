﻿.HomeDXContent.combinateContent {

	.CombinatePuntata,
	.CombinateItem.Fav,
	.CombinateItem.Thr,
	.CombinateItem.Cas {
		position: relative;
		height: 24px;
		line-height: 24px;
		margin: 0px 0px 8px 0px;

		> * {
			display: inline-block;
		}

		.CombinateSco {
			letter-spacing: 0.28px;
		}

		.CombinateQuota {
			position: absolute;
			letter-spacing: 0.28px;
			right: 72px;
		}

		.CombinateVincita {
			position: absolute;
			right: 0px;

			a {
				@include dvu-button-odd-regular();
			}
		}
	}

	.CombinatePuntata {
		font-size:$font-12;
		height: 14px;
		line-height: 14px;
		color: $white-080;
		letter-spacing: 0.24px;

		.CombinateImp {
			padding-left: 6px;
			font-weight: 500;
		}

		span {
			width: 60px;
			position: absolute;
			right: 0px;
			text-align: center;
			letter-spacing: 0.18px;
		}
	}

	.CombinateItem.Cas {
		margin: 0px;
	}
}
