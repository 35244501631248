.mainHeader {
	&#divHeader {
		min-width: 1008px;
		position: relative;
		z-index: 2000;

		.Top {
			position: relative;
			background: $yellow-400d;

			.Content {
				height: 76px;
				position: inherit;
				min-width: 1350px;
				padding: 0 100px;

				@media screen and (max-width: 1690px) {
					padding: 0 30px;
				}

				@media screen and (min-width: 2076px) {
					width: 1876px;
					margin: 0 auto;
				}
			}
		}

		.Menu {
			height: 29px;
			width: 820px;
			margin: 0;
			padding: 0;
			line-height: 29px;
		}

		.Logo {
			z-index: 1;
			width: 50%;
			display: inline-block;
			float: left;
			height: 100%;

			a {
				display: inline-block;
				height: 65px;
				background-image: url('../../images/Logo.png');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				width: 180px;
				position: relative;
				z-index: 1;
			}

			&.VG {
				background-image: none;

				IMG {
					display: block;
				}
			}
		}

		.AreaRiservata {
			position: relative;
			height: 40px;
			background-position: right bottom;
			background-repeat: no-repeat;

			#divSaldo {
				position: absolute;
				top: 34px;
				right: 135px;
				display: none;
				width: 311px;
				background: $white;
				color: black;
				border-radius: 6px;
				font-size: 11px;
				font-weight: normal;
				padding: 12px;
				line-height: 14px;
				overflow: hidden;
				z-index: 2;

				.btnSco {
					float: right;
					display: block;
					text-align: right;

					a {
						-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
						background-color: $grey-400d;
						display: inline-block;
						width: 14px;
						height: 14px;

						&:hover {
							background-color: $grey-300d;
						}

						img {
							display: none;
						}
					}
				}

				div {
					width: 50%;
					font-size: $font-10;
					line-height: 13px;

					.saldoMenuValue {
						float: right;
						font-weight: bold;
						padding-right: 10px;
					}

					input[id$='btnSaldo'] {
						background: $grey-300d;
						height: 12px;
						left: 50%;
						-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg');
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg');
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						position: absolute;
						width: 12px;
						border: none;
						cursor: pointer;

						&:hover {
							background: $grey-200d;
						}
					}
				}

				.TitoloSaldo INPUT:hover {
					cursor: pointer;
				}

				divBonusUtente {
					color: #eba917
				}

				.divBonusUtenteStatoNonAccreditato {
					color: red
				}
			}

			.divLoginLogged {
				.tblLoginLogged {
					position: absolute;
					top: 0;
					right: 0;
					width: 473px;
					height: 26px;
					color: black;
					font-size: $font-12; //Live css reset

					td {
						vertical-align: middle;
					}

					.tdUser {
						font-weight: bold;
					}

					.btnLogout {
						@include dvu-button-green-small-m();
						float: right;
					}
					//DVU TO DO: Change button to checkbox
					.tdChackSaldo {
						width: 20px;

						.btnSaldoVisHid {
							appearance: none;
							-moz-appearance: none;
							-webkit-appearance: none;
							background-color: $grey-400d;
							border: solid 1px $grey-200;
							border-radius: 4px;
							height: 16px;
							width: 16px;
							outline: none;
							position: relative;
							vertical-align: top;
							cursor: pointer;
							display: block;

							&:hover {
								background-color: $grey-300d;
							}

							&.btnSaldoVis {
								background-color: $yellow-400d;

								&:after {
									content: '';
									background-color: $grey-400d;
									mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
									width: 14px;
									height: 14px;
									position: absolute
								}

								&:hover {
									background-color: $yellow-300d;
								}
							}
						}
					}

					.tdMessages {
						width: 24px;

						a {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/letter.svg');
							background-color: $grey-300d;
							width: 14px;
							height: 14px;
							display: block;
							float: left;

							&:hover {
								background-color: $grey-200d;
							}

							img {
								display: none;
							}
						}

						span {
							padding: 1px 0 0 3px;
							float: right;
						}
					}

					.LastLoginTime, .LastLoginApp {
						td {
							padding-left: 7px;
						}
					}
				}
			}

			.pnlLogged {
				.tdRegister {
					width: 100%;
					text-align: right;
					line-height: 10px;
				}
			}
		}

		.divRegMain {
			z-index: 1;
			position: absolute;
			top: 0 !important;
			right: 0 !important;
			height: 45px;
			margin-top: 15px;
			padding: 8px 0 0 15px;

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-internal-autofill-selected {
				-webkit-box-shadow: 0 0 0 100px $yellow-200d inset !important;
				-webkit-text-fill-color: $black;

				&::first-line {
					font-family: "Roboto", sans-serif;
					font-size: 0.7em;
					font-weight: normal;
				}
			}

			&::placeholder,
			&::-webkit-input-placeholder,
			&::-moz-placeholder {
				color: $black;
				display: inline-block;
			}

			.RegLostPwd {
				right: inherit;
				text-align: left;
				top: 38px;
				left: 152px;
				position: absolute;

				a {
					@include dvu-link-light($font-10);
				}
			}

			.RegTxtUsr {
				padding-top: 0px;
				height: 24px;
				float: left;

				img {
					float: left;
				}

				.validation-error {
					font-size: 0 //DVUDelete remove error text
				}
			}

			.RegNewUser, .RegLblUsr, .RegLblPwd, .RegLblPwdRep {
				display: none;
			}

			.RegTxtPwd, .RegTxtPwdRep {
				padding: 0 5px;
				height: 24px;
				float: left;

				img {
					float: left;
				}

				.validation-error {
					font-size: 0 //DVUDelete remove error text
				}
			}

			.RegTxtPwdRep {
				padding-left: 0;
			}

			.RegDivBtn {
				float: left;
				padding: 0 5px;
			}

			.statsDivReg {
				float: left;
			}

			.txtLogin {
				background-image: none;
				width: 128px;
				-webkit-box-shadow: none !important;
				-webkit-text-fill-color: $black;
				background-color: $yellow-200d !important;
				border-color: $grey-300d;
				color: $black;
				filter: none;
				opacity: 1;

				&:focus {
					-webkit-box-shadow: 0 0 0 100px $white inset !important;
					-webkit-text-fill-color: $black;
					background-color: $white;
					color: $black;
					border-color: $grey-300d;
				}
			}

			.divLoginCode {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 20000;
				border: 1px solid #ffd803;
				background-color: #ffba03;
				padding: 5px;
				border-radius: 6px;
				border-top: 0;
				/*width: 250px;*/
				box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.6);
				text-align: center;

				#boxAntieffrazione {

					div {
						float: left;
						position: relative;
						width: 45px;
						text-align: center;
						height: 20px;
						border-radius: 3px;
						border: 1px solid $grey-400d;
						margin: 7px 3px;

						> span {
							line-height: 18px;
							color: $black;
							padding: 6px 0px;
							font-weight: 500;
							font-family: $roboto;
							font-size: $font-10;
						}

						> input {
							height: 16px;
							line-height: 16px;
							border-radius: 2px;
							margin-top: 1px;
							margin-right: -3px;
							width: 22px;
							background-color: $grey-400d;
							text-align: center;
							font-size: $font-10;
						}
					}

					> span {
						display: block;
						text-align: center;
						padding: 6px 0px;
						font-family: $roboto;
						font-size: $font-10;
						color: $black;
					}
				}

				.btnLogin {
					padding: 6px 0px;
					margin: 6px 0px;
				}
			}
		}

		.MenuUpdate {
			bottom: inherit;
			padding: 0;
			right: calc(50% - 25.5px);
			text-align: center;
			white-space: nowrap;
			color: $white;
			top: 40px;
			width: 51px;
			padding-right: 15px;
			font-size: $font-10;

			img {
				left: 0;
				position: absolute;
				top: 0;
			}

			DIV {
				display: inline;
			}

			@-o-keyframes loadingSlider {
				from {
					left: 0;
				}

				to {
					left: 100%;
					width: 70px;
					margin-left: -70px;
				}
			}

			@-webkit-keyframes loadingSlider {
				from {
					left: 0;
				}

				to {
					left: 100%;
					width: 70px;
					margin-left: -70px;
				}
			}

			@keyframes loadingSlider {
				from {
					left: 0;
				}

				to {
					left: 100%;
					width: 70px;
					margin-left: -70px;
				}
			}

			.updateProgress {
				height: 2px;
				width: 100%;
				background-color: $grey-300d;
				position: absolute;
				left: 0;

				> div {
					background: $yellow-400d;
					content: '';
					display: inline-block;
					height: 2px;
					width: 350px;
					position: absolute;
					animation-name: loadingSlider;
					-o-animation-name: loadingSlider;
					-webkit-animation-name: loadingSlider;
					animation-duration: 2.5s;
					animation-iteration-count: infinite;
					animation-play-state: inherit;
				}
			}
		}

		.SessionExp {
			@include horizontal-align-absolute;
			top: 30px;
			display: none;
			float: right;
			margin-top: -24px;
			padding: 7px 16px 5px;
			background-color: $white;
			border-radius: 6px;
			color: $black;

			span {
				@include dvu-font-regular($font-12);
				line-height: 14px;
			}
		}

		&.hasLanguageSelector {
			.divRegMain {
				right: 88px !important;
			}
		}

		.tblMenu {
			margin-top: 76px;
			height: 53px;
			font-size: $font-14;

			> ul > li {
				float: left;
				line-height: 53px;
				padding: 0 11px;
				position: relative;

				&:first-child {
					padding-left: 0;
				}

				a {
					@include dvu-font-regular($font-14);
					color: $white;
					opacity: 0.7;
					letter-spacing: 1px;
					text-transform: capitalize;

					@media screen and (max-width:1600px) {
						letter-spacing: normal;
					}

					&:hover {
						opacity: 1;
					}

					&.menuItemSelected {
						opacity: 1;
						position: relative;
						padding-bottom: 15px;
						border-bottom: 2px solid $yellow-400d;
					}
				}

				ul {
					display: none;
					position: absolute;
					left: 0;
					top: 53px;
					z-index: 2000;
					line-height: 24px;
					background-color: rgba($grey-500d, 0.9);
					text-transform: capitalize;
					padding: 0 11px;

					li {
						white-space: nowrap;
					}
				}

				&:hover {
					ul {
						display: block;
					}
				}

				li {
					a.menuItemSelected {
						opacity: 1;
						position: relative;
						padding-bottom: 15px;
						border-bottom: none;
					}
				}
			}
		}
	}

	&.userLoggedIn {
		&#divHeader {
			.Top {
				.Content {
					.AreaRiservata {
						height: initial;
					}
				}
			}
		}
	}
}
