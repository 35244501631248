﻿/*style for Benvenuto widget for logged in users*/
.RiquadroHome {
	overflow: hidden;
	font-family: $roboto;

	&.Reg {
		margin-top: 0px;
		margin-bottom: 8px;
	}

	> .TopSX {
		display: none;
	}

	> .BtmSX {
		display: none;
	}

	.Cnt {
		border-radius: 6px;
		background-image: none;
		padding: 0;
	}

	.RiquadroHomeUsr {
		margin: 0;

		.Cnt {
			padding: 12px;
			background-color: $grey-300d;
		}

		.TopSX, .TopDX, .BtmSX, .BtmDX {
			height: 0px;
			display: none;
		}
	}
}

.welcomeUser {
	display: inline-block;
	font-family: $roboto;
	font-size: $font-16;	
	line-height: 1.38;
	letter-spacing: 0.4px;

	> span {
		color: $white;
		padding-right: 7px;
	}

	> b {
		color: $yellow-200d;
		font-weight: 500;
		padding: 0;
	}
}

.pulsantiLogged {
	text-align: right;
	white-space: nowrap;
	font-size: 0;
	padding: 0;
	width: auto;

	.btnSco {
		position: relative;

		a {
			width: 20px;
			height: 20px;
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: $white;
			display: inline-block;
			position: absolute;
			right: 35px;
			top: -11px;
		}
	}

	[id$="divNewMessage"] {
		position: relative;

		a {
			width: 24px;
			height: 24px;
			top: -13px;
			right: 4px;
			position: absolute;
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/letter.svg');
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/letter.svg');
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: $white;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background: transparent url('../../images/IEIcons/Letter.png') no-repeat center;
				mask-image: none;
			}

			img {
				display: none;
			}
		}

		[id$="lblNumeroMessaggi"] {
			height: 18px;
			min-width: 18px;
			width: auto;
			line-height: 13px;
			bottom: 10px;
			z-index: 0;
			top: -18px;
			right: -3px;
			background: $green-300d;
			border-radius: 15px;
			box-shadow: none;
			color: $white;
			font-family: $roboto;
			font-size: $font-11;
			font-weight: normal;
			letter-spacing: 0.17px;
			position: absolute;
			text-align: center;
			padding: 3px 2px;
			border: none;
		}
	}
}

.divTblAgente {
	padding: 0;
}

.dgDefaultLoggedStyle {
	font-size: $font-12;
	width: 100%;
	margin-top: 10px;

	TD {
		margin: 0 3px 0 3px;
		vertical-align: bottom;
	}

	.dgFirstItemStyle {
		background-color: $grey-300d;
		color: $white;

		TD {
			padding: 0 3px 0 3px;
			background-position: bottom;
			background-repeat: repeat-x;
			height: 28px;
		}
	}

	.dgItemStyle {

		TD {
			padding: 0 3px 0 3px;
			height: 28px;
		}
	}

	.dgAItemStyle {

		TD {
			padding: 0 3px 0 3px;
			height: 28px;
		}
	}

	.dgPagerStyle {
		font-weight: bold;
		text-align: right;
		color: $white;
		height: 28px;
	}
}

.dgHdrDefaultLoggedStyle {
	border-bottom: 1px solid $grey-150;
	border-top: 1px solid $grey-150;
	color: $white;
	font-size: $font-12;
	font-family: $roboto;

	TH {
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		width: 20%;
		height: 22px;
		text-align: right;
	}

	TD {
		width: 20%;
		height: 22px;
	}
}

#btnAgente {	
	width: 20px;
	height: 20px;
	cursor: pointer;
	display: inline-block;
	border-radius: 4px;
	background-color: $grey-200d;
	text-align: center;
	margin-right: 6px;
}

.btnRiepilogoVisHid {
	width: 20px;
	height: 20px;

	&:after {
		content: '';
		width: 16px;
		height: 12px;
		display: inline-block;
		background-color: $grey-100d;
		mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");
		-webkit-mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");
		mask-position: center;
		-webkit-mask-position: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: transparent url('../../images/IEIcons/arrow.svg') no-repeat center;
			mask-image: none;
		}
	}
}

.btnRiepilogoVis {
	width: 20px;
	height: 20px;

	&:after {
		content: '';
		width: 16px;
		height: 12px;
		display: inline-block;
		background-color: $grey-100d;
		mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg");
		-webkit-mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg");
		mask-position: center;
		-webkit-mask-position: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: transparent url('../../images/IEIcons/arrow_up.svg') no-repeat center;
			mask-image: none;
		}
	}
}

/*style for widget "Benvenuto sul nostro pianeta!"*/
.homeLogNegozio {
	position: relative;

	.LogNegozioTitle {
		padding-bottom: 6px;

		&:after {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
			background-color: $grey-150d;
			position: absolute;
			right: 12px;
			top: 9px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				mask-image: none;
				background: none;
				background-color: transparent;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('../../images/IEIcons/info.svg');
				position: absolute;
				right: 12px;
				top: 9px;
			}
		}
	}

	.divMainLogNegozio {
		font-size: $font-11;
		letter-spacing: .24px;

		a {
			color: $yellow-300d;

			&:hover {
				color: $yellow-200d;
			}
		}
	}
}

.generalLogNegozio {
	position: relative;
	max-width: 1008px;
	margin: 0 auto;

	&.fullWidth {
		max-width: 100% !important;
	}

	&.seoHidden {
		visibility: hidden;
	}

	.LogNegozioTitle {
		padding-bottom: 6px;
	}

	.divMainLogNegozio {
		font-size: $font-11;
		letter-spacing: .24px;

		.divMainLogNegozioContent {
			a {
				color: $yellow-300d;

				&:hover {
					color: $yellow-200d;
				}
			}

			h2 {
				font-size: 16px;
				line-height: 16px;
				padding: 0 0 10px 0;
			}

			h3 {
				font-size: 11px;
				line-height: 11px;
				padding: 0 0 2px 0;
				color: $grey-100d;
			}
			
			ul { 
				list-style-type: disc; 
				list-style-position: inside; 
			}
			
			ol { 
				list-style-type: decimal; 
				list-style-position: inside; 
			}
			
			ul ul, ol ul { 
				list-style-type: circle; 
				list-style-position: inside; 
				margin-left: 15px; 
			}
			
			ol ol, ul ol { 
				list-style-type: lower-latin; 
				list-style-position: inside; 
				margin-left: 15px; 
			}

			hr {
				border: transparent;
			}
		}
		
		.divMainLogNegozioLeggiTutto {
			padding: 10px 0 0 0;
			text-align: center;
			display: none;

			&.visible {
				display: block;
			}

			a {
				display: inline-flex;

				&::after {
					content:"";
					width: 14px;
					height: 14px;
					margin: 0 0 0 5px;
					background: $grey-100d;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						mask-image: none;
						background-image: url('../../images/IEIcons/arrow.svg');
					}
				}
			}			
		}

		&.expanded {
			.divMainLogNegozioLeggiTutto {
				a {
					&::after {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							mask-image: none;
							background-image: url('../../images/IEIcons/arrow.svg');
						}
					}
				}
			}
		}
	}
}
