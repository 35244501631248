﻿/*--- CASHIER ---*/
.divMainHome {
	#divMain {
		#divContent {
			iframe[id*=iframeCashier].dvu {
				background-color: #22262B;
			}
		}
	}

	div[id$='pnlMsg'] {
		background: none;
		width: 457px;
		border-radius: 6px;
		padding: 0px;
		border: none;

		.okButton {
			text-align: right;
			padding: 0 10px 8px 0px;
			height: 100%;
			width: 100%;
			background: none;
			border: none;

			input {
				@include dvu-button-link-large-m(false);
				text-transform: capitalize;
			}
		}

		&.Errors {
			background-color: $red-100d;
		}

		&.Confirm {
			background-color: $green-100d;
		}

		&.Info {
			background-color: transparent;
			border: 1px solid $grey-150d;

			.Msg {
				background-color: transparent;
				color: white;

				.MsgInfo {
					background-color: white;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background-image: url('../../images/IEIcons/info white.svg');
					}
				}
			}

			.okButton input {
				@include dvu-button-green-large-m();
				text-transform: capitalize;
			}
		}

		&.Warning {
			background-color: $red-100d;
		}
	}
}
