﻿/*Checkboxes General*/
input[type="checkbox"] {
	@include mvu-checkbox(16px, 16px, $grey-400d, 1px);

	&:hover {
		background-color: $grey-300d;
	}

	&:checked {
		background-color: $yellow-400d;

		&:hover {
			background-color: $yellow-300d;
		}
	}
}

/*Shedina*/
.CouponUserData input[type="checkbox"][id$="chkRicAuto"] {
	vertical-align: middle;
	margin-right: 3px; 
}

/*Tipo Causale*/
.SearchControlsStyle table[id$="chklTipoCausale"] input[type="checkbox"] {
	vertical-align: middle;
}

/*GDPR Switch*/
.Gdprswitch {
	position: relative;
	display: inline-block;

	input[type="checkbox"] {
		height: 10px !important;
		width: 25px;
		margin: 2px 8px 2px 0px;
		border-radius: 48px;
		background-color: transparent;
		border: 1px solid $grey-150d;
		animation: none;

		&:hover {
			background-color: transparent;
		}

		&:checked {
			+ .gdprswitch-icon {
				right: 20px;
				background: $yellow-300d !important;
				width: 14px;
				height: 14px;
				position: absolute;
				left: 12px !important;
				top: 1px;
				border-radius: 50%;
			}
		}

		&:disabled {
			opacity: 0.2;

			+ .gdprswitch-icon {
				background: $grey-100d;
				opacity: 0.7;
			}
		}
	}

	.gdprswitch-icon {
		right: 20px;
		top: 1px !important;
		width: 14px !important;
		height: 14px !important;
		left: 0px !important;
		animation: none !important;
		box-shadow: none !important;
		background: $grey-150d !important;
		border-radius:50%;
		position:absolute;
	}
}

.gdprPopUp div:nth-child(2) input[type="checkbox"],
.editGdpr .Gdprswitch input[type="checkbox"],
.nuovoUtente3 .CheckAll .cellaSx.Top.Gdprswitch input[type="checkbox"] {
	width: 25px;
	height: 12px;
	border-radius: 48px;
	background: $transparent;
	border: 1px solid $grey-200;
	margin-left: -2px;
	animation-name: none;

	& ~ .gdprswitch-icon {
		position: absolute;
		left: 8px;
		top: 10px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: $white;
		-webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
		box-shadow: 0 0 .25em rgba(0,0,0,.3);
		-webkit-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		pointer-events: none;
	}

	&:checked {
		& ~ .gdprswitch-icon {
			left: 28px;
			background: $yellow-400;
		}
	}
}

.nuovoUtente3 .CheckAll .cellaSx.Top.Gdprswitch input[type="checkbox"] {
	& ~ .gdprswitch-icon {
		left: 12px;
		top: 4px;
	}

	&:checked ~ .gdprswitch-icon {
		left: 32px;
	}
}