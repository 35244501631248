@import "visual-uplift-personal-menu-animations";

.personalMenu__body {
	&__data {
		font-size: $font-10;
		line-height: 1.5;
		margin-bottom: 15px;
		position: relative;
		text-align: left;
		width: 100%;

		&__valuta {
			span[id$='_lblValuta'] {
				font-weight: bold;
			}
		}

		&__panelSaldo {
			span {
				&:last-child {
					font-weight: bold;
				}
			}
		}

		&__left {
			display: inline-block;
			float: left;
			position: relative;
			width: 45%;

			> div {
				line-height: 13px;
				letter-spacing: 0.17px;
				margin-right: 10px;
				padding-right: 20px;
				border-right: 1px solid $grey-100d;
			}

			&.bn-init {
				.saldo-amount {
					visibility: hidden;

					&:after {
						content: '';
						display: inline-block;
						visibility: visible;
						position: relative;
						right: 7px;
						top: -4px;
						width: 3px;
						height: 3px;
						border-radius: 50%;
						animation: notification-loader-amount 1.5s infinite linear;
					}
				}

			}

			span {
				display: inline-block;
				font-size: $font-10;

				&:last-child {
					float: right;
					font-weight: bold;
					text-align: right;
					color: $grey-300d;
				}
			}

			&__loader {
				background: rgba(255, 255, 255, .92);
				display: none;
				height: 100%;
				left: -1px;
				padding-right: 0 !important;
				position: absolute;
				top: 0;
				width: 43%;
				z-index: 1;
				pointer-events: none;

				&__indicator {
					border: 3px solid rgba(235,169,23,.3);
					border-top-color: $yellow-300;
					height: 21px;
					left: calc(50% - 28.5px);
					top: calc(50% - 12.5px);
					width: 21px;
				}
			}

			.panelShowTPBonuses {
				.btnShowTPBonuses {
					position: absolute;
					right: 31px;
					height: 13px;
					width: 13px;
					background: $grey-300d;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/hide.svg');
					mask-repeat: no-repeat;
					mask-size: auto 13px;
					mask-position: right center;
					border: none;
					cursor: pointer;

					&:hover {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/visible.svg');			
					}
				}
			}
		}

		&__right {
			display: inline-block;
			float: right;
			width: 55%;

			> div {
				line-height: 13px;
				letter-spacing: 0.17px;
			}
		}

		[id$="btnHiddenSaldo"] {
			background: $grey-300d;
			display: inline-block;
			height: 12px;
			left: calc(50% - 67px);
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg');
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg');
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			position: absolute;
			width: 12px;
			border: none;
			cursor: pointer;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/solid/refresh.svg') no-repeat center !important;
				mask-image: none;
			}
		}

		&.fullWidth {
			> div {
				float: none;
				width: 100%;
			}

			.personalMenu__body__data__left__loader {
				width: 100%;
			}

			.personalMenu__body__data__left__loader__indicator {
				left: calc(50% - 10.5px);
				top: calc(50% - 10.5px);
			}
		}
	}
}
