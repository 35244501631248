﻿.Msg {
	@include dvu-message-style($font-14);	
	padding: 10px 12px;	

	&.msgConfirm {
		background-color: $green-100d;
	}

	&.msgError,
	&.msgWarning {
		background-color: $red-100d;
	}

	&.msgInfo {
		background-color: $white;
	}

	.MsgTop {
		height: 0;
	}

	.MsgTopDX {
		height: 0;
	}

	.MsgCnt {
		padding-bottom: 6px;

		.Title {
			font-size: $font-16;
			font-weight: 500;
			padding: 0 0 5px 5px;
		}

		.Txt {
			padding-left: 5px;
		}

		.SubTitle {
			font-weight: bold;
			font-size: 11px;
			padding: 0 0 5px 5px;
		}

		table {
			td {
				padding-right: 4px;
				padding-top: 3px;
			}
		}
	}

	.MsgBtm {
		height: 0;
	}

	.MsgBtmDX {
		height: 0;
	}

	.MsgConfirm,
	.MsgInfo,
	.MsgWarning,
	.MsgErrors {
		border-radius: 50%;
		background-position: center;
		background-size: 15px;
		background-repeat: no-repeat;
		width: 24px;
		height: 24px;
	}

	.MsgInfo {
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
		background-color: $grey-150d;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: none;
			background-repeat: no-repeat;
			background-size: 24px;
			background-image: url('../../images/IEIcons/info.svg');
		}
	}

	.MsgWarning,
	.MsgErrors {
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
		background-color: $red-300d;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: none;
			background-repeat: no-repeat;
			background-size: 24px;
			background-image: url('../../images/IEIcons/warning.svg');
		}
	}

	.MsgConfirm {
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg');
		background-color: $green-300d;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: none;
			background-image: url('../../images/IEIcons/success.svg');
			background-repeat: no-repeat;
			background-size: 24px;
		}
	}
}

//style for single-line messages without a title
.messagePanel {
	@include dvu-message-style ($font-14);	
	padding: 8px 16px 8px 51px;	

	.messagePanelTextContainer {
		text-align: left;
		position: relative;

		.messagePanelText {
			a {
				font-weight: bold;
			}
		}
	}

	&.confirm {
		background-color: $green-100d;

		.messagePanelIconContainer {
			.messagePanelIcon {

				&::after {
					content: '';
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg');
					background-color: $green-300d;
					width: 22px;
					height: 22px;
					top: 6px;
					left: 16px;
					position: absolute;
				}
			}
		}
	}

	&.alert {
		background-color: $red-100d;

		.messagePanelIconContainer {
			background: none;

			.messagePanelIcon {
				background: none;

				&::after {
					content: '';
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
					background-color: $red-300d;
					width: 22px;
					height: 22px;
					top: 6px;
					left: 17px;
					position: absolute;
				}
			}
		}
	}
}

#tblMainContent {
	.tdCN {
		.messagePanel {
			margin-bottom: 16px;
		}
	}
}