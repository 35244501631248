﻿#sideCasinoGamesWidget {
	width: 100%;
	margin-top: 15px;
	padding: 0px;
	border-radius: 0px;
	background-color: transparent;
	position: static;
	overflow: hidden;
	border: none;

	.HomeDXTitleSideCasinoGames {
		@include dvu-titlebar();
	}

	.sideCasinoGamesContainer {
		position: relative;
		z-index: 1;
		width: 100%;
		height: auto;
		overflow: hidden;
		margin: 0px auto 6px auto;
		text-align: unset;

		&:last-of-type {
			margin-bottom: 0px;
		}

		a {
			width: 126px;
			height: 126px;
			margin: 0px;

			&:first-of-type {
				float: left;
			}

			&:last-of-type {
				float: right;
			}

			&:hover {
				box-shadow: inset 0px 0px 0px 3px $yellow-400d;
			}

			img {
				display: inline-block;
				position: relative;
				width: 100%;
				height: auto;
				z-index: -1;
			}
		}
	}
}
