﻿.divSliderRel {
	margin-bottom: 8px;

	.MainHomeNews {
		position: relative;
		margin: 0px;
		padding: 0px 0px 26px 0px;
		max-height: 286px;
		background-color: $grey-500d;
		overflow: hidden;

		& ~ .divMainWinV2 {
			position: relative;
			margin-top: -26px;
		}

		#HNImages {
			width: 100%;
			height: auto;

			&:not(.slick-initialized) {
				display: none;
			}

			.slick-list {

				.slick-track {

					.slick-slide {
						max-height: 260px;

						&::before {
							display: block;
							position: absolute;
							right: 0;
							bottom: 0;
							content: "";
							background-image: linear-gradient( to top, $black, rgba(0, 0, 0, 0.85) 27%, $transparent);
							width: 100%;
							height: 60px;
						}

						> img {
							width: 100%;
							height: auto;
						}

						.HNContent {
							position: absolute;
							left: 0px;
							bottom: 0px;
							width: 100%;
							min-height: 45px;
							max-height: 80px;
							background-image: none;
							background-color: $transparent;
							z-index: 16;
							overflow: hidden;

							#HNTitle {
								@include dvu-font-regular($font-14);
								position: absolute;
								top: 0px;
								left: 0px;
								padding: 0px 0px 0px 16px;
								height: 14px;
								width: 65%;
								line-height: 14px;
								letter-spacing: 0.28px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							#HNTesto {
								@include dvu-font-medium($font-24);
								position: absolute;
								left: 0px;
								bottom: 0px;
								padding: 0px 0px 0px 16px;
								width: 65%;
								line-height: 1.17;
								letter-spacing: 0.72px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							.HNOdds {
								position: absolute;
								bottom: 0px;
								right: 0px;
								width: 35%;
								height: 100%;

								.HNSottoEvento {
									display: none;
									overflow: hidden;
									position: relative;
									padding-left: 5px;
									font-size: 11px;
									text-overflow: ellipsis;
									white-space: nowrap;

									a {
										text-decoration: none;
									}
								}

								.HNOddsCnt {
									position: absolute;
									right: 0px;
									bottom: 0px;
									height: 100%;
									padding: 0px 12px 6px 0px;

									.HNOdd {
										display: inline-block;
										margin: 0px 0px 0px 6px;
										width: 74px;
										height: 100%;
										cursor: pointer;

										&:first-of-type {
											margin: 0px;
										}

										.HNTQ {
											@include dvu-font-medium($font-11);
											text-align: center;
											line-height: 1.18;
											letter-spacing: 0.17px;
											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;
										}

										.HNQ {
											@include dvu-button-odd-regular();
											position: absolute;
											bottom: 6px;
											width: 74px;
										}
									}
								}
							}

							#HNData, .HNEvento {
								display: none;
							}
						}
					}
				}
			}
		}

		ul.slick-dots {
			height: 26px;
			width: auto;
			position: absolute;
			bottom: 0px;
			right: 12px;
			z-index:1;

			li {
				display: inline-block;
				margin: 0px 0px 0px 6px;
				padding: 8px 0px;
				height: 100%;

				button {
					width: 10px;
					height: 10px;
					border: none;
					border-radius: 50%;
					color: $transparent;
					background-color: $grey-200d;
					cursor: pointer;

					&:focus,
					&:focus-visible {
						outline: none;
					}
				}

				&:first-of-type {
					margin: 0px;
				}

				&.slick-active {

					button {
						background-color: $yellow-300d;

						&:hover {
							cursor: default;
						}
					}
				}

				&:focus,
				&:focus-visible {
					outline: none;
				}
			}
		}

		.pnlLink {
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			background-image: none;
			cursor: pointer;
		}
	}

	@media (max-width: 1350px) {

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			max-width: 762px;
		}
	}

	@media (min-width: 1351px) and (max-width: 1690px) {

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			max-width: 1102px;
		}
	}

	@media (min-width: 1691px) and (max-width: 2075px) {

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			max-width: 1347px;
		}
	}

	@media (min-width: 2076px) {

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			max-width: 1148px;
		}
	}
}

.divMainWin {
	margin-top: -26px;
	clear: both;
	height: 26px;

	.winOfDay,
	.winOfWeek {
		@include dvu-font-regular($font-12);
		position: relative;
		float: left;
		height: 26px;
		padding: 0px 10px 0px 12px;
		line-height: 26px;
		letter-spacing: 0.18px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background-image: none;

		&:hover {
			cursor: pointer;
		}

		.title {
			text-transform: none;
			padding: 0px 8px 0px 0px;

			&:hover {
				color: $yellow-200d;
			}
		}
	}

	.winOfDay {

		.user,
		.importo {
			color: $green-200d;

			&:hover {
				color: $green-100d;
			}
		}
	}

	.winOfWeek {

		.user,
		.importo {
			color: $red-200d;

			&:hover {
				color: $red-100d;
			}
		}
	}
}

.divMainWinV2 {
	@extend .divMainWin;
	margin-top: 0px;
}