﻿div[id$="trTipoBonusMultiple"],
div[id$="trTipoBonusSistemi"],
div[id$="trTipoBonusDI"] {
	position: relative;
	border-radius: 0;
	border: none;
	min-height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	margin-left: -6px;
	margin-right: -6px;
	color: $grey-300d;
	font-weight: 500;
	background-color: $yellow-200d;
	background-image: url('../../images/treestsr_icon_website.svg');
	background-repeat: no-repeat;
	background-position: bottom right;
}
