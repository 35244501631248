﻿.SXWidgetTitle, .LogNegozioTitle {
	@include dvu-titlebar();
}

.SXWidgetContent, .divMainLogNegozio {
	border-radius: 6px;
	padding: 10px 12px;
	background-color: $grey-300d;
	@include dvu-font-regular($font-14);
	line-height: 16px;
	margin-bottom: 8px;

	input, a.okButton {
		width: 100%;

		&:-webkit-autofill::first-line {
			font-size: 11px !important;
		}

		&[type="text"] {
			margin-bottom: 12px;
		}
	}

	.LblWidget {
		margin-bottom: 6px;
		padding-left: 6px;

		&.CheckCpnTxt {
			font-size: $font-12;
			color: $white-080;
			letter-spacing: 0.24px;
		}
	}

	.validation-error {
		@include dvu-input-errormessage();
		position: relative;
		top: -10px;
		margin-left: 0px !important;
	}
}