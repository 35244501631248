@import "./mixins-visual-uplift-footer";

#divFooter {
	position: relative;
	z-index: 1000;
	width: 100%;
	margin: 0;
	min-width: initial;
	clear: none;
	background: none;
	background: $grey-300d;
	.spacer13 {
		height: 0;
	}

	.Main {
		margin: 0 auto;
		height: 44px;
		position: relative;
		background-repeat: no-repeat;
		background-position: right center;
		background-image: url('../../images/Logo_slogan.svg');
		background-size: 120px;
		padding-left: 100px;
		padding-right: 100px;
		background-position-x: calc(100% - 100px);

		@media screen and (max-width: 1659px) {
			padding-left: 30px;
			padding-right: 30px;
			background-position-x: calc(100% - 30px);
		}

		.Promo {
			float: right;
			height: 72px;
			font-size: 0;
			color: #fbd31c;
			display: none;
		}

		.Txt {
			float: left;
			position: relative;
			width: 1020px;
			height: 48px;
		}

		.Terms {
			margin-top: 20px;

			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					float: left;
					font-weight: 500;
					font-size: $font-11;
					line-height: 10px;

					&:first-child {
						border: 0;

						a {
							margin-left: 0;
						}
					}

					&:nth-child(6) {
						@include dvu-new-label;

						&::before {
							top: -12px;
						}
					}

					a {
						color: $white;
						margin-left: 30px;

						&:hover {
							cursor: pointer;
							color: $yellow-200d
						}

						&.goToTop {
							display: block;
							position: fixed;
							opacity: 0;
							right: 30px;
							width: 50px;
							height: 50px;
							color: $transparent;
							background-color: $grey-200d;
							background-position: 0 0;
							background-repeat: no-repeat;
							margin: 0;
							padding: 0;
							font-size: 11px;
							line-height: 20px;
							text-align: center;
							text-shadow: none;
							text-transform: uppercase;
							z-index: 10000;
							-webkit-transition: opacity 0.5s;
							-moz-transition: opacity 0.5s;
							transition: opacity 0.5s;
							border: none;
							border-radius: 50%;
							overflow: hidden;

							&::after {
								content: "";
								mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/arrow-up-alt.svg');
								mask-repeat: no-repeat;
								mask-size: 25px;
								mask-position: center;
								width: 50px;
								height: 50px;
								position: absolute;
								top: 0px;
								left: 0px;
								background-color: $white;

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									background-color: $grey-200d;
									background-image: url('../../images/IEIcons/back_to_top.svg');
									background-position: center;
									background-repeat: no-repeat;
									background-size: 13px;
									mask-image: none;
								}
							}

							&:hover {
								background-color: $grey-150d;
								background-position: 0 -57px;

								&::after {
									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										background-color: $grey-150d;
										background-image: url('../../images/IEIcons/back_to_top.svg');
										background-position: center;
										background-repeat: no-repeat;
										background-size: 13px;
										mask-image: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.mainFooter {
	background: $grey-400d;
	width: 100%;
	position: relative;
	z-index: 10;
	height: 149px;

	.Disclaimer_fr-FR,
	.Disclaimer_en-GB,
	.Disclaimer_it-IT {
		padding-left: 100px;
		padding-right: 100px;
		background-position-x: calc(100% - 100px);
		width: initial !important;

		@media screen and (max-width: 1659px) {
			padding-left: 30px;
			padding-right: 30px;
			background-position-x: calc(100% - 30px);
			width: initial !important;
		}
	}

	.Disclaimer_it-IT {
		text-align: left;
	}

	.first {
		img {
			padding-right: 15px;
		}
	}

	.third {
		margin-top: 20px;
		padding-right: 14px;
	}

	.clear {
		clear: both;
		height: 0;
		font-size: 0;
	}

	.footerLic {
		position: relative;
		width: calc(100% - $providersListWidth);
		max-width: 700px;
		display: inline-block;
		margin: 0 auto;
		height: 100px;
	}

	.footerLicLeft {
		font-size: $font-11;
		cursor: initial;
		padding-top: 20px;
		left: 0;
		text-align: left;
		font-family: open_sansregular, Arial, sans-serif;
		line-height: 15px;
		color: $grey-150d;
		display: inline-block;
		position: absolute;
		height: 60px;
		width: 90%;

		span:last-child {
			display: block;
			height: 50px;
		}

		.footerLicLeftHead {
			color: $white;
			font-size: $font-12;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			margin-bottom: 5px;
		}

		.footerLicLeftLink {
			color: $white;

			&:hover {
				color: $yellow-200d;
			}
		}
	}

	.footerProviders {
		@include providersLogos($providersList, $providersPath1, $providersPath2, $singleLogoWidth, $gapBetweenLogos);
		display: block;
		width: $providersListWidth;
		height: 60px;
		text-align: left;
		text-transform: uppercase;
		line-height: 60px;
		color: $white;
		font-family: Arial, regular;
		font-size: $font-12;
		float: right;
	}

	.footerLicRight {
		margin-top: 7px;
		margin-bottom: 24px;
		display: block;
		position: absolute;
		height: 37px;
		width: 100%;
		right: 0;
		overflow: hidden;
		padding: 5px;
		background-color: rgba(58, 62, 69, 0.1);

		ul {
			float: right;
			margin: 0 95px;

			@media screen and (max-width: 1659px) {
				margin: 0 25px;
			}

			li {
				float: left;
				list-style-type: none;
				margin-right: 15px;
				height: 28px;
				overflow: hidden;

				&:last-child {
					margin-right: 0;
				}

				a {
					height: 43px;
					overflow: hidden;

					&:not([href]) {
						cursor: default;

						img:hover {
							opacity: 1;
						}
					}

					img {
						position: relative;
						height: 56px;
						top: -28px;

						&:hover {
							top: -28px;
							opacity: 0.7;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}
