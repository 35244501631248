﻿@import "../../../../../../../../../../../../SkySport365/ISBets/Web/Shared/MVU/variables";

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;

	&:disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}

button {
	background-color: transparent;
	color: inherit;
	border-width: 0;
	padding: 0;
	cursor: pointer;
}

figure {
	margin: 0;
}

ul, ol, dd {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
}

p {
	margin: 0;
}

cite {
	font-style: normal;
}

fieldset {
	border-width: 0;
	padding: 0;
	margin: 0;
}

input {
	background-image: none;

	&[type='text'],
	&[type='date'],
	&[type='email'],
	&[type='number'],
	&[type='month'],
	&[type='password'],
	&[type='datetime-local'],
	&[type='search'],
	&[type='tel'],
	&[type='time'],
	&[type='url'],
	&[type='week'] {
		@include dvu-input(192px, 28px, 28px, 0.17px);

		&:-webkit-autofill,
		&:-webkit-autofill:hover {
			border: 1px solid $grey-200d;
		}

		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			background-color: $grey-400d;
			border: 1px solid $grey-100d;
		}

		&:-webkit-autofill::first-line {
			font-size: 11px !important;
		}
	}

	&[type='submit'],
	&[type='button'] {
		text-transform: capitalize !important; //DVUDElete check what overwrite this
	}

	&[type='radio'] {
		@include dvu-radio(true);
	}
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
	margin: 0;
}

/*remove autocomplete color and settings*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: inherit;
	-webkit-box-shadow: 0 0 0 100px $grey-400d inset !important;
	-webkit-text-fill-color: $white;
	transition: background-color 5000s ease-in-out 0s;

	&::first-line {
		font-family: $roboto;
		font-size: 11px;
		font-weight: normal;
	}
}

input:-internal-autofill-selected {
	-webkit-box-shadow: 0 0 0 100px $grey-400d inset !important;

	&::first-line {
		font-family: $roboto;
		font-size: 0.7em;
		font-weight: normal;
	}
}

/*remove spinner  buttons to number inputs*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
//Firefox
input[type=number]{
	-moz-appearance:textfield !important;
}

select {
	@include dvu-select(126px, 28px);

	&::-ms-expand {
		display: none;
	}
}