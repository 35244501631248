//positions
@mixin center-align {
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-align {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

@mixin vertical-align-absolute {
    @include vertical-align;
    position: absolute;
}

@mixin horizontal-align-absolute {
    @include horizontal-align;
    position: absolute;
}

@mixin center-align-absolute {
    @include center-align;
    position: absolute;
}

//transition
@mixin transition($transition-property...) {
    transition: $transition-property;
}

//transform
@mixin transform($transform-property...) {
    transform: $transform-property;
}


//clearfix
@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

//placeholder
@mixin placeholder($color, $weight: "") {
    // sass-lint:disable-all
    &::-webkit-input-placeholder {
        color: $color;
        font-weight: $weight;
    }

    &:-moz-placeholder {
        color: $color;
        font-weight: $weight;
    }

    &::-moz-placeholder {
        color: $color;
        font-weight: $weight;
    }

    &:-ms-input-placeholder {
        color: $color;
        font-weight: $weight;
    }
    // sass-lint:enable-all
}

//MVU specific mixins
@mixin mvu-input($height, $width, $color, $font-family, $font-size, $font-weight) {
    appearance: none;
    background: $grey-500;
    border: 1px solid $grey-300;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: none;
    color: $white;
    font: $font-weight $font-size $font-family;
    fill-opacity: 1;
    height: $height;
    letter-spacing: 0.3px;
    margin: 0;
    opacity: 1;
    text-align: left;
    padding: 0 8px;
    width: $width;
    white-space: nowrap;
}

@mixin mvu-input-focus($height, $width, $color, $font-family, $font-size, $font-weight) {
	appearance: none;
	background: $white;
	border: 1px solid $grey-300;
	border-radius: 6px;
	box-sizing: border-box;
	box-shadow: none;
	color: $black;
	font: $font-weight $font-size $font-family;
	fill-opacity: 1;
	height: $height;
	letter-spacing: 0.3px;
	margin: 0;
	opacity: 1;
	text-align: left;
	padding: 0 8px;
	width: $width;
	white-space: nowrap;
    outline-color:transparent;
}


@mixin mvu-select($height, $width, $max-width, $color, $font-family, $font-size, $font-weight) {
    appearance: none;
    background-image: url('icons/angle-down.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;    
    border: solid 1px $grey-300;
    border-radius: 6px;
    color: $color;
    font: $font-weight $font-size $font-family;
    height: $height;
    letter-spacing: 0.3px;
    margin: 0;
    max-width: $max-width;
    padding: 0 20px 0 10px;
    position: relative;
    width: $width;
	background-color:$grey-500;
}

@mixin mvu-select-focus($height, $width, $max-width, $color, $font-family, $font-size, $font-weight) {
    appearance: none;
    background-image: url('icons/angle-up.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;
    border: solid 1px $grey-300;
    border-radius: 6px;
    color: $black;
    font: $font-weight $font-size $font-family;
    height: $height;
    letter-spacing: 0.3px;
    max-width: $max-width;
    padding: 0 20px 0 10px;
    position: relative;
    width: $width;
    background-color: $white;
    outline-color: transparent;
}

@mixin mvu-button($height, $width, $background, $color, $font-family, $font-size, $font-weight) {
    appearance: none;
    background: $background;
    border: 0;
    border-radius: 4px;
    box-shadow: none;
    display: inline-block;
    color: $color;
    height: $height;
    margin: 0;
    font: $font-weight $font-size $font-family;
    letter-spacing: 0.3px;
    line-height: $height;
    text-align: center;
    padding: 0;
    width: $width;
	
	&:focus:active{
		font-weight: normal;
	}
	
	&:disabled{
		font-weight:normal;
		pointer-events:none;
		opacity: 0.6;
	}
}

@mixin mvu-button-green($height, $width, $font-size) {
    @include mvu-button($height, $width, $green-200, $white, $roboto, $font-size, 500);
}

@mixin mvu-button-grey($height, $width, $font-size) {
    @include mvu-button($height, $width, $grey-300, $white, $roboto, $font-size, 500);
}

@mixin mvu-button-darkgrey($height, $width, $font-size) {
    @include mvu-button($height, $width, $grey-300-600, $grey-600, $roboto, $font-size, 500);
}

@mixin mvu-button-red($height, $width, $font-size) {
    @include mvu-button($height, $width, $red-200, $white, $roboto, $font-size, 500);
}

@mixin mvu-button-link($height, $width, $font-size, $font-weight) {
    @include mvu-button($height, $width, $transparent, $white, $roboto, $font-size, $font-weight);

    &:focus {
        background: $grey-600;
        outline: 0;
    }
}

@mixin mvu-desktop-button($height, $width, $background, $color, $font-family, $font-size, $font-weight, $border-color) {
    appearance: none;
    background: $background;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: none;
    display: inline-block;
    color: $color;
    height: $height;
    margin: 0;
    font: $font-weight $font-size $font-family;
    letter-spacing: normal;
    line-height: $height;
    text-align: center;
    padding: 0 8px;
    width: $width;
}

@mixin mvu-destktop-button-green($height, $width, $font-size, $font-weight, $border-color) {
    @include mvu-desktop-button($height, $width, $green-500-600-700-800, $black, $openSans, $font-size, $font-weight, $border-color);
}

@mixin mvu-destktop-button-green-hover($height, $width, $font-size, $font-weight, $border-color) {
    @include mvu-desktop-button($height, $width, $green-500-500-500-500, $black, $openSans, $font-size, $font-weight, $border-color);
}

@mixin mvu-destktop-button-gray($height, $width, $font-size, $font-weight, $border-color) {
    @include mvu-desktop-button($height, $width, $grey-1100-1200-1300-1400, $black, $openSans, $font-size, $font-weight, $border-color);
}

@mixin mvu-destktop-button-gray-hover($height, $width, $font-size, $font-weight, $border-color) {
    @include mvu-desktop-button($height, $width, $grey-1100-1100-1100-1100, $black, $openSans, $font-size, $font-weight, $border-color);
}

@mixin mvu-radio_button($height, $width, $background-color, $select-color, $border) {
    appearance: none;
    height: $height;
    margin: 0;
    position: relative;
    visibility: hidden;
    width: $width;

    &:after {
        background: $background-color;
        border-radius: 50%;
        content: '';
        display: block;
        height: $height;
        left: 0;
        position: absolute;
        top: 0;
        visibility: visible;
        width: $width;
        z-index: 1200;
    }

    &:before {
        @include transition(all 100ms ease-in-out);
        @include transform(scale(0));
        background: $select-color;
        border-radius: 50%;
        content: '';
        display: block;
        height: $height - 2 * $border;
        left: $border;
        position: absolute;
        top: $border;
        visibility: hidden;
        width: $width - 2 * $border;
        z-index: 1300;
    }

    &:checked {
        &:before {
            @include transform(scale(1));
            visibility: visible;
        }
    }
}

@mixin mvu-checkbox($height, $width, $background-color, $border) {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $background-color;
    border: solid $border $grey-200;
    border-radius: 4px;
    height: $height;
    width: $width;
    outline: none;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    
    @keyframes checkboxFocus {
        0% {
            box-shadow: 0 0 0px 4px rgba(255,255,255, 0.2);
        }
        100% {
            box-shadow: none;
        }
    }
    
    @keyframes checkboxPop {
        0% {
            @include transform(scale(0));
        }
        100% {
            @include transform(scale(1));
        }
    }

    &:checked {
        visibility: visible;
        background: url(icons/check.svg) no-repeat;
        background-color: $yellow-300;
        border: none;
        animation-name: checkboxFocus, checkboxPop;        
        animation-duration: 300ms, 100ms;
        animation-delay: 100ms, 0ms;
        animation-timing-function: steps(1, end), ease-in-out;        
    }
    
    &[checked] {
        animation-name: none;
    }
}

@mixin mvu-counter($background, $color) {
    background: $background;
    border: none;
    border-radius: 15px;
    box-shadow: none;
    color: $color;
    font-family: $roboto-condensed;
    font-size: $font-11;
    font-weight: bold;
    height: 18px;
    letter-spacing: 0.1px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 18px;
    z-index: 10;
}

@mixin mvu-new-label {
    border: 1px solid $green-300;
    border-radius: 30px;
    background: $white;
    color: $green-300;
    display: inline-block;
    font-size: $font-11;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 14px;
    margin: auto 6px;
    padding: 0 4px;
    width: auto;
    z-index: 999;
}

/* 
    ----------------
    DESKTOP MIXINS
    -----------------
*/
@mixin dvu-font-regular($font-size) {
    font-weight:normal;
    font-size: $font-size;
    font-family: $roboto;
}

@mixin dvu-font-medium($font-size) {
    font-weight: 500;
    font-size: $font-size;
    font-family: $roboto;
}

/*#region BUTTONS*/
@mixin dvu-button($height, $width, $background, $font-size, $line-height, $letter-spacing) {
    @include dvu-font-medium($font-size);
    appearance: none;
    background: $background;
    border-radius: 4px;
    border:1px solid transparent;
    box-shadow: none;
    display: inline-block;
    height: $height;
    width: $width;
    margin: 0;
    color: $white;
    letter-spacing: $letter-spacing;
    line-height: $line-height;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    text-transform: lowercase;
    outline:none;
	cursor:pointer;

    &:first-letter{
        text-transform:capitalize;
    }

    &:focus:active {
        font-weight: normal;
        opacity: 0.7;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

// transparent buttons
@mixin dvu-button-link($height, $width, $font-size, $line-height, $line-spacing, $dark-theme: true) {
    @include dvu-button($height, $width, transparent, $font-size, $line-height, $line-spacing);

    &:hover,
    &:focus:active {
        border: 1px solid $grey-150d;
    }

    &:disabled {
        color: $grey-400d;
    }
    //Checks if button's background is dark or light
    @if $dark-theme {
        color: $white;

        &:focus:active {
            background: $grey-400d;
        }
    }
    @else {
        color: $black;

        &:focus:active {
            background: $transparent;
        }
    }
}

@mixin dvu-button-link-small-s($dark-theme) {
    @include dvu-button-link(28px, 60px, $font-12, 1.17, 0.24px, $dark-theme);
    padding: 7px 0;
}

@mixin dvu-button-link-small-m($dark-theme) {
    @include dvu-button-link(28px, 126px, $font-12, 1.17, 0.24px, $dark-theme);
    padding: 7px 0;
}

@mixin dvu-button-link-small-l($dark-theme) {
    @include dvu-button-link(28px, 192px, $font-12, 1.17, 0.24px, $dark-theme);
    padding: 7px 0;
}

@mixin dvu-button-link-large-s($dark-theme) {
    @include dvu-button-link(36px, 126px, $font-16, 1.0, 0.32px, $dark-theme);
    padding: 10px 0;
}

@mixin dvu-button-link-large-m($dark-theme) {
    @include dvu-button-link(36px, 192px, $font-16, 1.0, 0.32px, $dark-theme);
    padding: 10px 0;
}

@mixin dvu-button-link-large-l($dark-theme) {
    @include dvu-button-link(36px, 258px, $font-16, 1.0, 0.32px, $dark-theme);
    padding: 10px 0;
}

// regular buttons
@mixin dvu-button-regular($height, $width, $font-size, $line-height, $line-spacing) {
    @include dvu-button($height, $width, $grey-200d, $font-size, $line-height, $line-spacing);

    &:hover {
        background: $grey-150d;
    }
}
@mixin dvu-button-small-s(){
    @include dvu-button-regular(28px, 60px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}
@mixin dvu-button-small-m() {
    @include dvu-button-regular(28px, 126px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}
@mixin dvu-button-small-l() {
    @include dvu-button-regular(28px, 192px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}
@mixin dvu-button-large-s() {
    @include dvu-button-regular(36px, 126px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}
@mixin dvu-button-large-m() {
    @include dvu-button-regular(36px, 192px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}
@mixin dvu-button-large-l() {
    @include dvu-button-regular(36px, 258px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

//green button
@mixin dvu-button-green($height, $width, $font-size, $line-height, $line-spacing) {
    @include dvu-button($height, $width, $green-300d, $font-size, $line-height, $line-spacing);

    &:hover {
        background: $green-200;
    }
}

@mixin dvu-button-green-small-s() {
    @include dvu-button-green(28px, 60px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}
@mixin dvu-button-green-small-m() {
    @include dvu-button-green(28px, 126px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}
@mixin dvu-button-green-small-l() {
    @include dvu-button-green(28px, 192px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-green-large-s() {
    @include dvu-button-green(36px, 126px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}
@mixin dvu-button-green-large-m() {
    @include dvu-button-green(36px, 192px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}
@mixin dvu-button-green-large-l() {
    @include dvu-button-green(36px, 258px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

//red buttons

@mixin dvu-button-red($height, $width, $font-size, $line-height, $line-spacing) {
    @include dvu-button($height, $width, $red-300d, $font-size, $line-height, $line-spacing);

    &:hover {
        background: $red-200;
    }
}
@mixin dvu-button-red-small-s() {
    @include dvu-button-red(28px, 60px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-red-small-m() {
    @include dvu-button-red(28px, 126px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-red-small-l() {
    @include dvu-button-red(28px, 192px, $font-12, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-red-large-s() {
    @include dvu-button-red(36px, 126px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

@mixin dvu-button-red-large-m() {
    @include dvu-button-red(36px, 192px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

@mixin dvu-button-red-large-l() {
    @include dvu-button-red(36px, 258px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

//yellow buttons

@mixin dvu-button-yellow($height, $width, $font-size, $line-height, $line-spacing) {
    @include dvu-button($height, $width, $yellow-400d, $font-size, $line-height, $line-spacing);

    &:hover {
        background: $yellow-200d;
    }
}
@mixin dvu-button-yellow-small-s() {
    @include dvu-button-yellow(28px, 60px, $font-24, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-yellow-small-m() {
    @include dvu-button-yellow(28px, 126px, $font-24, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-yellow-small-l() {
    @include dvu-button-yellow(28px, 192px, $font-24, 1.17, 0.24px);
    padding: 7px 0;
}

@mixin dvu-button-yellow-large-s() {
    @include dvu-button-yellow(36px, 126px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

@mixin dvu-button-yellow-large-m() {
    @include dvu-button-yellow(36px, 192px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

@mixin dvu-button-yellow-large-l() {
    @include dvu-button-yellow(36px, 258px, $font-16, 1.0, 0.32px);
    padding: 10px 0;
}

//Odd buttons
@mixin dvu-button-odd($height, $width, $font-size, $line-height, $letter-spacing) {
    @include dvu-button($height, $width, $grey-200d, $font-size, $line-height, $letter-spacing);

    &:active {
        background: $yellow-300d;
        color: $grey-300d;
    }

    &:hover {
        background: $grey-150d;
    }
}

@mixin dvu-button-odd-small-s() {
    @include dvu-button-odd(20px, 36px, $font-12, 1.17, 0.24px);
    padding: 2px 0;

    &:active {
        background: $yellow-400d;
        color: $black;
    }
}

@mixin dvu-button-odd-small() {
    @include dvu-button-odd(20px, 46px, $font-13, 1.15, 0.26px);
    padding: 2px 0;
}

@mixin dvu-button-odd-regular() {
    @include dvu-button-odd(24px, 60px, $font-13, 1.15, 0.26px);
    padding: 4px 0;
}

@mixin dvu-button-odd-middle() {
    @include dvu-button-odd(18px, 75px, $font-13, 1.15, 0.26px);
    padding: 1px 0;
}

@mixin dvu-button-play() {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: $yellow-300d;
    border-radius: 6px;
    background-image: url(../../../../../ISolutions/ISBets/Web/ISBetsWeb/ISBets/App_Themes/_common/images/icons/pw365-iconpack/current/system/regular/solid/play.svg);
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 5px center;

    &:hover {
        background-color: $yellow-200d;
        cursor: pointer;
    }
}

/*#endregion*/

/*#region INPUT FIELDS*/
@mixin dvu-input($width, $height, $line-height, $letter-spacing) {
    appearance: none;
    background: $grey-400d;
    border: 1px solid $grey-200d;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
    color: $white;
    @include dvu-font-regular($font-11);
    line-height: $line-height;
    width: $width;
    height: $height;
    letter-spacing: $letter-spacing;
    text-align: left;
    white-space: nowrap;
    padding: 0 5px;

    &:focus {
        background: $grey-500d;
        border: 1px solid $grey-100d;
        color: $white;
        outline: none;
    }

    &.input-validation-error {
        border: 1px solid $red-200d !important;
    }
}

@mixin dvu-input-betslip($width, $height, $line-height, $letter-spacing) {
    @include dvu-input($width, $height, $line-height, $letter-spacing);
	background: $white;
    color: $grey-300d;
	font-size: $font-14;
    font-weight: 500;
    text-align:right;
    padding: 0 13px 0 5px;
	
	&:focus {
        background: $white;
        color: $grey-300d;
    }
}

@mixin dvu-input-errormessage() {
    @include dvu-font-regular($font-11);
    padding-left: 8px;
    line-height: 14px;
    display: block;
}

@mixin dvu-select($width, $height) {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    @include dvu-font-regular($font-11);
    background-image: url('../../../../../ISolutions/ISBets/Web/CommonClientScripts/ngMonoRepo/sks365/apps/isbets-web/src/App_Themes/_common/images/IEIcons/arrow.svg');
    background-position: right 6px center;
    background-repeat: no-repeat;
    border: solid 1px $grey-200d;
    border-radius: 4px;
    color: $white;
    width: $width;
    height: $height;
    letter-spacing: 0.24px;
    margin: 0;
    padding: 0 18px 0 6px;
    position: relative;
    background-color: $grey-400d;

    &:focus {
        background-color: $grey-500d;
        border: 1px solid $grey-100d;

        &:active {
            background-image: url('../../../../../ISolutions/ISBets/Web/CommonClientScripts/ngMonoRepo/sks365/apps/isbets-web/src/App_Themes/_common/images/IEIcons/arrow_up.svg');
        }
    }

    optgroup {
        background-color: $white;
        font-size: $font-12;
        color: $black;

        label {
            font-weight: bold;
        }
    }

    option {
        background-color: $white;
        padding: 5px 0;
        color: $black;
    }
}

@mixin dvu-radio($dark-theme: true) {
    appearance: none;
    position: relative;
	top:3px;
	padding-right: 5px;

    &:after {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid $grey-150d;
        border-radius: 8px;
    }

    &:hover {
        &:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: $yellow-300d;
            border-radius: 6px;
            position: absolute;
            top: 2px;
            left: 2px;
        }

        @if $dark-theme {
            //
        }
        @else {
            &:after {
                border-width: 2px;
            }
        }
    }

    &:checked {
        &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: $yellow-300d;
            border-radius: 5px;
            position: absolute;
            top: 3px;
            left: 3px;
        }
    }
}

/*#endregion*/

@mixin dvu-link-dark($font-size) {
    @include dvu-font-regular($font-size);
    color: $yellow-300d;

    &:hover {
        color: $yellow-200d;
    }

    &:focus:active {
        font-weight: normal;
        opacity: 0.7;
    }
}

@mixin dvu-link-light($font-size) {
    @include dvu-font-medium($font-size);
    color: $black;

    &:hover {
        color: $grey-200d;
    }

    &:focus:active {
        font-weight: normal;
        opacity: 0.7;
    }
}

/*#region TITLE BARS*/
@mixin dvu-titlebar() {
    @include dvu-font-regular($font-20);
    position: static;
    width: 100%;
    height: 38px;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    text-indent: 0px;
    color: $white;
    padding: 0px 0px 0px 12px;
    margin: 0px;
    background-image: none;
    background-color: transparent;
    overflow: hidden;
}
/*#endregion*/

/*#region Tooltips*/
@mixin dvu-tooltip-default-simple($font-size) {
    @include dvu-font-regular($font-size);
    margin: 0px;
    padding: 8px 14px;
    background-color: $white;
    color: $black;
    border: none;
    border-radius: 6px;
    line-height: 1.29;
    letter-spacing: 0.28px;
    text-align: left;
}
/*#endregion*/

/*#region Notification messages for Betslip*/
@mixin dvu-notificationMess-warning() {
    @include dvu-font-regular($font-14);
    position: relative;
    margin: 0;
    padding: 12px 12px 12px 52px;
    background-color: $red-100d;
    background-image: none !important;
    border: none;
    border-radius: 6px;
    color: $grey-300d;
    line-height: 1.29;
    letter-spacing: 0.28px;
    text-align: left;

    &:before {
        content: '';
        mask-image: url('../../../../../ISolutions/ISBets/Web/CommonClientScripts/ngMonoRepo/sks365/apps/isbets-web/src/App_Themes/_common/images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
        width: 100%;
        height: 100%;
        background-color: $red-300d;
        display: block;
        position: absolute;
        left: 0;     
        top: 0;   
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 22px;
        mask-size: 22px;
        -webkit-mask-position: 20px center;
        mask-position: 20px center;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-color: $transparent;
            background-image: url('../../../../../ISolutions/ISBets/Web/CommonClientScripts/ngMonoRepo/sks365/apps/isbets-web/src/App_Themes/_common/images/IEIcons/warning.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            mask-image: none;
        }
    }
}
/*#endregion*/

@mixin dvu-message-style($font-size) {
    @include dvu-font-regular($font-size);
    font-stretch: normal;
    font-style: normal;
    width: fit-content;
    margin: auto;
    color: $black;
    line-height: 18px;
    letter-spacing: 0.4px;
    position: relative;
    border-radius: 6px;
}

@mixin dvu-new-label {
    position: relative;

    &:before {
        content: "new";
        display: block;
        position: absolute;
        top: -7px;
        right: -14px;
        width: 26px;
        height: 10px;
        border-radius: 4px;
        border: solid 1px $green-200;
        background-color: $white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.54);
        font-family: $roboto;
        font-size: $font-10;
        line-height: 8px;
        text-align: center;
        color: $grey-400;
        text-transform: none;
        letter-spacing: normal;
    }
}