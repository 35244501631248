﻿.MarketingMessage {
	z-index: 9999;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0,0,0,0.5);

	.main {
		top: 20vh;
	}
}
