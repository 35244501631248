//add 'scrollbar-custom' class to the element where custom scrollbar is needed and where 'jspScrollable' plugin is not suitable to be applied
.scrollbar-custom {
    scrollbar-width: thin; //Firefox only
    scrollbar-color: $yellow-100d $grey-200d; //Firefox only

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
        background-color: $grey-200d;
        box-shadow: none; //live page reset
    }

    &::-webkit-scrollbar-thumb {
        background-color: $yellow-100d;
        background-image: none; //live page reset
        border: none; //live page reset
    }
}