﻿//PRIVREMENO _OBRISATI!!!
/*#divCoupon {
	background: beige !important;
}*/

.BlogWidgetHeader {
	@include dvu-titlebar();
}

.BlogWidget {
	position: relative;
	padding: 0px;

	&::after {
		background-image: linear-gradient(0deg, $grey-500d, $transparent);
		content: "";
		width: 100%;
		height: 15px;
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.BlogWidgetContent {
		position: relative;
		margin: 0px;
		height: 168px;

		.jspContainer {
			position: relative;
			overflow: hidden;

			.jspPane {
				position: absolute;

				.blogArticle {
					position: relative;
					float: left;
					margin: 0px 5px;
					padding: 5px 0px;
					background-color: $transparent;
					border-bottom: 1px solid $grey-200d;

					&:first-child {
						padding-top: 10px;
					}

					&:nth-last-child(2) {
						border-bottom: none;
						padding-bottom: 10px;
					}

					.blogArticleImage {
						position: relative;
						float: left;
						width: 78px;
						height: 56px;
						border: none;
						border-radius: 6px;
						overflow: hidden;

						img {
							position: absolute;
							border: none;
							outline: none;
							height: 100%;
							width: auto;
						}
					}

					.blogArticleContent {
						@include dvu-font-regular($font-11);
						padding: 0px 0px 0px 5px;
						float: right;
						width: 162px;
						height: 56px;

						.blogArticleContentTitle {
							height: 39px;
							color: $grey-100d;
							line-height: 13px;
							overflow: hidden;
						}

						.blogArticleContentDate {
							margin: 7px 0px 0px 0px;
							color: $blue-100d;
						}

						a {
							display: inline-block;

							&:hover {
								color: $white;
							}
						}
					}
				}
			}

			.jspVerticalBar {
				position: absolute;
				top: 0px;
				right: 5px;
				width: 2px;
				height: 100%;
				background: $grey-200d;

				.jspTrack {
					position: relative;
					background: $grey-200d;
				}

				.jspDrag {
					position: relative;
					top: 0;
					left: 0;
					background-color: $yellow-100d;
					cursor: pointer;
				}

				.jspCap {
					display: block;
					height: 10px;
					background: $grey-400;
				}
			}
		}
	}
}
