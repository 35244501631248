﻿.LastMinutePage {
	font-size: $font-12;
	color: $white;

	.Title {
		@include dvu-titlebar();
		text-align: center;
	}

	.itemSport {
		padding-bottom: 25px;

		.sport {
			padding: 10px 0 3px 7px;
		}

		.header {
			height: 20px;
			display: block;
			background-color: rgba($white, 0.05);
		}

		.item {
			.dataHeader, .statsHeader, .eventHeader {
				display: none;
				position: absolute;
				top: -16px;
				font-size: $font-11;
				color: $grey-100d;
			}

			&:first-child {

				.dataHeader {
					display: inline-block;
					padding-left: 16px;
				}

				.statsHeader {
					display: inline-block;
					padding-left: 75px;

					@media screen and (max-width:1490px) {
						padding-left: 60px;
					}
				}

				.eventHeader {
					display: inline-block;
					padding-left: 127px;

					@media screen and (max-width:1490px) {
						padding-left: 102px;
					}
				}
			}

			.dateTime {
				padding-right: 16px !important;

				@media screen and (max-width:1490px) {
					padding-right: 0px !important;
				}
			}

			.eventName {
				width: 420px;
				padding: 0 !important;

				@media screen and (max-width:1870px) {
					width: 330px
				}

				@media (min-width:1691px) and (max-width:1745px) {
					width: 277px;
				}

				@media (min-width:1650px) and (max-width:1689px) {
					width: 375px;
				}

				@media (min-width:1491px) and (max-width:1649px) {
					width: 278px;
				}

				@media screen and (max-width:1490px) {
					width: 180px;
				}
			}

			.stats {
				width: 58px;
				padding-left: 0px !important;
				padding-right: 16px !important;

				@media screen and (max-width:1490px) {
					width: 48px;
					padding-right: 6px !important;
				}
			}

			.subevent {
				height: 16px;
			}

			.TQ {
				display: none;
				font-size: $font-12;
				width: 100%;
			}
		}

		.items .item:first-child .TQ {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.odds {
			float: right;

			.odd {
				background-color: rgba($grey-200d, 0.3);

				&:last-child {
					margin-right: 0px;
				}

				&.pos2,
				&.pos51 {
					background-color: transparent;
				}

				.TQ {
					position: absolute;
					top: -22px;
					left: 0;
					height: 14px;
					line-height: 14px;
					color: $grey-100d;
				}
			}
		}
	}
}
