﻿.pnl-autoesclusioni-popup {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	z-index: 100001;
	font-weight: normal;
}

.bg-autoesclusioni-popup {
	position: fixed;
	display: block;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.5;
	z-index: 100000;
}

.autoesclusioniPopUp {
	position: absolute;
	top: 25%;
	left: calc(50% - 200px);
	width: 400px;
	min-width: 350px;
	background-color: $white;
	color: $grey-300d;
	z-index: 100002;
	overflow: auto;
	text-align: center;
	border-radius: 6px;
	padding: 16px;

	.hide{
		display:none !important;
	}
}

.autoesclusioniPopUp_title {
	@include dvu-font-medium($font-16);
	text-align: center;
	margin: 0 auto;
}

.autoesclusioniPopUp_body {
	@include dvu-font-regular($font-12);
	line-height: normal;
	margin: 12px auto;
}

.autoesclusioniPopUp_body-normal {
	font-weight: normal;
}

.autoesclusioniPopUp_body-nomargintop {
	margin-top: 0;
}

.autoesclusioniPopUp_buttons {
	text-align: center;
	padding: 5px 0 5px 0;

	.autoesclusioniPopUp_button-prev {
		@include dvu-button-small-m();
	}
}

.autoesclusioniPopUp_radioHolder {
	display: block;
	width: 80%;
	margin: 0 auto;

	input {
		@include dvu-radio(false);
	}
}

.autoesclusioniPopUp_radioHolder span {
	display: table-cell;
	vertical-align: middle;
	height: 35px;
	padding-right: 15px;
}

.autoesclusioniPopUp_radioHolder label,
.autoesclusioniPopUp_radioHolder input {
	cursor: pointer;
}
