﻿.CassaStatsTitle,
.LastMessTitle,
.LastTransTitle {
	@include dvu-titlebar();

	a {
		display: inline-block;
		width: 100%;

		&:after {
			content: '';
			@include dvu-button-play();
			float:right;
			margin-top:9px;
		}
	}
}

.LastTransDivCnt,
.LastMessDivCnt,
.CassaStats table {
	width: 100%;
	border: none;
	border-radius: 6px;
	background-image: none;
	background-color: $grey-400;
	padding: 0;
	border-collapse:collapse;

	tr {
		height: 32px;

		td {
			padding: 0 12px;

			&:last-child {
				text-align: right;
			}
		}

		a:hover {
			color: $yellow-200d;
		}

		&.CassaStatsTotals{
			height:128px;
			vertical-align:top;
			font-weight:bold;
			border-top:1px solid $grey-150d;

			td{
				padding-top:9px;
			}
		}
	}

	.LastTransItem,
	.LastMessItem {
		background-color: rgba($white, 0.05);
	}
}

.LastMessDivCnt,
.CassaStats table {
	height: 160px;
}

.LastMess{
	margin-left:3px;
}

.CassaStats{
	margin-right:3px;
}