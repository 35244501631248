﻿#popUpModalERB {
	display: none;
	position: fixed;
	_position: absolute; /* hack for internet explorer 6*/
	height: 450px;
	width: 400px;
	z-index: 12000;
	font-size: 12px;
	text-align: center;

	.RiquadroPopRiserva {
		padding: 12px 30px 16px 30px;
	}

	.pnlErb {
		min-height: 150px;
		@include dvu-font-regular($font-14);
		line-height: 1.29;
		letter-spacing: 0.28px;
		text-align: center;

		&.warningIcon {
			.ErbMainMsgTitle {
				&:before {
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/warning.svg');
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/warning.svg');
					background-color: $red-200d;
				}
			}
		}
	}

	.ErbMainMsgTitle {
		@include dvu-font-medium($font-16);
		position: relative;
		padding-top: 72px;
		line-height: 1;

		&:before {
			content: '';
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/info.svg');
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/info.svg');
			background-color: $green-200d;
			width: 65px;
			height: 65px;
			top: 0;
			left: calc(50% - 33px);
			position: absolute;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-color: $transparent;
				background-image: url('../../images/IEIcons/info_green.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 58px;
				mask-image: none;
			}
		}
	}

	#ErbMainMsg {
		padding: 10px 0px 12px 0px;
	}

	.ERBPopUpContent {
		font-size: $font-12;
		line-height: 16px;

		.errorcoupon {
			padding: 10px 60px;
		}

		#tblErbParzButtons {
			padding-top: 10px;
			text-align: left;
			border-collapse: collapse;

			table {
				border-collapse: collapse;
			}

			.dvuTblSection {
				border-bottom: 1px solid $grey-100d;
			}

			tr:not(:first-child) {
				.SectionTitle {
					padding-top: 12px;
				}
			}

			.btnMediumRiserva {
				height: 40px;
			}

			.dgStyle {
				.dgSubHdrStyle {
					font-size: $font-10;
					height: 18px;

					th:not(:first-child) {
						text-align: center;
					}
				}
			}

			.Quota_New {
				color: $yellow-400d;
			}

			.dvuPartQuotaModifica {
				max-height: 150px !important;

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-track {
					background: $grey-100d;
				}

				&::-webkit-scrollbar-thumb {
					height: 46px;
					border-radius: 8px;
					background-color: $yellow-100d;
				}
			}
		}

		[id$="_btnErbDone"],
		[id$="_btnErbPrint"] {
			margin-left: 86%;
		}

		input[id$='btnErbRiservaRifiuta'] {
			@include dvu-button-red-small-m();
		}

		.buttonAccept, .buttonCancel {
			margin-top: 8px !important;
		}
	}
}
