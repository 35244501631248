﻿.divQta {
	padding: 0 0 12px 0;
	margin: 12px 0 0 0;
	@include dvu-font-medium($font-13);
	color: $white;

	.labelGQ {
		@include dvu-font-regular($font-12);
		display: block;
		line-height: 14px;
		height: 20px;
		padding-left: 12px;
	}

	.divQt {
		width: 100%;

		#lblMsgNoRecord {
			display: block;
			text-align: center;
			padding-bottom: 25px;
			color: $grey-150d;
		}

		.dgHeader {
			vertical-align: top;
			color: $grey-100;

			&:hover {
				cursor: default;
			}

			.cqTbl, .cqDateTbl {
				background-color: $grey-300d;
				line-height: 20px;
				font-weight: normal;
				color: rgba($white, 0.7);
				position: relative;
			}

			td.cqTbl {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					width: 1px;
					height: 14px;
					top: 3px;
					background-color: $grey-200d;
				}
			}

			div.cqTbl:empty {
				display: none;
			}

			div.cqTbl.cqTblHeader:empty,
			div.altreTbl.altreTblHeader:empty {
				display: block;
				min-height: 20px;;
			}

			div.altreTbl:empty {
				display: none;
			}

			.cqDateTbl {
				color: $blue-100d;
				padding-left: 12px;
			}

			.codTbl, .dataTbl, .statsTbl {
				height: 32px;
				text-align: center;
				min-width: 45px;
				font-size: $font-12;
				vertical-align: middle;

				@media screen and (max-width:1550px) {
					min-width: 32px;
					font-size: $font-10;
				}

				@media screen and (max-width:1800px) {
					width: 45px;
					font-size: $font-11;
				}
			}

			.statsTbl.hidden {
				visibility: hidden;
				max-width: 45px;
			}

			.incTbl {
				font-size: $font-12;
			}

			.OddsQuotaHeaderStyle, .OddsQuotaHeaderStyleSingle {
				width: 100%;

				TD {
					border-top: none;
					border-bottom: none;
					text-align: center;
				}

				tr:last-child {
					td {
						height: 32px;
						color: $grey-100;
						text-align: center;
						width: 55px;
						vertical-align: middle;
						padding: 2px;
						font-size: 12px;
						position: relative;

						@media screen and (max-width:1800px) {
							width: 45px;
							font-size: $font-11;
						}

						@media screen and (max-width:1550px) {
							width: 40px;
							font-size: $font-10;
						}
					}
				}
			}

			.OddsQuotaHeaderStyleSingle {
				.qtTbl {
					line-height: 32px;
				}

				td {
					padding: 0 !important;
				}
			}
		}

		.dgHidHdr {
			border: 0px;
			visibility: hidden;
			display: none;
			height: 0;
			font-size: 0;
			padding: 0;
			margin: 0;
			line-height: 0;

			.cqTbl,
			.altreTbl,
			.cqDateTbl,
			.codTbl,
			.incTbl,
			.dataTbl .HNDTbl .dgHidHdr .qtTbl_1,
			.qtTbl_0 {
				border: 0px;
				visibility: hidden;
				height: 0;
				font-size: 0;
				padding: 0;
				margin: 0;
				line-height: 0;
			}

			IMG {
				height: 0px;
			}
		}

		.dgSEItem {

			&.dgAItem {
				background-color: rgba($white, 0.05);
			}

			&.dgItem {
				background-color: transparent;
			}

			.seExpander, .seCollapser {
				width: 28px;
				height: 28px;
				cursor: pointer;
				display: inline-block;
				border-radius: 4px;
				background-color: $grey-200d;
				left: 12px;
				line-height: 33px;
				text-align: center;
				float: right;

				&:after {
					content: '';
					width: 12px;
					height: 12px;
					display: inline-block;
					background-color: $grey-100d;
					mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						mask-image: none;
						background-size: 10px;
						background: transparent url('../../images/IEIcons/arrow_up.svg') no-repeat center center;
					}
				}

				&:hover {
					background-color: $grey-150d;
				}
			}

			.seCollapser {
				&:after {
					mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg")!important;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						mask-image: none;
						background-size: 10px;
						background: transparent url('../../images/IEIcons/arrow.svg') no-repeat center center;
					}
				}
			}
		}

		.dgItem, .dgAItem:nth-of-type(even) {
			background-color: rgba($white, 0.05);
		}

		.dgItem, .dgAItem, .dgSEItem {
			height: 32px;

			.codInc, .dtInc, .statsInc {
				min-width: 45px;
				vertical-align: top;
				padding-top: 11px;

				@media screen and (max-width:1550px) {
					min-width: 32px;
				}
			}

			.codInc {
				text-align: center;
				color: $grey-100d;
				font-size: $font-11;
			}

			.dtInc {
				text-align: center;
				color: $blue-100d;
				font-size: $font-11;

				&:hover {
					cursor: default;
				}
			}

			.nmInc {
				width: 100%;
				padding-bottom: 0px;
				font-size: $font-14;
				vertical-align: top;
				padding-top: 11px;

				@media screen and (max-width:1550px) {
					font-size: $font-11;
				}

				a {
					text-decoration: none;
				}
			}

			&.participant {
				border-left: 1px solid $yellow-400d;
				border-right: 1px solid $yellow-400d;

				&.partFirst {
					border-top: 1px solid $yellow-400d;
				}

				&.partLast {
					border-bottom: 1px solid $yellow-400d;
				}
			}

			.statsInc {
				padding-top: 8px;

				.statsBtn {
					width: 16px;
					height: 16px;
					margin: auto;
					cursor: pointer;
					background-color: $grey-100d;
					mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/solid/stats.svg');
					-webkit-mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/solid/stats.svg');
					mask-size: 16px;
					-webkit-mask-size: 16px;
					mask-position: center;
					-webkit-mask-position: center;
					mask-repeat: no-repeat;
					-webkit-mask-repeat: no-repeat;

					&:hover {
						background-color: $yellow-200d;
					}
				}
			}
		}

		.OddsDetailsQuote {

			.dgQuote {
				width: 100%;
				height: 100%;

				&.dgQuoteTQ {
					width: 700px;

					@media screen and (max-width: 1800px) {
						width: 650px;
					}

					@media screen and (max-width: 1550px) {
						width: 550px;
					}
				}
			}

			.OddsQuotaItemStyle {

				TD {
					border-top: none;
					border-bottom: none;
					text-align: center;
					background-color: rgba($grey-200d, 0.18);
					padding: 2px;

					&[data-altcolumn="True"] {
						background-color: transparent;
					}

					&:not(.QuotaSel) {
						&:hover {
							.oddsQ {
								color: $yellow-400d;
							}
						}
					}

					&.QuotaSel {

						a, .oddsQ {
							background-color: $yellow-400d;
							color: $black;
						}

						&:hover {
							a, .oddsQ {
								background-color: $yellow-300d;
								color: $black;
							}
						}
					}
				}

				.oddsQ {
					width: 51px;
					line-height: 32px;

					@media screen and (max-width:1800px) {
						width: 45px;
					}

					@media screen and (max-width:1550px) {
						width: 40px;
					}

					a {
						font-size: $font-13;
						display: block;
						text-align: center;
					}
				}

				.OddGB_0_0 .oddsQ, .OddGB_0_1 .oddsQ {
					line-height: 32px;

					a {
						border-bottom: 2px solid $unica;
						display: inline-block;
						width: 38px;
						height: 29px;
					}
				}

				.OddGB_3_0 .oddsQ, .OddGB_3_1 .oddsQ {
					line-height: 32px;

					a {
						border-bottom: 2px solid $tripla;
						display: inline-block;
						width: 38px;
						height: 29px;
					}
				}

				.OddGb_Empty_0,
				.OddGb_Empty_1 {
					border: none;
					text-align: right;
					empty-cells: show;

					&:hover {
						.oddsQ {
							border: none !important;
						}
					}
				}

				.HNDInc {
					color: $yellow-400d;

					DIV {
						width: 51px;
						font-size: $font-12 !important;
					}
				}
			}

			.OddsQuotaItemStyleTQ {
				display: inline;

				.oddsTQ {
					letter-spacing: -0.5px;
					font-size: $font-10;
					line-height: 12px;
					padding: 3px 0;
					text-align: center;
					vertical-align: bottom;
					color: $grey-100d;
					position: absolute;
					width: calc(100% - 2px);
					white-space: nowrap;
				}

				.oddsQ {
					width: 100%;
					height: 100%;
					border: 1px solid transparent;
				}

				TD {
					border-top: none;
					border-bottom: none;
					text-align: center;
					//border: 1px solid rgba($grey-400d, 0.5);
					border: 1px solid transparent;
					background-color: rgba($grey-200d, 0.2);
					height: 40px;
					float: left;
					width: calc((100% - 26px) / 13);
					position: relative;
					margin: 1px;
					padding: 1px;

					@media screen and (max-width: 1800px) {
						width: calc((100% - 24px) / 12);
					}

					@media screen and (max-width: 1550px) {
						width: calc((100% - 22px) / 11);
					}

					&.OddGb_Empty_0,
					&.OddGb_Empty_1 {
						display: none;
					}

					A {
						font-size: $font-13;
						text-decoration: none;
						font-weight: normal;
						line-height: 13px;
						height: 100%;
						display: block;
						padding-top: 17px;
						border: 1px solid transparent;
						position: relative;
						z-index: 1;
					}

					&.OddGB_0_0,
					&.OddGB_0_1 {
						border-bottom: none;
						line-height: 30px;
						padding: 1px;

						a {
							border-bottom: 2px solid $unica;
							border-right: none;
							border-left: none;
						}
					}

					&.OddGB_3_0,
					&.OddGB_3_1 {
						border-bottom: none;
						line-height: 30px;
						padding: 1px;

						a {
							border-bottom: 2px solid $tripla;
						}
					}

					&:not(.QuotaSel) {
						&:hover {
							color: $yellow-400d;
						}
					}

					&.QuotaSel {
						color: $grey-300d;

						.oddsTQ {
							color: $grey-300d;
						}

						.oddsQ {
							background-color: $yellow-400d;

							a {
								background-color: transparent !important;
							}
						}

						&:hover {
							.oddsQ {
								background-color: $yellow-300d;
								color: $black;
							}

							a {
								background-color: transparent !important;
							}
						}
					}
				}
			}
		}

		.OddsDetailsClassiQuota {

			&:empty {
				display: none;
			}

			&.All {
				font-size: $font-11;
				font-weight: normal;
				padding: 0;

				@media screen and (max-width:1550px) {
					font-size: 8px;
				}

				A {
					display: block;
					border-radius: 4px;
					width: 34px;
					height: 22px;
					line-height: 22px;
					text-align: right;
					padding-right: 5px;
					background-color: $grey-200d;

					@media screen and (max-width:1550px) {
						width: 21px;
						height: 18px;
						line-height: 18px;
					}

					&:hover {
						background-color: $grey-150d;
					}

					&:before {
						content: '+';
						width: 10px;
						height: 10px;
					}

					&.sel {
						background-color: $yellow-400d;
						color: $black;

						&:before {
							content: '-';
						}

						&:hover {
							background-color: $yellow-300d;
						}
					}
				}
			}

			&.Stats {
				height: 16px;
				width: 16px;
				position: relative;

				a {
					height: 16px;
					width: 16px;
					display: block;
					border: 1px solid #000000;
				}

				.statsSPC {
					width: 16px;
					height: 0;
				}
			}

			.divCmb {
				width: 12px;
				height: 12px;
				overflow: hidden;
				cursor: pointer;
				mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");
				background-color: $grey-150d;
				display: block;
				margin: 0 5px 0 10px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					mask-image: none;
					background-size: 10px;
					background: transparent url('../../images/IEIcons/arrow.svg') no-repeat center center;
				}

				@media screen and (max-width:1550px) {
					width: 11px;
					height: 11px;
					margin: 0 3px 0 5px;
				}

				&:hover {
					background-color: $grey-100d;
				}

				SPAN {
					position: absolute;
					right: 15px;
					top: 0;
					height: 17px;
					line-height: 16px;
				}

				&.Sel {
					background-color: $yellow-400d;

					&:hover {
						background-color: $yellow-300d;
					}
				}

				&.click {
					background-color: $yellow-300d;
				}
			}
		}

		.DateOdds {
			background-color: $grey-300d;
			line-height: 20px;
			font-weight: normal;
			padding-left: 12px;
			color: $blue-100d;
		}

		.oddsToolTip {
			@include dvu-tooltip-default-simple($font-14);
			z-index: 200;
			left: 12px;
			top: 15px;
			position: absolute;
			display: block;
			float: left;
			border-top-left-radius: 0;
			text-align: center;

			> SPAN {
				display: block;
			}

			> DIV {
				display: inline-block;
				width: 50px;
				text-align: center;

				SPAN {
					display: block;
					text-align: center;
					color: #EBA917;
					line-height: 12px;
					margin-top: 2px;
				}
			}

			.graphCnt {
				border: 1px solid #888;
				border-radius: 3px;
				margin: 2px 3px;
			}

			.graph {
				height: 10px;
				background-color: #96cf34; /* Old browsers */
			}
		}
	}
}
