﻿/*This file contains common css properties for central widgets CasinoGames, PromoSection...*/
#NESportCnt {
	margin: 8px 0px 0px 0px;
}

#centralCasinoGamesWidget,
.MainHomeNews{
	width: 100%;
	margin: 8px 0px 0px 0px;
	padding: 0px;
	border: none;
	border-radius: 6px;
	background-image: none;
}