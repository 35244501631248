﻿.bonus_terms_popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;
	background: rgba($black, 0.6);
	display: none;

	.bonus_popup_wrapper {
		bottom: auto;
		width: 850px;
		max-height: 455px;
		background: $white !important;
		border-radius: 6px;
		overflow: hidden;

		.bonus_popup_close {
			cursor: pointer;
			position: absolute;
			top: 3px;
			right: 5px;
			width: 15px;
			height: 15px;
			background-color: $grey-300d;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			mask-position: center;
			mask-repeat: no-repeat;
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			-webkit-mask-position: center;
			-webkit-mask-repeat: no-repeat;
		}

		H4 {
			@include dvu-font-medium($font-16);
			box-sizing: border-box;
			width: 100%;
			padding: 20px 0px 10px 0px;
			margin: auto;
			background-color: $white !important;
			color: $black !important;
			line-height: 1.1;
			text-align: center;
			text-transform: capitalize;
		}

		.bonus_popup_content {
			margin-bottom: 30px;
			height: 348px;

			.bonus_content_wrap {
				font-family: $roboto !important;
				padding: 0 30px;
				font-size: 12px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.25;
				letter-spacing: 0.2px;
				text-align: left;
				color: $black !important;

				p {
					margin-top: 0;
					margin-bottom: 10px;
				}

				a {
					color: $yellow-300d;
					text-decoration: none;

					&:hover {
						color: $yellow-200d;
					}
				}

				ul, ol {
					list-style-type: disc;
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-start: 0px;
					padding-inline-start: 40px;
				}

				ol {
					list-style-type: decimal;
				}
			}

			.jspVerticalBar {
				right: 30px;
			}
		}

		.bonus_popup_actions {
			position: relative;
			text-align: center;

			a {
				@include dvu-font-medium($font-16);
				display: inline-block;
				margin: 0 2%;
				width: 42%;
				border-radius: 4px;
				padding: 10px 0;
				cursor: pointer;
				line-height: 1.19;
				letter-spacing: 0.3px;
				text-align: center;

				&.cancel {
					background-image: linear-gradient(to bottom, #ffffff, #cccecf);
					color: $black;
				}

				&.confirm {
					background-image: linear-gradient(to bottom, #58b73f, #3b9b22);
					color: $white;
				}
			}
		}
	}
}
