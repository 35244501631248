@import "//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext";
#statistics{
  background-color: $grey-300d;
  border-radius: 6px;
  width: 298px;
  height: auto;
  z-index: 999;
  padding: 6px;

  #statisticsheader{
    height: 22px;
    padding: 2px 6px 6px 6px;
    border-radius: 6px;
    cursor: grab;
  }

  #statisticstitle{
    size: 12px;
    line-height: 14px;
    font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    color: $white;
  }

  #statisticsclose{
    margin-left: auto;
    mask-image: url('../../Images/close.svg');
    background-color: $grey-100d;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 6px;
    top: 7px;
    cursor: pointer;
  }
}


.sr-bb {
    font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-align: left;
    background: #22262B;

    .sr-goalscorers__more {
        fill: white;
    }

    .sr-bb {
        background: none;
    }

    .srt-base-1 {
        background-color: transparent;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-win {
        background-color: transparent;
        color: #7AC57B;
        border-color: #7AC57B;
    }

    .srt-base-1-draw {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.4);
        border-color: rgba(255, 255, 255, 0.4);
    }

    .srt-base-1-lose {
        background-color: transparent;
        color: #F3E06C;
        border-color: #F3E06C;
    }

    .srt-base-1-is-active {
        background-color: rgba(250, 182, 21, 0.12);
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-is-active-2 {
        background-color: #383c40;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-is-hoverable {
        &:hover {
            background-color: rgba(250, 182, 21, 0.12);
            color: #FFFFFF;
            border-color: rgba(255, 255, 255, 0.12);
        }
    }

    .srt-base-1-primary {
        background-color: transparent;
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-home-1 {
        background-color: transparent;
        color: #7AC57B;
        border-color: #7AC57B;
    }

    .srt-base-1-away-1 {
        background-color: transparent;
        color: #F3E06C;
        border-color: #F3E06C;
    }

    .srt-base-1-home-2 {
        background-color: transparent;
        color: #7AC57B;
        border-color: #fcd577;
    }

    .srt-base-1-away-2 {
        background-color: transparent;
        color: #F3E06C;
        border-color: #fcd577;
    }

    .srt-base-1-home-3 {
        background-color: transparent;
        color: #7AC57B;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-away-3 {
        background-color: transparent;
        color: #F3E06C;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-home-4 {
        background-color: transparent;
        color: #609563;
        border-color: #609563;
    }

    .srt-base-1-away-4 {
        background-color: transparent;
        color: #b4a859;
        border-color: #b4a859;
    }

    .srt-base-1-home-5 {
        background-color: transparent;
        color: #a9d9a9;
        border-color: #a9d9a9;
    }

    .srt-base-1-away-5 {
        background-color: transparent;
        color: #f7eb9f;
        border-color: #f7eb9f;
    }

    .srt-base-1-background {
        background-color: #22262b;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-2 {
        background-color: #22262b;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-2-is-active {
        background-color: #3c3728;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-2-is-hoverable {
        &:hover {
            background-color: #3c3728;
            color: #FFFFFF;
            border-color: rgba(255, 255, 255, 0.12);
        }
    }

    .srt-base-3 {
        background-color: #3c3728;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-3-is-active {
        background-color: #473e27;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-3-is-hoverable {
        &:hover {
            background-color: #473e27;
            color: #FFFFFF;
            border-color: rgba(255, 255, 255, 0.12);
        }
    }

    .srt-base-3-background {
        background-color: #22262b;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-4 {
        background-color: #2d2d2a;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-5-is-active {
        background-color: #5a4b25;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-5-is-hoverable {
        &:hover {
            background-color: #5a4b25;
            color: #FFFFFF;
            border-color: rgba(255, 255, 255, 0.12);
        }
    }

    .srt-base-6 {
        background-color: transparent;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.3);
    }

    .srt-primary-1 {
        background-color: #FAB615;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-1-is-active {
        background-color: #e1a413;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-1-is-hoverable {
        &:hover {
            background-color: #e1a413;
            color: #000000;
            border-color: rgba(0, 0, 0, 0.16);

            .srt-text-secondary {
                opacity: 0.7;
                -webkit-font-smoothing: antialiased;
            }

            .srt-text-disabled {
                opacity: 0.35;
            }

            .srt-text-tertiary {
                opacity: 0.54;
            }
        }

        &:hover.srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        &:hover.srt-text-disabled {
            opacity: 0.35;
        }

        &:hover.srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-1-is-disabled {
        background-color: #c89211;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-2 {
        background-color: #fccc5b;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-3 {
        background-color: #e1a413;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-4 {
        background-color: #a47c1e;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-5 {
        background-color: #a47c1e;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-5-is-hoverable {
        &:hover {
            background-color: #FAB615;
            color: #000000;
            border-color: rgba(0, 0, 0, 0.16);

            .srt-text-secondary {
                opacity: 0.7;
                -webkit-font-smoothing: antialiased;
            }

            .srt-text-disabled {
                opacity: 0.35;
            }

            .srt-text-tertiary {
                opacity: 0.54;
            }
        }

        &:hover.srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        &:hover.srt-text-disabled {
            opacity: 0.35;
        }

        &:hover.srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-6 {
        background-color: #fcd577;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-7 {
        background-color: #FAB615;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-8 {
        background-color: #FAB615;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-8-is-active-1 {
        background-color: #e1a413;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-8-is-active-2 {
        background-color: #e1a413;
        color: #000000;
        border-color: #22262B;

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-9 {
        background-color: #635124;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-10 {
        background-color: #cf9919;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-11 {
        background-color: #e1a413;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-12 {
        background-color: #FAB615;
        color: #000000;
        border-color: #000000;

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-primary-13 {
        background-color: #2d2d2a;
        color: #000000;
        border-color: rgba(250, 182, 21, 0.3);

        .srt-text-secondary {
            opacity: 0.7;
            -webkit-font-smoothing: antialiased;
        }

        .srt-text-disabled {
            opacity: 0.35;
        }

        .srt-text-tertiary {
            opacity: 0.54;
        }
    }

    .srt-base-1-primary-1 {
        background-color: transparent;
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-primary-2 {
        background-color: transparent;
        color: #fccc5b;
        border-color: #fccc5b;
    }

    .srt-base-1-primary-3 {
        background-color: transparent;
        color: #e1a413;
        border-color: #e1a413;
    }

    .srt-base-1-primary-4 {
        background-color: transparent;
        color: #a47c1e;
        border-color: #a47c1e;
    }

    .srt-base-1-primary-5 {
        background-color: transparent;
        color: #a47c1e;
        border-color: #a47c1e;
    }

    .srt-base-1-primary-6 {
        background-color: transparent;
        color: #fcd577;
        border-color: #fcd577;
    }

    .srt-base-1-primary-7 {
        background-color: transparent;
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-primary-8 {
        background-color: transparent;
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-primary-9 {
        background-color: transparent;
        color: #635124;
        border-color: #635124;
    }

    .srt-base-1-primary-10 {
        background-color: transparent;
        color: #cf9919;
        border-color: #cf9919;
    }

    .srt-base-1-primary-11 {
        background-color: transparent;
        color: #e1a413;
        border-color: #e1a413;
    }

    .srt-base-1-primary-13 {
        background-color: #22262B;
        color: #2d2d2a;
        border-color: rgba(250, 182, 21, 0.3);
    }

    .srt-base-1-neutral-1 {
        background-color: transparent;
        color: #3f4247;
        border-color: #3f4247;
    }

    .srt-base-1-neutral-2 {
        background-color: transparent;
        color: #e9e9ea;
        border-color: #e9e9ea;
    }

    .srt-base-1-neutral-3 {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.12);
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-neutral-4 {
        background-color: transparent;
        color: #b2b3b5;
        border-color: #b2b3b5;
    }

    .srt-base-1-neutral-5 {
        background-color: transparent;
        color: #c8c9ca;
        border-color: #c8c9ca;
    }

    .srt-base-1-neutral-6 {
        background-color: transparent;
        color: #575a5e;
        border-color: #575a5e;
    }

    .srt-base-1-neutral-7 {
        background-color: transparent;
        color: #b2b3b5;
        border-color: #b2b3b5;
    }

    .srt-base-1-neutral-8 {
        background-color: transparent;
        color: #3f4247;
        border-color: #3f4247;
    }

    .srt-base-1-neutral-9 {
        background-color: transparent;
        color: #2f3338;
        border-color: #2f3338;
    }

    .srt-base-1-neutral-10 {
        background-color: transparent;
        color: #3d4044;
        border-color: #3d4044;
    }

    .srt-base-1-neutral-11 {
        background-color: transparent;
        color: #7a7d80;
        border-color: #7a7d80;
    }

    .srt-base-1-neutral-12 {
        background-color: transparent;
        color: #bdbebf;
        border-color: #bdbebf;
    }

    .srt-base-1-neutral-13 {
        background-color: transparent;
        color: #383c40;
        border-color: #383c40;
    }

    .srt-base-1-is-active-primary {
        background-color: rgba(250, 182, 21, 0.12);
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-is-active-home-1 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #7AC57B;
        border-color: #7AC57B;
    }

    .srt-base-1-is-active-away-1 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #F3E06C;
        border-color: #F3E06C;
    }

    .srt-base-1-is-active-home-2 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #7AC57B;
        border-color: #fcd577;
    }

    .srt-base-1-is-active-away-2 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #F3E06C;
        border-color: #fcd577;
    }

    .srt-base-1-is-active-home-3 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #7AC57B;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-is-active-away-3 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #F3E06C;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-is-active-home-4 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #609563;
        border-color: #609563;
    }

    .srt-base-1-is-active-away-4 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #b4a859;
        border-color: #b4a859;
    }

    .srt-base-1-is-active-home-5 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #a9d9a9;
        border-color: #a9d9a9;
    }

    .srt-base-1-is-active-away-5 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #f7eb9f;
        border-color: #f7eb9f;
    }

    .srt-base-1-is-active-primary-1 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-is-active-primary-2 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #fccc5b;
        border-color: #fccc5b;
    }

    .srt-base-1-is-active-primary-3 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #e1a413;
        border-color: #e1a413;
    }

    .srt-base-1-is-active-primary-4 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #a47c1e;
        border-color: #a47c1e;
    }

    .srt-base-1-is-active-primary-5 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #a47c1e;
        border-color: #a47c1e;
    }

    .srt-base-1-is-active-primary-6 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #fcd577;
        border-color: #fcd577;
    }

    .srt-base-1-is-active-primary-7 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-is-active-primary-8 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #FAB615;
        border-color: #FAB615;
    }

    .srt-base-1-is-active-primary-9 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #635124;
        border-color: #635124;
    }

    .srt-base-1-is-active-primary-10 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #cf9919;
        border-color: #cf9919;
    }

    .srt-base-1-is-active-primary-11 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #e1a413;
        border-color: #e1a413;
    }

    .srt-base-1-is-active-neutral-1 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #3f4247;
        border-color: #3f4247;
    }

    .srt-base-1-is-active-neutral-2 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #e9e9ea;
        border-color: #e9e9ea;
    }

    .srt-base-1-is-active-neutral-3 {
        background-color: rgba(250, 182, 21, 0.12);
        color: rgba(255, 255, 255, 0.12);
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-base-1-is-active-neutral-4 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #b2b3b5;
        border-color: #b2b3b5;
    }

    .srt-base-1-is-active-neutral-5 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #c8c9ca;
        border-color: #c8c9ca;
    }

    .srt-base-1-is-active-neutral-6 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #575a5e;
        border-color: #575a5e;
    }

    .srt-base-1-is-active-neutral-7 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #b2b3b5;
        border-color: #b2b3b5;
    }

    .srt-base-1-is-active-neutral-8 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #3f4247;
        border-color: #3f4247;
    }

    .srt-base-1-is-active-neutral-9 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #2f3338;
        border-color: #2f3338;
    }

    .srt-base-1-is-active-neutral-10 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #3d4044;
        border-color: #3d4044;
    }

    .srt-base-1-is-active-neutral-11 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #7a7d80;
        border-color: #7a7d80;
    }

    .srt-base-1-is-active-neutral-12 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #bdbebf;
        border-color: #bdbebf;
    }

    .srt-base-1-is-active-neutral-13 {
        background-color: rgba(250, 182, 21, 0.12);
        color: #383c40;
        border-color: #383c40;
    }

    .srt-home-1 {
        background-color: #7AC57B;
        color: #000000;
        border-color: #7AC57B;
    }

    .srt-away-1 {
        background-color: #F3E06C;
        color: #000000;
        border-color: #F3E06C;
    }

    .srt-home-2 {
        background-color: #7AC57B;
        color: #000000;
        border-color: #fcd577;
    }

    .srt-away-2 {
        background-color: #F3E06C;
        color: #000000;
        border-color: #fcd577;
    }

    .srt-home-3 {
        background-color: #7AC57B;
        color: #000000;
        border-color: #22262B;
    }

    .srt-away-3 {
        background-color: #F3E06C;
        color: #000000;
        border-color: #22262B;
    }

    .srt-home-4 {
        background-color: #609563;
        color: #000000;
        border-color: #609563;
    }

    .srt-away-4 {
        background-color: #b4a859;
        color: #000000;
        border-color: #b4a859;
    }

    .srt-home-5 {
        background-color: #a9d9a9;
        color: #000000;
        border-color: #a9d9a9;
    }

    .srt-away-5 {
        background-color: #f7eb9f;
        color: #000000;
        border-color: #f7eb9f;
    }

    .srt-neutral-1 {
        background-color: #3f4247;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-2 {
        background-color: #e9e9ea;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-neutral-3 {
        background-color: rgba(255, 255, 255, 0.12);
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-4 {
        background-color: #b2b3b5;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-neutral-5 {
        background-color: #c8c9ca;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-neutral-6 {
        background-color: #575a5e;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-7 {
        background-color: #b2b3b5;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-neutral-8 {
        background-color: #3f4247;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-9 {
        background-color: #2f3338;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-10 {
        background-color: #3d4044;
        color: rgba(255, 255, 255, 0.82);
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-11 {
        background-color: #7a7d80;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-neutral-12 {
        background-color: #bdbebf;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-neutral-13 {
        background-color: #383c40;
        color: #FFFFFF;
        border-color: rgba(255, 255, 255, 0.12);
    }

    .srt-win {
        background-color: #7AC57B;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-draw {
        background-color: rgba(255, 255, 255, 0.4);
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-lose {
        background-color: #F3E06C;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-text-secondary {
        opacity: 0.8;
        -webkit-font-smoothing: antialiased;
    }

    .srt-text-disabled {
        opacity: 0.6;
    }

    .srt-text-tertiary {
        opacity: 0.7;
    }

    .srt-primary-1.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-1-is-active.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-1-is-disabled.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-2.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-3.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-4.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-5.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-6.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-7.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-8.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-8-is-active-1.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-8-is-active-2.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-9.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-10.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-11.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-12.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-13.srt-text-secondary {
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
    }

    .srt-primary-1.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-1-is-active.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-1-is-disabled.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-2.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-3.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-4.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-5.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-6.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-7.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-8.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-8-is-active-1.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-8-is-active-2.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-9.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-10.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-11.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-12.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-13.srt-text-disabled {
        opacity: 0.35;
    }

    .srt-primary-1.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-1-is-active.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-1-is-disabled.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-2.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-3.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-4.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-5.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-6.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-7.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-8.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-8-is-active-1.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-8-is-active-2.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-9.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-10.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-11.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-12.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-primary-13.srt-text-tertiary {
        opacity: 0.54;
    }

    .srt-icon {
        opacity: 0.33;
    }

    .srt-icon-secondary {
        opacity: 0.7;
    }

    .srt-elevation-1 {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .srt-elevation-2 {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    }

    .srt-elevation-3 {
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
    }

    .srt-elevation-center-2 {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
    }

    .srt-inset-top-1 {
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-inset-bottom-1 {
        box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-inset-top-2 {
        box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-inset-bottom-2 {
        box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-inset-top-3 {
        box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-inset-bottom-3 {
        box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
    }

    .srt-fill-blue-card {
        fill: #2579ad;
    }

    .srt-stroke-blue-card {
        stroke: #2579ad;
    }

    .srt-fill-soccer-yellow-card {
        fill: #ffbf00;
    }

    .srt-stroke-soccer-yellow-card {
        stroke: #ffbf00;
    }

    .srt-fill-soccer-red-card {
        fill: #e43b3b;
    }

    .srt-stroke-soccer-red-card {
        stroke: #e43b3b;
    }

    .srt-stroke-soccer-substitution-in {
        stroke: #4fbe30;
        fill: transparent;
    }

    .srt-fill-soccer-substitution-in {
        fill: #4fbe30;
    }

    .srt-stroke-soccer-substitution-out {
        stroke: #e43b3b;
        fill: transparent;
    }

    .srt-fill-soccer-substitution-out {
        fill: #e43b3b;
    }

    .srt-stroke-soccer-own-goal {
        stroke: #e43b3b;
        fill: transparent;
    }

    .srt-fill-soccer-own-goal {
        fill: #e43b3b;
    }

    .srt-fill-soccer-relegation-1 {
        fill: #fdd835;
    }

    .srt-stroke-soccer-relegation-1 {
        stroke: #fdd835;
    }

    .srt-fill-soccer-relegation-2 {
        fill: #ffb848;
    }

    .srt-stroke-soccer-relegation-2 {
        stroke: #ffb848;
    }

    .srt-fill-soccer-relegation-3 {
        fill: #ef6c00;
    }

    .srt-stroke-soccer-relegation-3 {
        stroke: #ef6c00;
    }

    .srt-fill-soccer-relegation-4 {
        fill: #e93a34;
    }

    .srt-stroke-soccer-relegation-4 {
        stroke: #e93a34;
    }

    .srt-fill-soccer-relegation-5 {
        fill: #941d1d;
    }

    .srt-stroke-soccer-relegation-5 {
        stroke: #941d1d;
    }

    .srt-fill-soccer-promotion-1 {
        fill: #51d151;
    }

    .srt-stroke-soccer-promotion-1 {
        stroke: #51d151;
    }

    .srt-fill-soccer-promotion-2 {
        fill: #1b911b;
    }

    .srt-stroke-soccer-promotion-2 {
        stroke: #1b911b;
    }

    .srt-fill-soccer-promotion-3 {
        fill: #0e8094;
    }

    .srt-stroke-soccer-promotion-3 {
        stroke: #0e8094;
    }

    .srt-fill-soccer-promotion-4 {
        fill: #0a6cce;
    }

    .srt-stroke-soccer-promotion-4 {
        stroke: #0a6cce;
    }

    .srt-fill-soccer-promotion-5 {
        fill: #4a9fe4;
    }

    .srt-stroke-soccer-promotion-5 {
        stroke: #4a9fe4;
    }

    .srt-nfl-timeout-1 {
        background-color: #f5a623;
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-nfl-timeout-2 {
        background-color: rgba(245, 166, 35, 0.3);
        color: #000000;
        border-color: rgba(0, 0, 0, 0.16);
    }

    .srt-nfl-penalty {
        background-color: #f4a621;
        color: #000000;
        border-color: #f4a621;
    }

    .srt-nfl-negative-yards-1 {
        background-color: #ac182e;
        color: #000000;
        border-color: #ac182e;
    }

    .srt-nfl-negative-yards-2 {
        background-color: #ac182e;
        color: #000000;
        border-color: #ac182e;
    }

    .srt-nfl-first-line {
        background-color: #4b90de;
        color: #000000;
        border-color: #4b90de;
    }

    .srt-nfl-ten-line {
        background-color: #f5a623;
        color: #000000;
        border-color: #f5a623;
    }

    .srt-fill-nfl-penalty {
        fill: #f4a621;
    }

    .srt-stroke-nfl-penalty {
        stroke: #f4a621;
    }

    .srt-fill-nfl-negative-yards-1 {
        fill: #ac182e;
    }

    .srt-stroke-nfl-negative-yards-1 {
        stroke: #ac182e;
    }

    .srt-fill-nfl-negative-yards-2 {
        fill: #ac182e;
    }

    .srt-stroke-nfl-negative-yards-2 {
        stroke: #ac182e;
    }

    .srt-fill-nfl-first-line {
        fill: #4b90de;
    }

    .srt-stroke-nfl-first-line {
        stroke: #4b90de;
    }

    .srt-fill-nfl-ten-line {
        fill: #f5a623;
    }

    .srt-stroke-nfl-ten-line {
        stroke: #f5a623;
    }

    .srt-mlb-run-1 {
        background-color: #009900;
        color: #ffffff;
        border-color: #009900;
    }

    .srt-mlb-run-2 {
        background-color: #ffffff;
        color: #009900;
        border-color: #009900;
    }

    .srt-mlb-hit-1 {
        background-color: #f7ab11;
        color: #FFFFFF;
        border-color: #f7ab11;
    }

    .srt-mlb-hit-2 {
        background-color: #ffffff;
        color: #f7ab11;
        border-color: #f7ab11;
    }

    .srt-mlb-hit-3 {
        background-color: #00a4ff;
        color: #ffffff;
        border-color: #00a4ff;
    }

    .srt-mlb-error-1 {
        background-color: #d0021b;
        color: #ffffff;
        border-color: #d0021b;
    }

    .srt-mlb-error-2 {
        background-color: #ffffff;
        color: #d0021b;
        border-color: #d0021b;
    }

    .srt-mlb-neutral-1 {
        background-color: #212121;
        color: #ffffff;
        border-color: #ffffff;
    }

    .srt-fill-mlb-base {
        fill: #00a4ff;
    }

    .srt-strole-mlb-base {
        fill: #00a4ff;
    }

    .srt-fill-change-increase {
        fill: #7AC57B;
    }

    .srt-stroke-change-increase {
        stroke: #7AC57B;
    }

    .srt-fill-change-decrease {
        fill: #F3E06C;
    }

    .srt-stroke-change-decrease {
        stroke: #F3E06C;
    }

    .srt-fill-text {
        fill: #FFFFFF;
    }

    .srt-fill-text-invert {
        fill: #000000;
    }

    .srt-fill-text-secondary {
        fill-opacity: 0.8;
    }

    .srt-fill-text-disabled {
        fill-opacity: 0.6;
    }

    .srt-fill-base-1 {
        fill: transparent;
    }

    .srt-stroke-base-1 {
        stroke: transparent;
    }

    .srt-fill-base-1-active {
        fill: rgba(250, 182, 21, 0.12);
    }

    .srt-stroke-base-1-active {
        stroke: rgba(250, 182, 21, 0.12);
    }

    .srt-fill-base-1-active-2 {
        fill: #383c40;
    }

    .srt-stroke-base-1-active-2 {
        stroke: #383c40;
    }

    .srt-fill-base-1-primary {
        fill: transparent;
    }

    .srt-stroke-base-1-primary {
        stroke: transparent;
    }

    .srt-fill-base-1-home {
        fill: transparent;
    }

    .srt-stroke-base-1-home {
        stroke: transparent;
    }

    .srt-fill-base-1-away {
        fill: transparent;
    }

    .srt-stroke-base-1-away {
        stroke: transparent;
    }

    .srt-fill-base-2 {
        fill: currentColor;
    }

    .srt-stroke-base-2 {
        stroke: currentColor;
    }

    .srt-fill-base-2-active {
        fill: #3c3728;
    }

    .srt-stroke-base-2-active {
        stroke: #3c3728;
    }

    .srt-fill-base-2-hover {
        fill: #3c3728;
    }

    .srt-stroke-base-2-hover {
        stroke: #3c3728;
    }

    .srt-fill-base-3 {
        fill: #3c3728;
    }

    .srt-stroke-base-3 {
        stroke: #3c3728;
    }

    .srt-fill-base-3-active {
        fill: #473e27;
    }

    .srt-stroke-base-3-active {
        stroke: #473e27;
    }

    .srt-fill-base-3-hover {
        fill: #473e27;
    }

    .srt-stroke-base-3-hover {
        stroke: #473e27;
    }

    .srt-fill-primary-1 {
        fill: #FAB615;
    }

    .srt-stroke-primary-1 {
        stroke: #FAB615;
    }

    .srt-fill-primary-2 {
        fill: #fccc5b;
    }

    .srt-stroke-primary-2 {
        stroke: #fccc5b;
    }

    .srt-fill-primary-3 {
        fill: #e1a413;
    }

    .srt-stroke-primary-3 {
        stroke: #e1a413;
    }

    .srt-fill-primary-4 {
        fill: #a47c1e;
    }

    .srt-stroke-primary-4 {
        stroke: #a47c1e;
    }

    .srt-fill-primary-5 {
        fill: #a47c1e;
    }

    .srt-stroke-primary-5 {
        stroke: #a47c1e;
    }

    .srt-fill-primary-6 {
        fill: #fcd577;
    }

    .srt-stroke-primary-6 {
        stroke: #fcd577;
    }

    .srt-fill-primary-7 {
        fill: #FAB615;
    }

    .srt-stroke-primary-7 {
        stroke: #FAB615;
    }

    .srt-fill-primary-8 {
        fill: #FAB615;
    }

    .srt-stroke-primary-8 {
        stroke: #FAB615;
    }

    .srt-fill-primary-8-is-active-1 {
        fill: #e1a413;
    }

    .srt-stroke-primary-8-is-active-1 {
        stroke: #e1a413;
    }

    .srt-fill-primary-8-is-active-2 {
        fill: #e1a413;
    }

    .srt-stroke-primary-8-is-active-2 {
        stroke: #e1a413;
    }

    .srt-fill-primary-9 {
        fill: #635124;
    }

    .srt-stroke-primary-9 {
        stroke: #635124;
    }

    .srt-fill-primary-10 {
        fill: #cf9919;
    }

    .srt-stroke-primary-10 {
        stroke: #cf9919;
    }

    .srt-fill-primary-11 {
        fill: #e1a413;
    }

    .srt-stroke-primary-11 {
        stroke: #e1a413;
    }

    .srt-fill-primary-12 {
        fill: #FAB615;
    }

    .srt-stroke-primary-12 {
        stroke: #FAB615;
    }

    .srt-fill-home-1 {
        fill: #7AC57B;
    }

    .srt-stroke-home-1 {
        stroke: #7AC57B;
    }

    .srt-fill-home-2 {
        fill: #7AC57B;
    }

    .srt-stroke-home-2 {
        stroke: #7AC57B;
    }

    .srt-fill-home-3 {
        fill: #7AC57B;
    }

    .srt-stroke-home-3 {
        stroke: #7AC57B;
    }

    .srt-fill-home-4 {
        fill: #609563;
    }

    .srt-stroke-home-4 {
        stroke: #609563;
    }

    .srt-fill-home-5 {
        fill: #a9d9a9;
    }

    .srt-stroke-home-5 {
        stroke: #a9d9a9;
    }

    .srt-fill-away-1 {
        fill: #F3E06C;
    }

    .srt-stroke-away-1 {
        stroke: #F3E06C;
    }

    .srt-fill-away-2 {
        fill: #F3E06C;
    }

    .srt-stroke-away-2 {
        stroke: #F3E06C;
    }

    .srt-fill-away-3 {
        fill: #F3E06C;
    }

    .srt-stroke-away-3 {
        stroke: #F3E06C;
    }

    .srt-fill-away-4 {
        fill: #b4a859;
    }

    .srt-stroke-away-4 {
        stroke: #b4a859;
    }

    .srt-fill-away-5 {
        fill: #f7eb9f;
    }

    .srt-stroke-away-5 {
        stroke: #f7eb9f;
    }

    .srt-fill-neutral-1 {
        fill: #3f4247;
    }

    .srt-stroke-neutral-1 {
        stroke: #3f4247;
    }

    .srt-fill-neutral-2 {
        fill: #e9e9ea;
    }

    .srt-stroke-neutral-2 {
        stroke: #e9e9ea;
    }

    .srt-fill-neutral-3 {
        fill: rgba(255, 255, 255, 0.12);
    }

    .srt-stroke-neutral-3 {
        stroke: rgba(255, 255, 255, 0.12);
    }

    .srt-fill-neutral-4 {
        fill: #b2b3b5;
    }

    .srt-stroke-neutral-4 {
        stroke: #b2b3b5;
    }

    .srt-fill-neutral-5 {
        fill: #c8c9ca;
    }

    .srt-stroke-neutral-5 {
        stroke: #c8c9ca;
    }

    .srt-fill-neutral-6 {
        fill: #575a5e;
    }

    .srt-stroke-neutral-6 {
        stroke: #575a5e;
    }

    .srt-fill-neutral-7 {
        fill: #b2b3b5;
    }

    .srt-stroke-neutral-7 {
        stroke: #b2b3b5;
    }

    .srt-fill-neutral-8 {
        fill: #3f4247;
    }

    .srt-stroke-neutral-8 {
        stroke: #3f4247;
    }

    .srt-fill-neutral-9 {
        fill: #2f3338;
    }

    .srt-stroke-neutral-9 {
        stroke: #2f3338;
    }

    .srt-fill-neutral-10 {
        fill: #3d4044;
    }

    .srt-stroke-neutral-10 {
        stroke: #3d4044;
    }

    .srt-fill-neutral-11 {
        fill: #7a7d80;
    }

    .srt-stroke-neutral-11 {
        stroke: #7a7d80;
    }

    .srt-fill-neutral-12 {
        fill: #bdbebf;
    }

    .srt-stroke-neutral-12 {
        stroke: #bdbebf;
    }

    .srt-fill-neutral-13 {
        fill: #383c40;
    }

    .srt-stroke-neutral-13 {
        stroke: #383c40;
    }

    .srt-fill-win {
        fill: #7AC57B;
    }

    .srt-stroke-win {
        stroke: #7AC57B;
    }

    .srt-fill-draw {
        fill: rgba(255, 255, 255, 0.4);
    }

    .srt-stroke-draw {
        stroke: rgba(255, 255, 255, 0.4);
    }

    .srt-fill-lose {
        fill: #F3E06C;
    }

    .srt-stroke-lose {
        stroke: #F3E06C;
    }

    .srt-stop-base-1 {
        stop-color: transparent;
    }

    .srt-stop-primary-1 {
        stop-color: #FAB615;
    }

    .srt-stop-primary-2 {
        stop-color: #fccc5b;
    }

    .srt-stop-primary-3 {
        stop-color: #e1a413;
    }

    .srt-stop-primary-4 {
        stop-color: #a47c1e;
    }

    .srt-stop-primary-5 {
        stop-color: #a47c1e;
    }

    .srt-stop-primary-6 {
        stop-color: #fcd577;
    }

    .srt-stop-primary-7 {
        stop-color: #FAB615;
    }

    .srt-stop-primary-8 {
        stop-color: #FAB615;
    }

    .srt-stop-primary-9 {
        stop-color: #635124;
    }

    .srt-stop-primary-10 {
        stop-color: #cf9919;
    }

    .srt-stop-primary-11 {
        stop-color: #e1a413;
    }

    .srt-stop-primary-12 {
        stop-color: #FAB615;
    }

    .srt-stop-home-1 {
        stop-color: #7AC57B;
    }

    .srt-stop-away-1 {
        stop-color: #F3E06C;
    }

    .srt-fill-neutral-14 {
        fill: #22262B;
    }

    .srt-stroke-neutral-14 {
        stroke: #22262B;
    }

    .srt-logo-powered-by-light {
        display: none;
    }

    .srt-logo-powered-by-dark {
        display: inline-block;
    }

    .sr-lmt-mybets-plus__footer-wrapper {
        height: 40px;
    }

    .sr-poweredby__wrapper {
        display: none !important;
    }
}

.sr-bb.sr-rtl {
    text-align: right;
}
