﻿.divFastSport {
	margin: 0 0 6px 0;
	height: 38px;
	border-radius: 6px;
	background-color: $grey-300d;

	.FSSX {
		padding: 0 6px 0 12px;
		height: 100%;
	}

	.FSDX {
		> div {
			margin-right: 6px;
			line-height: 38px;
			float: left;
			height: 38px;
		}
	}

	.FSdesc {
		font-size: $font-14;
	}

	.FSTxt {
		//;
	}

	.TxtBox {
		@include dvu-input(74px, 28px, 28px, 1.17px);
	}

	.FSDdl {
		//
	}

	.DropDown {
		//
	}

	.FSSE {
		width: 500px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: $font-12;
		padding-left: 6px;

		@media screen and (max-width:1435px) {
			width:400px;
		}
	}

	.FSBtn {
		padding-top: 6px;

		A {
			@include dvu-button-play();
		}
	}

	.FSHelp {
		float: right !important;
		padding-top: 10px;

		A {
			display: block;
			height: 16px;
			width: 16px;
			background-color: $grey-150d;
			border-radius: 4px;
			position: relative;

			&:after {
				content: '';
				mask-image: url('../../images/icons/pw365-iconpack/current/system/cond/solid/info.svg');
				width: 12px;
				height: 12px;
				position: absolute;
				background-color: $grey-300d;
				top: 2px;
				left: 2px;
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				mask-image: none;
				background-size: 14px;
				background-color: transparent !important;
				background: url('../../images/IEIcons/info.svg') no-repeat center center;

				&:after {
					background-color: transparent;
				}
			}

			&:hover {
				background-color: $grey-100d;
			}
		}
	}
}

#divLI .DropDown {
	width: 180px;
}

//** Prematch betcode popup **//
.ClickPageStyle {
	width: 100%;
	padding: 0;
	font-size: $font-14;
	color: $grey-300d;

	.ClickSectionStyle {

		.ClickDescStyle {
			@include dvu-titlebar();
			color: black;
			width: 50%;

			.Dati {
				position: absolute;
				top: 0px;
				right: 0px;
			}
		}
	}
}

.FastCodes {
	border: 1px solid rgba($grey-150d, 0.5);
	border-radius: 4px;

	&:hover {
		cursor: default;
	}

	.clickCQ {
		color: $black;
		text-align: left;
		font-weight: bold;
		padding: 3px 0 0 25px;
	}

	.clickTQ {
		text-align: left;
		width: 60%;
		padding: 1px 0px 1px 10px;
		font-size: 12px;
	}

	.clickCodCQ {
		text-align: right;
		width: 40%;
		padding: 1px 10px 1px 0;
		font-weight: bold;
	}
}

//**Agent page code list**//
.divMainClick {
	.ClickPageStyle {
		color: $grey-100;
	}

	.ClickDescStyle, .clickCQ {
		color: $white !important;
	}
}