﻿.divNewUserWide {
	.pnlUserData {
		background: none;

		.RiquadroWide {
			.NewUserData {
				width: 626px;
				margin: 0 auto;

				.BonusCode {
					background-color: #000000;
					border-radius: 10px;
					padding: 15px 0;

					.bonusTopRow {
						padding-bottom: 10px;
						width: 66%;
						margin: 0 auto;

						.lblBonus {
							color: #ebebeb;
							font-size: 12px;
							font-weight: bold;
							padding-right: 20px;
							float: left;
							line-height: 20px;
						}

						.bonusBadge {
							display: none;
							background-size: 34px;
							position: absolute;
							width: 34px;
							height: 34px;
							right: calc(47% - 5px);
							top: 140px;
						}

						.txtBonus {
							input {
								width: 145px;
								border-radius: 6px;
								border: 0px;
								height: 18px;
								outline: none;
								font-size: 11px;
								padding-left: 10px;
							}
						}
					}

					.regVal {
						bottom: 5px;
						position: relative;
						width: 66%;
						margin: 0 auto;
						text-align: left;

						.infoBonusFormat {
							font-size: 10px;
							font-style: italic;
							color: #cccecf;
							position: relative;
							display: none;
							float: right;
							right: 8px;
						}
					}

					.infoBonus {
						font-size: 11px;
						color: #dadada;
						position: relative;
						display: inline-block;
						left: 17%;
					}
				}

				.NewUserFormRegistration {
					background-color: #22262b;
					position: relative;
					border-radius: 6px;

					.head_title {
						@include dvu-font-medium($font-20);
						letter-spacing: 0.5px;
						text-align: center;
						height: 50px;
						line-height: 50px;
					}

					.main {
						position: relative;
						display: block;
						width: 100%;
						box-sizing: content-box;

						.SectionTitle {
							text-align: center;
							border: none;
							padding: 24px 0 14px 0;
							font-size: 0;
							margin: 0;

							h4 {
								@include dvu-font-medium($font-16);
							}
						}

						select {
							box-sizing: border-box;
							width: 280px;
							height: 42px;

							&.regSmall {
								width: 84px;
							}
						}

						input[type="text"],
						input[type="password"] {
							width: 280px;
							height: 42px;

							&:-webkit-autofill::first-line {
								font-size: 11px !important;
							}
						}

						input[id$="txtTelefono"] {
							width: 182px;
						}

						.row {
							display: block;
							width: 100%;
							height: 42px;
              clear: both;

							& > div {
								float: left;
							}

							.row_title {
								@include dvu-font-regular($font-10);
								letter-spacing: 0.15px;
								color: $grey-700;
								width: 140px;
								padding-left: 20px;
								display: inline-block;
								height: 42px;
								line-height: 42px;
							}

							.row_separator {
								@include dvu-font-regular($font-10);
								display: inline-block;
								width: 16px;
								line-height: 42px;
								height: 42px;
								text-align: center;
							}

							.row_data {
								display: inline-block;
								width: 280px;
								height: 42px;

								.divMainCF {
									.ErrCodiceFiscale {
										@include dvu-tooltip-default-simple($font-14);
										position: relative;
										width: 266px;
										background-image: none;
										top: 5px;

										&:after {
											content: '';
											width: 25px;
											height: 25px;
											mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
											-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/tip.svg');
											background-color: $white;
											position: absolute;
											display: inline-block;
											top: -6px;
											left: 18%;
										}
									}
								}
							}

							.row_warning {
								display: inline-block;
								width: 150px;
								padding: 0 10px;
								height: 42px;

								span {
									@include dvu-font-regular($font-10);
									color: $red-200 !important;
									letter-spacing: 0.15px;
									width: 100%;
									height: 42px;
									line-height: initial;
									position: relative;
								}
							}

							.row.addressCopy {
								height: initial;
								padding: 0 0 12px 20px;
								line-height: 20px;

								input[type="checkbox"] {
									display: inline-block;
									vertical-align: middle;
								}

								label {
									@include dvu-font-regular($font-10);
									color: $white;
									display: inline-block;
								}
							}
						}

						p:not(.WelcomeBonusMultiselect *) {
							color: $yellow-300d !important;

							&:hover {
								color: $yellow-200d !important;
							}
						}

						.Name,
						.LastName,
						.Username,
						.Password,
						.ConfirmPassword,
						.Valuta,
						.DomandaSicurezza,
						.RispostaSicurezza,
						.Gender,
						.Birthday,
						.PaeseBirth,
						.ProvinceBirth,
						.ComuneNascita,
						.PaeseResidence,
						.PaeseDomicile,
						.ProvinceResidence,
						.ProvinceDomicile,
						.City,
						.Address,
						.TipoDocumento,
						.NumeroDocumento,
						.AutoritaRilascio,
						.LocalitaRilascio,
						.DataRilascio,
						.FavoriteProduct,
						.PEPRegistrationAnswer,
						.WelcomeBonusMultiselect {
							margin-bottom: 14px;
						}

						div[id$="trNumeroDocumentoTN"] {
							display: none;
						}

						.Telefono {
							.row_data {
								& > div {
									font-size: 0;

									input {
										margin-left: 14px;
									}
								}
							}
						}

						.ResidencePlace,
						.DomicilePlace,
						.PlaceOfBirth,
						.WelcomeBonusMultiselect {
							height: initial;
							display: flex;
							position: relative;
						}

						.DataRilascio,
						.DataScadenza,
						.Birthday {
							.row_data {
								font-size: 0;

								select {
									&:nth-child(2) {
										margin: 0 14px;
									}
								}
							}
						}

						.row.LinguaMessaggi,
						.Valuta,
						.buttonCF {
							display: none;
						}

						.FavoriteProduct {
							.row_data {
								input[type="checkbox"] {
									top: 10px;
								}

								label {
									@include dvu-font-regular($font-10);
									position: relative;
									display: inline-block;
									margin-right: 16px;
									top: 12px;
									letter-spacing: 0.4px;
								}
							}
						}

						.WelcomeBonusAccept,
						.Contratto,
						.Responsabilita,
						.row_gdpr {
							margin-bottom: 14px;
						}

						.PEPRegistrationQuestion {
							.row_title {
								a {
									color: #ffbb03 !important;

									&:hover {
										color: $yellow-200d;
									}
								}
							}
						}

						.PEPRegistrationAnswer {

							.row_title {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}

						.WelcomeBonusAccept,
						.Contratto,
						.Responsabilita,
						.TCAccettaPrivacy,
						.row_gdpr {
							padding: 0 20px;
							display: block;

							.row_checkGdpr, .row_title {
								width: 16px;
								display: inline-block;
								vertical-align: top;
							}

							.row_separatorGdpr,
							.row_separator {
								width: 10px;
								display: inline-block;
								vertical-align: top;
								@include dvu-font-regular($font-10);
								text-align: right;
								height: 16px;
								line-height: 16px;
							}

							.row_dataGdpr,
							.row_data {
								width: 552px;
								display: inline-block;

								label {
									cursor: pointer;
								}

								label,
								p {
									margin: 0;
									font-size: $font-10;
									line-height: 16px;
									letter-spacing: 0.3px;
									color: $yellow-300d;

									&:hover {
										color: $yellow-200d;
									}

									a {
										color: #ffbb03 !important;
									}
								}
							}

							.row_warning {
								padding-top: 4px;

								span {
									@include dvu-font-regular($font-10);
									color: $red-200 !important;
									letter-spacing: 0.15px;
								}
							}
						}

						.CheckAll {
							margin-bottom: 14px;
							padding: 0 20px;
							height: 20px;
							line-height: 20px;
							font-size: 0;

							.Gdprswitch {
								display: inline-block;

								input[type="checkbox"] {
									margin: 3px 8px 2px 0px;

									&:checked {
										background-image: none;
									}
								}

								.gdprswitch-icon {
									pointer-events: none;
								}
							}

							.txtGdpr {
								display: inline-block;
								height: 20px;
								line-height: 20px;
								padding-left: 14px;

								label {
									p {
										font-size: $font-10;
									}
								}
							}
						}

						.cellaInfoDwn {
							@include dvu-font-regular($font-10);
							letter-spacing: 0.2px;
							padding: 0 20px 24px;
							color: $grey-700;
						}

						.NewUserInfo {
							display: block;
							width: 280px;
							margin: 0;
							text-align: justify;
							line-height: 12px;
							letter-spacing: 0.18px;
							color: $grey-100d;
							@include dvu-font-regular($font-10);
							padding: 4px 0 14px 176px;

							+ .SectionTitle {
								padding: 0 0 14px 0;
							}
						}

						.NewUserBtn {
							text-align: center;
							padding: 0 20px 40px;

							.openAccountBtn {
								border: none;
								border-radius: 4px;
								width: 192px;
								height: 36px;
								line-height: 36px;
								color: $white;
								@include dvu-font-medium($font-14);
								background-color: $green-300d;
								letter-spacing: 0.4px;
								background-image: none;
								margin: 0;
								padding: 0;

								&.disabledBtn {
									pointer-events: none;
									opacity: 0.6;
								}

								&:not(.disabledBtn):hover {
									background-color: $green-200d;
									background-image: none;
									margin: 0;
									padding: 0;
								}

							}

							.resetBtn,
							.resetBtn_hover {
								display: none;
							}
						}

						.divTabs{
							text-align: center;
							padding: 15px 0 30px 0;
	
							.tabButton{
								@include dvu-button-small-m();
								line-height: 28px;
								margin:5px 3px;
	
								&.selected{
									background-color: $yellow-300;
									color: black;
								}
							}
						}
					}
				}
			}
		}
	}
}

.cdivRegistrationProcess {
	#popupRegistrationProcess {
		width: 29%;
		position: fixed;
		top: 25%;
		left: 33.33333%;
		display: block;
		background-color: $black;
		z-index: 3;
		border-radius: 0;

		.RiquadroPopRiserva {
			.Cnt {
				border-radius: 0;
				background-image: none;
				box-shadow: none;
				border: none;
				color: $grey-300d;

				& > div {
					background-color: transparent;

					.divTitle {
						display: none;
						background-image: none;
					}

					.img {
						display: block;
						background-repeat: no-repeat;
						width: 84px;
						height: 90px;
						background-size: 100%;
						margin: 0 auto;
						background-position: bottom;
					}

					.MsgRegProces {
						p.err_title {
							padding: 12px 44px 0;
							display: block;
							font-size: 14px;
							color: $grey-300d;
							font-weight: bold;
							text-align: center;
						}

						p {
							color: #a2a2a2;
							font: normal 12px Roboto;
							line-height: 16px;
						}
					}

					.btnCheckData {
						text-align: center;
						padding: 10px 0 14px 0;

						input {
							background: linear-gradient(to bottom, #84d632 50%, #4ebd00 50%);
							margin: 0;
							width: 220px;
							height: 32px;
							line-height: 32px;
							font: bold 14px Roboto;
							text-transform: capitalize;
							letter-spacing: 0.2px;
							border: none;

							&:hover {
								background: #84d632;
							}
						}
					}
				}
			}
		}
	}
}

.mainlanding {
	.LandingRegistration {
		.lndgRegistration {
			.lrPrivacyPolicy {
				width: 100%;
				height: 30px;
				display: block;
				font-size: 0;

				.prPolicy {
					width: 5%;
					height: 100%;
					display: inline-block;

					input {
						appearance: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						width: 16px;
						height: 16px;
						border-radius: 4px;
						border: solid 1px #868a91;
						margin: 0;
						padding: 0;
						cursor: pointer;
						position: relative;
						outline: none;

						&:checked {
							background-color: $yellow-400d;
							border: none;
							-webkit-animation-name: checkboxFocus,checkboxPop;
							animation-name: checkboxFocus,checkboxPop;
							-webkit-animation-duration: .3s,.1s;
							animation-duration: .3s,.1s;
							-webkit-animation-delay: .1s,0ms;
							animation-delay: .1s,0ms;
							-webkit-animation-timing-function: steps(1),ease-in-out;
							animation-timing-function: steps(1),ease-in-out;

							&:hover {
								background-color: $yellow-300d;
							}
						}

						&:hover {
							background-color: $grey-100d;
						}
					}
				}

				p {
					font: normal 10px Roboto;
					margin: 0;
					display: inline-block;
					height: 100%;
					vertical-align: top;
					padding-left: 5px;
					padding-top: 2px;
					color: $yellow-300d;

					&:hover {
						color: $yellow-200d;
					}

					a {
						color: #ffbb03;
					}
				}
			}

			.lrInfoText {
				font: normal 10px Roboto;
				padding: 10px 0 20px 0;
			}
		}
	}
}
