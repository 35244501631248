.divCoupon,
.divCouponHome {
  font-size: $font-11;

  .Top {
    height: 42px;
    position: relative;
    overflow: hidden;
    margin: 0;
    z-index: 1;
    background-color: $yellow-400d;
    background-image: none;
    color: #000;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: $font-16;
    padding: 0 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;

    &.Anonimo {
      height: 40px;
      background-position: center -218px;

      + #divContentCoupon {
        margin-top: 0;
        border-bottom: none;

        .divCpnTipoRic,
        .divCpnTipoRicCnt {
          background-color: $yellow-400d;
        }
      }
    }

    .lnkBlocca, .lnkSblocca {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      position: relative;
      flex: 1;
      box-sizing: content-box;
      background-color: $grey-400;
      margin-right: 4px;

      &::before {
        content: "";
        mask-size: 20px;
        -webkit-mask-size: 20px;
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &:hover {
        background-color: $grey-300;
      }
    }

    .lnkBlocca {
      &::before {
        background-color: $yellow-400;
        mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/solid/pin.svg');
        -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/solid/pin.svg');
      }

      &:hover {
        &::before {
          background-color: $yellow-300;
        }
      }
    }

    .lnkSblocca {
      &::before {
        background-color: $grey-100;
        mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/pin.svg');
        -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/pin.svg');
      }
    }

    h3 {
      margin: 0;
      padding: 0;
      line-height: 28px;
      height: 28px;
      font-weight: 500;
      color: #000;
      font-size: $font-20;
      letter-spacing: 0.32px;

      &:hover {
        cursor: default;
      }
    }

    .dvuGroup {
      width: auto;
      text-align: right;
      display: flex;
    }

    span {
      color: #000;
      white-space: nowrap;
      font-size: $font-10;
      font-weight: normal;
      text-transform: none;
    }

    .btnTab {
      line-height: 42px;
      height: 42px;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: .32px;
      display: inline-block;
      color: #9a7a2d;
      position: relative;
      text-align: center;
      flex: 1 1 0px;

      &.active {
        color: $black;

        &::before {
          content: "";
          width: 50%;
          height: 2px;
          background-color: $black;
          display: block;
          left: 50%;
          transform: translatex(-50%);
          bottom: 0;
          position: absolute;
        }
      }

      > span.cashoutCouponCount {
        position: absolute;
		z-index: 10;
		right: 4px;
		top: 9px;
		width: 18px;
		height: 18px;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 9px;
		display: none;
		border-radius: 50%;
		background: #3b9b22;

        &.visible {
          display: flex;
        }
      }
    }
  }

  .Cnt {
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    background-color: $yellow-400d;

    hr {
        display: none;
    }

    .btnListAnonCodice {
      color: $black;
      font-size: $font-14;
      position: relative;
      padding: 0 0 0 20px;
      margin: 6px 0px 6px 6px;
      line-height: 16px;
      height: 16px;
      display: block;
      width: unset;
      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: $black;
        mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/betslip.svg');
        -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/betslip.svg');
        mask-size: 16px;
        -webkit-mask-size: 16px;
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
      }

      &::after {
        content: attr(after-content);
        position: absolute;
        top: -4px;
        left: 8px;
        background-color: $green-300d;
        border-radius: 6px;
        width: 12px;
        height: 12px;
        font-size: $font-9;
        text-align: center;
        line-height: 12px;
        color: $white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
      }

      &:hover {
        color: $grey-200d;

        &::before {
          background-color: $grey-200d;
        }

        &::after {
          background-color: $green-200d;
        }
      }
	  > span.cashoutCouponCount {
		position: absolute;
		z-index: 10;
		right: 4px;
		top: 9px;
		width: 18px;
		height: 18px;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 9px;
		display: none;
		border-radius: 50%;
		background: #3b9b22;
		&.visible {
			display:flex;
		}
	  }

      &[after-content] + hr:not(:last-child) {
        display: block;
        border: none;
        padding: 0;
        margin: 0 7px;
        height: 1px;
        background-color: $black;
      }
    }
  }

  .Btm {
    height: 0;
  }

  .TextBox {
    @include dvu-input-betslip(112px, 28px, 28px, 1px);
    min-width: 112px;
    margin-right: -16px;
  }

  input:-webkit-autofill,
  input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 100px $white inset !important;
    -webkit-text-fill-color: $grey-300d;

    &::first-line {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .divCpnTipoRic,
  .divCpnTipoRicCnt {
    background-color: transparent;
    font-family: $roboto;
  }

  .divCpnTipoRic {
    font-size: $font-14;
    text-transform: inherit;
    margin-top: 6px;
    position: relative;
    margin-left: 6px;
    padding-left: 20px;
    line-height: 16px;
    height: 16px;
    display: inline-block;
    font-weight: 500;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: #000;
      mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/re-bet.svg');
      -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/re-bet.svg');
      mask-size: 16px;
      -webkit-mask-size: 16px;
      mask-position: center;
      -webkit-mask-position: center;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
    }
  }

  .divCpnTipoRicCnt {
    .ScommRisTRItem,
    .ScommRisTRAItem {
      height: 22px;
      border-top: 0.5px solid rgba($grey-200d,0.4);

      a {
        line-height: 22px;
        font-weight: normal;
      }
    }
  }

  #mybets-wrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    border-bottom: $grey-300d 5px solid;
    position: relative;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: $grey-100d;
    }

    &::-webkit-scrollbar-thumb {
      height: 46px;
      border-radius: 8px;
      background-color: $yellow-200d;
    }

    [id$='PanelMyBets'] {
      background-color: $grey-300d;
      position: relative;
      padding-bottom: 3px;
    }

    .loader_indicator {
      display: none;
      // position: absolute;
      width: 52px;
      height: 52px;
      top: calc(50% - 18px);
      left: calc(50% - 25px);
      z-index: -1;
      background-color: transparent;
      border: 8px solid rgba(235, 169, 23, 0.2);
      border-left-color: rgba(235, 169, 23, 1);
    }

    &.loading {
      min-height: 100px;

      .loader_indicator {
        display: block;
      }
    }
  }
}

.divCoupon {
	position: relative;
	top: 0px;
}

#divInserimentoScommesse {
	position: relative;
	padding-top: 2px;

	.CpnPuls {

		&.CouponMainAccepted {
			height: auto;
		}

		&[id$='pnlCommandPanelMessage'] {
			@include dvu-notificationMess-warning();
			font-size: $font-11;
			line-height: 1.27;
			letter-spacing: 0.17px;
			height: auto;
			color: $black;
			text-align: left;
			margin-top: 8px;
		}
	}
}

.CouponMainIns {
	padding-bottom: 20px;

	.CpnPotWinMess {
		color: #343434;
		background-repeat: no-repeat;
		padding-left: 24px;
		font-size: $font-10;
		padding-top: 20px;
		background-position: 0 23px;
		margin-bottom: -10px;

		&:before {
			content: '';
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
			background-color: $grey-200d;
			display: block;
			position: absolute;
			width: 24px;
			height: 24px;
			left: 10px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-color: $transparent;
				background-image: url('../../images/IEIcons/info.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				mask-image: none;
			}
		}
	}

	.COdds {
		&.True {
			&.isFisse {
				.valQuota,
				.valQuota_0,
				.valQuota_1,
				.valQuota_2,
				.valQuota_3,
				.valQuota_4,
				.valQuota_5,
				.valQuota_10 {
					padding-right: 32px;
				}

				.Cerror {
					right: 18px;
				}

				.CSegno {
					width: 128px;
				}
			}

			.valQuota,
			.valQuota_0,
			.valQuota_1,
			.valQuota_2,
			.valQuota_3,
			.valQuota_4,
			.valQuota_5,
			.valQuota_10 {
				padding-right: 11px;
				color: $red-300d;
			}

			.valQuota_0 {
				color: $yellow-400d;
			}

			.valQuota_3 {
				color: $tripla;
			}

			.Cerror {
				cursor: help;
				width: 10px;
				height: 10px;
				position: absolute;
				right: 0px;
				top: 4px;
				background-color: $red-300d;
				border-radius: 50%;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
			}

			.CSubEv {
				width: 137px;
			}

			.CInfo {
				top: 1px;
			}
		}
	}

	.divCpnTipi {
		position: relative;
		padding-top: 28px;

		input[type="checkbox"] {

			&:not(:checked) {
				background: $white;
			}
		}

		.divCpnTipo {
			position: absolute;
			z-index: 1;
			width: 123px;
			padding: 0;
			font-size: $font-12;
			font-weight: 500;
			margin: 0;
			height: 25px;
			line-height: 25px;
			text-align: center;
			color: #141414;
			overflow: hidden;
			background-color: $white;
			border-bottom: 0;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;

			&.Mul {
				left: 0px;
				top: 3px;
			}

			&.Sis {
				right: 0px;
				top: 3px;
			}

			&.DI {
				left: 0px;
				top: 0;
				width: 246px;
				height: 27px;
				font-size: $font-16;
				font-weight: 500;
				letter-spacing: 0.32px;
				color: $grey-300d;
			}

			a {
				color: $grey-300d;
				text-decoration: none;
				display: block;
				background-color: $yellow-100d;
			}
		}

		.sel {
			.divCpnTipo {
				background-position: 0 -27px;
				z-index: 100;
				height: 28px;

				a {
					color: #141414;
					text-decoration: none;
					background-color: $white;
					font-size: $font-16;

					&:hover {
						cursor: default;
					}
				}

				&.Sis, &.Mul {
					top: 0px !important;
				}
			}

			.Mul,
			.Sis {
				~ .divCpnTipoCnt {
					border-top-right-radius: 0px !important;
					border-top-left-radius: 0px !important;
				}
			}

			&.singola {
				.divCpnTipo {
					left: 0px;
					top: 0;
					width: 246px;
					height: 28px;
					font-size: $font-16;
					letter-spacing: 0.32px;
				}
			}
		}
	}

	.divCpnTipo {
		img {
			display: none;
		}
	}

	.divCpnTipoCnt {
		position: relative;
		z-index: 10;
		margin: 0;
		font-size: $font-12;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		color: $grey-300d;
		background-color: $white;
		padding: 0 6px 0 6px;
		display: table-cell;
		width: 246px;

		.CpnTipoRiep,
		.CpnTipoRiepDbl,
		.CpnTipoSisRiep {

			&.dvu-import {
				border-bottom: 1px solid $grey-150d;
				padding-bottom: 6px;
				height:auto;
			}
		}
	}

	.pnlPotWinMessage {
		background-color: transparent;
		border-radius: 6px;
		margin-top: 20px;

		.CpnPotWinMess {
			margin-left: 5px;
			color: $grey-300d;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			background-position: -16px 23px;
			padding: 12px 12px 12px 32px;
			background-image: none;
		}
	}

	.CouponUserData {
		background-color: $white;
		border: none;
		margin: 0 0 8px;
		border-radius: 6px;
		line-height: 14px;

		.CouponUserDataHdr, .Saldo {
			border: none;
			font-size: 12px;
			line-height: 14px;
			padding: 2px 0;
			height: auto;
			background-color: transparent;
			font-weight: bold;
		}

		.cboUtenti {
			height: auto;
			overflow: hidden;
		}

		.cboUtente {
			height: auto;
			width: 70%;

			select {
				width: 100% !important;
				background-color: white;
				color: $black;
				position: inherit;

				&:focus {
					background-color: white;
					color: $black;
				}
			}
		}

		.txtUtente {
			height: auto;
			width: 25%;

			input {
				position: inherit;
				@include dvu-input-betslip(100% !important, 28px !important, 28px, 1px);
			}
		}
	}

	.cpnMultibonus {
		background-color: $yellow-200;
		margin: 6px 0;
		border-radius: 4px;		
		padding: 10px 6px 10px 10px;
		display: none;
		.head{
			.present{
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/multibonus_o.svg');				
				background: $black;
				width: 15px;
				height: 15px;
				display: inline-block;
				mask-size: 15px;
				position: relative;		
				top: 2px;		

				&.open{
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/multibonus_outline_open.svg');		
				}
			}

			.text{
				color: $black;
				font-size: 13px;
				padding-left: 8px;
				font-family: Roboto;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
			}
			
			input {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
				background-color: $grey-150d;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background-position: center;
				border: initial;
				float: right;
				position: relative;
				top: 2px;
			}
		}

		.main{
			font-family: Roboto;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color:$black;
			padding: 8px 0;
			span{
				display: none;
				font-family: Roboto;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;				
				p{
					display: inline-block;
				}
			}
			
		}

		.bonus-line {
			display: flex;
			background: initial;
			height: initial;

			&.bottom {
				background: $grey-100d;
				border-radius: 4px;
				height: 4px;
			}

			& > span {
				min-height: 3px;
				margin-right: 3px;
				-webkit-flex-grow: 1;
				flex-grow: 1;
				background-color: $grey-100d;

				&.full {
					background-color: $yellow-400;
				}

				&.dynamic {
					background-color: $green-400;
					border-radius: 4px;
				}
			}
		}
	}
}

.CouponMainIns,
.CouponMainConf {
	padding: 0px 6px 6px 6px;

	input:-internal-autofill-selected {
		-webkit-box-shadow: 0 0 0 100px $white inset !important;
		-webkit-text-fill-color: $grey-300d;

		&::first-line {
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-weight: normal;
		}
	}

	.CItems {
		margin: 0;
		padding: 0;
		position: relative;
		margin-bottom: 8px;
	}

	.CItem {
		position: relative;
		color: $grey-300d;
		font-size: $font-11;
		background-color: $white;
		background-image: none;
		border: 1px solid $white;
		border-radius: 4px;
		overflow: hidden;
		min-height: 35px;
		margin-top: 2px;
		margin-bottom: 0px;
		padding: 2px 4px 2px 2px;
	}
}

.CouponMainConf {
	.CItem {
		.CSubEv {
			width: 170px;
		}

		.CSegno {
			left: 4px;
			width: 157px;
		}

		.CFissa {
			right: 1px;
			height: 11px;
			font-size: 0px;
		}

		.COdds {
			&.True {
				background-color: #f1a593;
			}
		}
	}

	.divCpnTipi {
		border-radius: 5px;
		font-size: $font-11;
		margin: 0;
		color: #000000;
		background-color: #fff;
		padding: 0px 6px;

		.divCpnSelectAnagrafica {
			overflow: hidden;
			padding-bottom: 6px;

			input {
				@include dvu-input-betslip(50px, 28px, 28px, 1px);
				float: left;
				margin-right: 4px;
				font-size: $font-12;
				padding: 0 5px;
				color: $black;
			}

			.Titolo {
				padding: 6px 0;
				margin: 0;
				font-weight: bold;
				text-align: center;
				border: none;
			}

			.Info {
				padding: 2px 0 6px 0;
				text-align: center;
			}

			.txtIDAnagrafica {
				width: 50px;
			}

			.txtAnagrafica {
				width: 128px;
			}

			.btnAnagrafica,
			.btnStampa {
				background-color: $grey-300d;
				width: 20px;
				height: 20px;
				float: left;
				margin: 3px 0 0 0;
				background-position: 0;

				&:hover {
					background-color: $yellow-200d;
				}
			}

			.btnAnagrafica {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/account.svg');
				display: block;
				margin-right: 8px;
			}

			.btnStampa {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/print.svg');
				display: none;
			}
		}
	}

	.CpnTipoRiep {
		&.High {
			top: 0px;
		}
	}

	.CpnPuls {
		.btnCoupon {
			&.sx {
				@include dvu-button-large-s();
				position: initial;
				margin-top: 0;
				width: 120px;
				text-transform: capitalize;
			}

			&.mx {
				@include dvu-button-green-large-s();
				width: 120px;
				float: right;
				text-transform: capitalize;
			}
		}
	}
}

.LiveCoupon {
	z-index: 1;
	overflow-x: hidden;
	overflow-y: auto;
	width: 250px;
}

.divCouponContent {
	width: 235px;
	margin-bottom: 15px;
}

.couponFixed {
	position: fixed;
	top: 0px;
}

.bodyMainLive {
  .divCoupon,
  .divCouponHome {
    .Top {
      &.Anonimo {
        + #divContentCoupon {
          margin-top: 0px;
        }
      }
    }
  }

  .divCouponHome {
    .Top {
      &.Anonimo {
        background-position: center top;
        height: auto;
      }
    }

    .Cnt {
      hr {
        display: none !important;
      }

      .btnListAnonCodice {
        display: none !important;
      }
    }
  }

  .divCoupon {
    .Top {
      &.Anonimo {
        height: 51px;
      }
    }
  }

  &.Logged {
    .divCouponHome {
      .Btm {
        height: auto;
      }
    }
  }

  .lcNumEventi {
    position: relative;
    top: 2px;
    left: 12px;
  }

  .CouponMainIns,
  .CouponMainConf {
    margin-top: 0px;
  }
}

.CouponMainEmpty {
	padding: 2px 19px 2px 15px;
	color: #000000;
}

.lcNumEventi {
	padding-left: 12px;
	color: #000;
	white-space: nowrap;
	font-size: $font-10;
	font-weight: normal;
	text-transform: none;
}

.cpnDivAttesa {
	padding: 12px 6px 0;
	background-color: transparent;
	height: 120px;

	.divAnimazioneLiveHTML {
		display: block;
		width: 74px;
		height: 74px;
		text-align: center;
		background-color: transparent;
		border: 1px solid $grey-300d;
		border-radius: 50%;
		margin: auto;
	}

	.cpncounter {
		height: 54px;
		width: 100%;
		margin-top: -71px;
		background-image: url('../../images/counter.png');
		background-repeat: no-repeat;
		background-position: 72px 42px;
		margin-left: 0px;
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		-webkit-animation-name: counter10secs;
		animation-name: counter10secs;
		-webkit-animation-duration: 10s;
		animation-duration: 10s;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}

  .cpnLoaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid $black;

    .cpnLoader {
      margin: 11px 0 12px;
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: 5px solid $yellow-100d;
      border-left-color: $grey-200d;
      border-radius: 100%;
      animation: spinning 1s infinite;
    }

    @keyframes spinning {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    .cpnLoaderMessage {
      color: $grey-400d;
      font-size: 12px;
      line-height: 14px;
      width: 202px;
      text-align: center;
    }
  }
}

.couponempty {
	background-image: none;
	background-color: $white;
	color: $grey-300d;
	margin: -14px 6px 6px 6px;
	border-radius: 4px;
	padding: 6px 6px 8px 6px;
	text-align: left;
	font-size: $font-11;
	line-height: 13px;
	letter-spacing: 0.17px;

	.CpnPuls {
		/*padding: 6px 0px;
		height: 36px;*/
		padding-top:5px;

		.TextBox {
			width:154px;
			//@include dvu-input-betslip(154px, 28px, 28px, 1px);
		}

		.btnCoupon {
			&.mx {
				@include dvu-button-green-small-s();
				float: right;
				width: 68px;
			}
		}
	}
}

.CItem {
	.CInfo {
		position: absolute;
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
		background-color: $grey-100d;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-position: center;
		right: 4px;
		top: 2px;
		background-size: 11px;
		background-repeat: no-repeat;
		cursor: help;
	}

	.Cerror {
		width: 16px;
		height: 16px;
		position: absolute;
		right: 50px;
		top: 1px;
		background-repeat: no-repeat;
	}

	.CCodPub {
		position: absolute;
		left: 5px;
		top: 5px;
		font-size: $font-10;
		line-height: 10px;
		height: 10px;
	}

	.CEvento {
		display: none;
	}

	.CFissa {
		position: absolute;
		right: 18px;
		top: 2px;

		.lnkFissaOff,
		.lnkFissaOn {
			display: block;
			width: 15px;
			height: 15px;

			&:before {
				content: 'B';
				font-size: 14px;
				text-align: center;
				line-height: 17px;
				display: block;
				font-weight: 500;
				width: 16px;
				height: 16px;
				border-radius: 4px;
				top: -2px;
				position: absolute;
			}
		}

		.lnkFissaOff {
			&:before {
				background-color: $grey-100d;
				color: $white;
			}
		}

		.lnkFissaOn {
			&:before {
				background-color: $yellow-300d;
				color: $black;
			}
		}
	}

	.CSubEv {
		cursor: help;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 80%;
		padding: 0 0 0 29px;
		height: 18px;
		line-height: 16px;
		font-weight: bold;
		font-size: $font-13;
		letter-spacing: 0.24px;
	}

	.CSegno {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 137px;
		height: 18px;
		position: absolute;
		left: 17px;
		top: 0px;
		font-weight: normal;
		line-height: 18px;
		font-size: $font-10;
	}

	.CDelete {
		position: absolute;
		background-image: none;
		mask-image: url('../../Images/close.svg');
		background-color: $red-300d;
		left: 0px;
		top: 0px;
		width: 16px;
		height: 16px;
		display: block;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background-color: $transparent;
			background-image: url('../../images/IEIcons/cancel.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 9px;
			mask-image: none;
		}

		&:hover {
			background-color: $red-200d;
		}
	}

	.COdds {
		position: relative;
		line-height: 18px;
		height: 18px;

		&.isFisse {
			right: 1px;
			top: 0;

			.CFissa {
				right: 0;
				top: 2px;
			}

			.valQuota,
			.valQuota_0,
			.valQuota_1,
			.valQuota_2,
			.valQuota_3,
			.valQuota_4,
			.valQuota_5,
			.valQuota_10 {
				padding-right: 22px;
			}
		}
	}

	.ClblSegno {
		font-weight: normal;
		padding-right: 5px;
		color: #000000;
	}

	.valQuota,
	.valQuota_0,
	.valQuota_1,
	.valQuota_2,
	.valQuota_3,
	.valQuota_4,
	.valQuota_5,
	.valQuota_10 {
		text-align: right;
		font-size: $font-12;
		line-height: 18px;
		font-weight: bold;
		color: $grey-300d;
		padding-right: 0px;
		letter-spacing: 0.24px;
	}

	.valQuota_0 {
		color: $yellow-400d;
	}

	.valQuota_3 {
		color: $tripla;
	}

	.te6,
	.te10 {
		top: -17px;

		.CEvento {
			color: $grey-300d;
			font-size: font-11;
			font-weight: 600;
			top: 2px;
			left: 32px;
		}
	}

	&.te10,
	&.te6 {
		.CEvento {
			display: block;
			position: absolute;
			left: 35px;
			top: 3px;
			height: 14px;
			line-height: 14px;
			width: 140px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.CSubEv {
			padding-top: 13px;
			padding-left: 33px;
			height: 28px;
		}

		.CFissa {
			top: -27px;
		}
	}
}

.CouponUserData {
	background-color: $white;
	margin: 0 0 8px;
	border-radius: 6px;
	padding: 0 5px 5px 5px;
	position: relative;
	text-align: center;
	color: #000000;
	line-height: 14px;

	.Saldo {
		height: auto;
		font-size: $font-12;
		font-weight: bold;
		margin: 0;
		padding: 2px 0;
		line-height: 14px;
		color: #000000;
		text-align: center;
		background-color: transparent;
	}

	.cboUtenti {
		height: auto;
		overflow: hidden;

		.txtUtente {
			float: left;
			height: auto;
			position: relative;
			width: 25%;

			input {
				@include dvu-input-betslip(100% !important, 28px !important, 28px, 1px);
			}
		}

		.cboUtente {
			float: right;
			width: 70%;
			overflow: hidden;
			position: relative;
			height: auto;

			select {
				width: 100% !important;
				background-color: white;
				color: $black;
				position: inherit;

				&:focus {
					background-color: white;
					color: $black;
				}
			}
		}
	}

	.dropdown {
		width: 135px;
		position: absolute;
		left: 0;
		top: 0;

		&.Expanded {
			width: auto;
		}
	}
}

.CouponUserDataHdr {
	height: auto;
	font-size: $font-12;
	font-weight: bold;
	margin: 0;
	padding: 2px 0;
	line-height: 14px;
	color: #000000;
	text-align: center;
	background-color: transparent;
}

.dropdown {
	.CpnUser {
		width: 135px;
	}
}

.CpnTipoBonus {
	background-color: #f2b100;
	margin-top: 5px;
	border-radius: 5px;
	line-height: 18px;
	text-align: center;
	font-weight: bold;
	border: 1px solid #ffde85;
}

.CpnTipoRiep {
	padding-top: 3px;

	.lnkRefresh {
		float: left;
		width: 20px;
		height: 20px;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.RiepSX,
.RiepDX {
	line-height: 14px;
}

.RiepSX {
	margin-right: 5px;
}

.CpnTipoRiep,
.CpnTipoRiepRagg {
	line-height: 22px;
	position: relative;
	clear: both;
	width: 100%;
	display: inline-block;

	.RiepSX {
		float: left;
		position: relative;
		left: 0px;
		line-height: 28px;
		font-weight: 500;

		.selectall {
			padding-left: 20px;
			max-width: 100px;
			height: 16px;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;

			> div {
				background: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
				width: 16px;
				height: 16px;
				background-color: $yellow-400d;
				display: inline-block;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.unselectall {
			padding-left: 20px;
			max-width: 100px;
			height: 16px;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;

			> div {
				padding-left: 0;
				background: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
				width: 16px;
				height: 16px;
				background-color: $yellow-400d;
				display: inline-block;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.RiepDX {
		float: right;
		text-align: right;
		font-weight: 500;
		font-size: $font-14;
		position: relative;
		/*right: 6px;*/
		line-height: 28px;

		#Cpn_CombiMultibonus{
			display: none;

			.CpnCnt_icon{
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/multibonus_o.svg');				
				background: $black;
				width: 15px;
				height: 15px;
				display: inline-block;
				mask-size: 15px;
				position: relative;		
				mask-repeat: no-repeat;
				top: 2px;	
				
				&.open{
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/multibonus_outline_open.svg');		
				}
			}
			
			#CpnCnt_val{
				border-right: 1px solid $grey-150d;
    			padding-right: 4px;
			}
		}
	}
}

.CpnTipoRiepRagg {
	height: 30px;
	line-height: 23px;

	.RiepSX {
		height: 31px;
		position: relative;
		width: 47%;
		margin-right: -12px;
		letter-spacing: 0.24px;
		left: 0;

		+ div {
			height: 23px;
			position: relative;
			float: right;
			width: 57%;
		}

		a {
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: left center;
			padding-left: 15px;
			display: block;
			line-height: 12px;
			position: absolute;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	.RiepDX {
		top: 3px;
		right: 6px;
		font-size: 14px;
		font-weight: 500;
	}
}

.CpnTipoRiep,
.CpnTipoRiepDbl {
	&.High {
		font-weight: bold;
		color: $black;
		border: 0;
	}

	&.HighImp {
		font-weight: bold;
	}
}

.CpnTipoRiepDbl {
	height: 35px;
	position: relative;
	clear: both;
	top: 6px;

	.RiepSX {
		float: left;
		font-weight: bold;
		line-height: 17px;
	}

	.RiepDX {
		float: right;
		text-align: right;
		line-height: 17px;
		font-size: $font-14;
		font-weight: 500;
	}

	.lnkRefresh {
		float: left;
		width: 20px;
		height: 35px;
		background-position: center center;
		background-repeat: no-repeat;
		margin-right: -20px;
		margin-left: 2px;
	}

	&.High {
		height: 50px;
		padding-top: 5px;

		.RiepDX {
			display: block;
			float: right;
			width: auto;
			height: 17px;
			line-height: 17px;
			font-weight: 500;

			> div {
				position: relative;
			}
		}

		.RiepSX {
			display: block;
			float: left;
			clear: both;
			width: auto;
			height: 17px;
			line-height: 17px;
			font-weight: 500;
		}

		.lnkRefresh {
			position: absolute;
			left: 0;
			top: 8px;
		}
	}
}

.CpnTipoSisRiep,
.CpnTipoSisRiepHdr {
	position: relative;
	height: 16px;
	margin-top: 3px;

	.TsTipo {
		width: 76px;
	}

	.TSNComb {
		text-align: right;
		left: 88px;
	}
}

.CpnTipoSisRiepHdr {
	height: 20px;
	font-size: $font-10;
	font-weight: bold;
	margin: 0;

	.TSTipo {
		display: block;
		position: absolute;
		left: 0px;
		top: 0;
		height: 20px;
		line-height: 20px;
		width: 50px;
	}

	.TSNComb {
		display: block;
		position: absolute;
		left: 90px;
		top: 0;
		height: 20px;
		line-height: 20px;
		width: 30px;
		white-space: nowrap;
	}

	.TSTxt {
		display: block;
		position: absolute;
		right: 0px;
		top: 0;
		height: 20px;
		line-height: 20px;
	}

	.TSVinLbl,
	.TSVin,
	.TSVinMin,
	.TSVinMax,
	.TSVinMinLbl,
	.TSVinMaxLbl {
		display: none;
	}
}

.CpnTipoSisRiep {
	height: 28px;
	margin-top: 4px !important;

	&.sel {
		height: 70px;

		.TSVinMin {
			display: block;
			position: absolute;
			right: 6px;
			top: 32px;
			height: 17px;
			line-height: 17px;
			font-size: $font-14;
		}

		.TSVinMax {
			display: block;
			position: absolute;
			right: 6px;
			top: 52px;
			height: 17px;
			line-height: 17px;
			font-size: $font-14;
		}

		.TSVinMinLbl {
			display: block;
			position: absolute;
			left: 5px;
			top: 32px;
			height: 17px;
			line-height: 17px;
			white-space: nowrap;
		}

		.TSVinMaxLbl {
			display: block;
			position: absolute;
			left: 5px;
			top: 52px;
			height: 17px;
			line-height: 17px;
			white-space: nowrap;
		}
	}

	.TSChk {
		display: block;
		position: absolute;
		top: 7px;
		left: 0px;
		height: 20px;
		line-height: 20px;
		width: 20px;
	}

	.TSHid {
		display: none;
	}

	.TSTipo {
		display: block;
		position: absolute;
		top: 5px;
		left: 20px;
		height: 20px;
		line-height: 20px;
		width: 87px;
	}

	.TSNComb {
		display: block;
		position: absolute;
		left: 88px;
		top: 5px;
		height: 20px;
		line-height: 20px;
		width: 30px;
	}

	.TSTxt {
		display: block;
		position: absolute;
		right: 6px;
		font-size: $font-14;
		font-weight: 500;
		top: 0;
	}

	.TSVinLbl,
	.TSVin,
	.TSVinMin,
	.TSVinMax,
	.TSVinMinLbl,
	.TSVinMaxLbl {
		display: none;
	}

	.RiepDX {
		right: 6px;
	}
}

.valLen3 {
	.CpnTipoSisRiep {
		.TSNComb {
			left: 95px;
			width: 15px;
			text-align: right;
		}

		.TSTxt {
			min-width: 91px;
			text-align: right;

			> .TextBox {
				float: left;
			}
		}
	}
}

.divCpnAvvisio {
	padding: 10px 0 0 0;

	span {
		color: $grey-300d;
	}
}

.CpnAccetaCambioQuote {
	border-radius: 4px;
	margin-top: 8px;
	background-color: $yellow-200d;
	color: $grey-300d;
	height: 30px !important;
	font-size: $font-12;
	font-weight: bold;
	padding-top:5px;

	input[type="checkbox"] {
		margin-top: 2px;
		margin-left: 7px;
		width: 16px;
		height: 16px;

		&:not(:checked) {
			background: $white;
		}
	}

	label {
		padding: 8px 4px;
		position: relative;
		top: -4px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 220px;
		float: right;
		color: $grey-300d;
	}
}

.CpnPuls {
	//height: 68px;
	height: auto;
	clear: both;
	//padding-top: 5px;
	overflow: hidden;

	.btnCoupon {

		&.three {
			font-size: $font-11;
			line-height: 17px;
			margin-top: 8px !important;
		}

		&.two {
			font-size: $font-13;
			line-height: 17px;
			margin-top: 8px !important;
		}

		&.dx {
			@include dvu-button-green-large-s();
			width: 120px;
			float: right;
			text-transform: capitalize;

			&.disabled {
				opacity: 0.5;
				display: table-cell;
				vertical-align: middle;
				font-size: $font-9;
				text-transform: initial;
				line-height: 12px;
			}

			&.two[id$='lnkAvanti'] {
				width: 100%;
			}

			img {
				display: none;
			}
		}

		&.sx {
			@include dvu-button-yellow-large-s();
			background: none;
			color: $grey-300;
			width: 246px;
			/*position: absolute;
			margin-top: 46px;*/
			text-transform: capitalize;

			img {
				display: none;
			}
		}

		&.mx {
			@include dvu-button-large-s();
			width: 120px;
			text-transform: capitalize;

			img {
				display: none;
			}
		}
	}

	.TxtBox {
		height: 14px;
		width: 80px;
		line-height: 14px;
		font-size: $font-11;
		border: 1px solid #969696;
		margin: 0 3px 0 0px;
		float: left;
	}
}

.CpnPulsBonus {
	padding: 0;

	.btnCoupon {
		&.Bonus {
			@include dvu-button-green-small-l();
			width: 246px;
			text-transform: capitalize;
			margin-top: 8px;

			&:hover {
				color: $white;
			}
		}
	}
}

.CouponMainAccepted {
  height: auto;
  overflow: hidden;
  background-repeat: repeat-y;
  margin: 0;
  font-size: $font-11;
  border: 0;
  color: $white;
  background-color: $grey-300d;
  padding: 16px 6px 16px 6px;

  &.CpnPuls {
    height: auto;
    overflow: hidden;
    margin-top: -14px;

    .savedBetsTitle {
      color: $white;
      position: relative;
      padding-left: 20px;
      height: 16px;
      line-height: 16px;
      font-size: $font-14;
      margin-bottom: 12px;
      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: $white;
        mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/betslip.svg');
        -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/betslip.svg');
        mask-size: 16px;
        -webkit-mask-size: 16px;
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
      }
    }

    .btnCoupon {
      &.dx {
        @include dvu-button-large-s();
        width: 120px;
      }

      &.sx {
        @include dvu-button-green-large-s();
        width: 120px;

        > span {
          display: inline;
          vertical-align: middle;
          line-height: 18px;
          padding-bottom: 0;
        }
      }
    }

    input[id$="btnScoAncora"] {
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize !important;
      padding: 10px !important;
      cursor: pointer;

      &.btnCenter {
        @include dvu-button-green-large-m();
        display: block;
        margin: 0px auto;
        width: 170px !important;
        font-size: 14px;
        letter-spacing: 0.28px;
        line-height: 17px;
        text-align: center;
      }
    }

    span {
      color: $grey-100d;
      padding: 0px 6px 16px;
      line-height: 1.0;

      strong {
        color: $white;
        font-weight: normal;
      }

      b {
        color: $white;
        display: block;
        padding-top: 14px;
        font-size: $font-16;
      }

      .codiceBlock {
        color: $white;
        font-size: $font-24;

        .coddItem {
          font-size: $font-24;
          color: $white;
          margin: 6px 0px;
          padding: 0px;
        }
      }

      &:not(.centerText) {
        text-align: center;
      }
    }

    a {
      span {
        color: $white;
        vertical-align: baseline;
        padding: 0px;
      }
    }

    strong {
      &.codiceBlock {
        display: block;
        margin-bottom: -8px;
        font-size: 30px;
        line-height: 33px;
        padding-bottom: 0;
        display: grid;
        grid-template-columns: max-content;
        justify-content: center;
        gap: 6px;
        padding: 6px;

        .coddItem {
          background-color: rgba($grey-200d,0.3);
          border-radius: 6px;
          padding: 6px;
          margin: unset;
          line-height: 24px;

          &::before {
            content: "";
            width: 16px;
            height: 16px;
            background-color: $white;
            -webkit-mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/copy.svg');
            mask-image: url('../../Images/icons/pw365-iconpack/current/system/regular/o/copy.svg');
            -webkit-mask-size: 16px;
            mask-size: 16px;
            mask-position: center;
            -webkit-mask-position: center;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            margin: 0 0 0 4px;
            float: right;
            position: relative;
            transform: translateY(-50%);
            top: 50%;
          }

          &:hover {
            color: $grey-100d;

            &::before {
              background-color: $grey-100d;
            }
          }
        }
      }
    }
  }

  .btnCoupon {
    &.sx {
      @include dvu-button-green-large-s();
      width: 120px;

      > span {
        display: table-cell;
        vertical-align: middle;
        line-height: 18px;
        padding-bottom: 0;
        color: $white;
      }
    }

    &.dx {
      @include dvu-button-large-s();
      width: 120px;
      float: right;
    }
  }

  .addCpnSaveTxt {
    display: block;
    padding: 0;
    margin: 0;
  }

  span {
    display: block;
    padding-bottom: 10px;
    letter-spacing: 0.17px;
  }

  a {
    margin: 2px auto 0 auto;
    display: block;
  }

  input {
    margin: 2px auto 0 auto;
    display: block;
  }

  img {
    float: left;
  }
}

.btnCoupon {
	&:disabled, &[disabled], &:disabled:hover, &[disabled]:hover {
		color: rgb(109, 109, 109);
		background: rgb(165, 163, 163) !important;
		border: 1px solid rgb(109, 109, 109) !important;
		cursor: default;
	}
}

.buttonRollIn {
	&[id$="btnPrintCopia"] {
		&:disabled, &[disabled], &:disabled:hover, &[disabled]:hover {
			color: rgb(109, 109, 109);
			background: rgb(165, 163, 163) !important;
			border: 1px solid rgb(109, 109, 109) !important;
			cursor: default;
		}
	}

	&[id$="btnPrint"] {
		&:disabled, &[disabled], &:disabled:hover, &[disabled]:hover {
			color: rgb(109, 109, 109);
			background: rgb(165, 163, 163) !important;
			border: 1px solid rgb(109, 109, 109) !important;
			cursor: default;
		}
	}
}

.divCpnTipoRic {
	padding: 0 0 0 8px;
	font-size: $font-12;
	font-family: open_sansbold, Tahoma, Arial;
	text-align: left;
	margin: 0;
	height: 21px;
	line-height: 22px;
	text-transform: uppercase;
	color: #000000;
	background-repeat: repeat-x;
	background-color: #ecad19;
	background-position: 0 0px;
	overflow: hidden;

	&:hover {
		cursor: default;
	}
}

.divCpnTipoRicCnt {
	padding: 2px 9px 2px 9px;
	background-color: #ecad19;

	.ScommRisTRAItem,
	.ScommRisTRItem {
		height: 18px;
		border-top: 1px solid #a87c05;
	}

	.ScommRisTRItem {
		&:first-child {
			border: 0;
		}
	}

	a {
		text-decoration: none;
		display: block;
		line-height: 18px;
		font-weight: bold;
		color: #000000;

		&.sx {
			float: left;
		}

		&.dx {
			float: right;
		}
	}
}

.ScommRisTRItem,
.ScommRisTRAItem {
	color: #000;

	td {
		border-top: 1px solid #a87c05;

		img {
			margin-top: 2px;
		}
	}

	&:first-child {
		td {
			border: 0;
		}
	}
}

.ScommRisTRItem {
	height: 18px;
}

.ScommRisTRAItem {
	height: 18px;
}

.divCouponError,
.divCouponErrorGen {
	z-index: 100;
	@include dvu-notificationMess-warning();
	margin: 0px 6px 10px 6px;
	font-size: $font-11;
	line-height: 1.27;
	letter-spacing: 0.17px;

	span {
		display: inline !important;
	}
}

.divCouponErrorGen {
	margin: 8px 6px;
	padding: 9px 7px 9px 45px;
	border: 1px solid #ecad19;
	border-radius: 0;

	.fillUp {
		text-decoration: none;
		color: #CAC4FF;
	}

	span {
		span {
			display: block;
		}
	}
}

.divCouponError {
	margin: 0px 0px 10px 0px;
}

.RiquadroDXUnderCpn {
	margin-bottom: 6px;
	margin-top: 10px;

	.TopSX {
		height: 23px;
		line-height: 23px;
		padding-left: 8px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		background-position: center -225px;
		background-repeat: no-repeat;
	}

	.TopDX {
		height: 23px;
		line-height: 23px;
		position: relative;
		overflow: hidden;
		color: #161616;
		font-weight: normal;
		letter-spacing: 0px;
		font-size: $font-15;

		h3 {
			padding: 0;
			margin: 0;
			font-family: open_sansbold, Tahoma, Arial;
			font-size: $font-13;
			text-transform: uppercase;
			font-weight: normal;
		}
	}

	.CntSX {
		background-color: #ecad19;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.BtmSX {
		height: 0px;
		background-color: #ecad19;
	}
}

.divScommRis {
	margin: 0;
	padding: 2px 5px 5px 5px;
	font-size: $font-11;

	.Accettato {
		color: #000000;
		font-weight: bold;
		height: 25px;
	}
}

.divScommRis,
.divScommRic {
	a{
		text-decoration: none;
		color: #000;
		font-weight: bold;
	}
}

.ScoRisParzialeA,
.ScoRisParziale {
	.tdParziale {
		background-color: #ffd457;
		padding: 5px;
		border: 1px solid #b40c0b;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top: 0;
		color: #000000;
	}
}

.cancCoupon {
	float: right;
	margin: 1px 7px 0 0;
}

.stampaCoupon {
	a {
		&:link {
			float: left;
			text-decoration: none;
			color: #000000;
		}

		:visited {
			float: left;
			text-decoration: none;
			color: #000000;
		}

		:hover {
			float: left;
			text-decoration: none;
			color: #666666;
		}
	}
}

.btnRosso {
	float: right;
	padding-top: 1px;
}

.divDisclaimerBonus {
	background-color: #ffd155;
	color: #000000;
	margin: 3px;
	border: 1px solid #c39007;
	font-size: $font-11;
	padding: 3px;
}

.cpnCassa {
	background-color: #faeeb5;
}

.divCpnSelectAnagrafica {
	input {
		float: left;
		margin-right: 4px;
	}

	.Titolo {
		margin-top: 10px;
		text-align: center;
		font-weight: bold;
		border-bottom: 2px solid #f2b100;
	}

	.Info {
		padding: 2px 0 5px 0;
		text-align: center;
	}

	.txtIDAnagrafica {
		font-size: $font-11;
		width: 40px;
	}

	.txtAnagrafica {
		font-size: $font-11;
		width: 90px;
	}

	.btnAnagrafica {
		margin-right: 2px;
		background-position: -20px top;
		display: block;
		width: 20px;
		height: 20px;
		float: left;
	}

	.btnStampa {
		display: none;
		width: 20px;
		height: 20px;
		float: left;
	}
}

.buttonStampaAnagraficaCoupon {
	background-repeat: repeat-x;
	background-position: center bottom;
	background-color: #4d4d4d;
	color: #FFFFFF;
	border: 1px solid #000000;
	font-weight: bold;
	padding: 0 10px 0 10px;
	height: 20px;
	font-size: $font-11;
	margin: 0 2px 0 2px;
	cursor: pointer;
	width: 128px;
	line-height: 17px;
	text-align: center;
}

.divCouponAsync {
	min-height: 40px;
	margin: 0 0 10px 0;
	background-repeat: no-repeat;
	background-position: 7px 7px;
	padding: 5px 5px 30px 45px;
	color: #000;
	position: relative;
	overflow: hidden;
	background-color: #ffd155;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;

	> div {
		clear: both;
		position: absolute;
		width: 100%;
		bottom: 6px;
		text-align: center;
		left: 0;
	}
}

.centerText {
	text-align: center;
}

.imgButtonCoupon {
	padding: 3px 4px 0 7px;
	vertical-align: top;
	text-align: center;
	height: 13px;
}

.imgButtonCouponSave {
	background-repeat: no-repeat;
	background-position: 50% 5%;
}

.divAnimazioneLiveHTML {
	display: block;
	width: 198px;
	height: 100px;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #ECAD19;
}

.deposNowBtnCoupon {
	@include dvu-button-green-large-s();
	width: 120px;
	margin: 8px auto;
	display: block;
}

.lnkRefresh {
	display: none;
}

.depositNowBtn {
	&.divCpnTipoCnt {
		&.CpnPuls {
			border-radius: 4px;
			display:block;
			margin-top:6px;

			span {
				display: block;
				margin: 5px auto;
				text-align: center;
				font-size: $font-11;
				width: 95%;
			}

			.lblDepositNowCouponTitle {
				width: 187px;
				font-weight: bold;
				color: #000000;
				line-height: 15px;
			}
		}
	}
}

#spanImporto {
	padding-right: 5px;
}

#spanImportoSis {
	padding-right: 0px;
}

div[id$="trImportoTotaleDI"],
div[id$="trImportoDI"] {
	&.CpnTipoRiep {
		.RiepDX {
			right: 4px;
		}
	}
}

.secs9 {
	-webkit-animation-delay: -1s !important;
	animation-delay: -1s !important;
}

.secs8 {
	-webkit-animation-delay: -2s !important;
	animation-delay: -2s !important;
}

.secs7 {
	-webkit-animation-delay: -3s !important;
	animation-delay: -3s !important;
}

.secs6 {
	-webkit-animation-delay: -4s !important;
	animation-delay: -4s !important;
}

.secs5 {
	-webkit-animation-delay: -5s !important;
	animation-delay: -5s !important;
}

.secs4 {
	-webkit-animation-delay: -6s !important;
	animation-delay: -6s !important;
}

.secs3 {
	-webkit-animation-delay: -7s !important;
	animation-delay: -7s !important;
}

.secs2 {
	-webkit-animation-delay: -8s !important;
	animation-delay: -8s !important;
}

.secs1 {
	-webkit-animation-delay: -9s !important;
	animation-delay: -9s !important;
}

.secs0 {
	-webkit-animation-delay: -10s !important;
	animation-delay: -10s !important;
}

@-webkit-keyframes counter10secs {
	0% {
		background-position: center 0px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	1% {
		opacity: 1
	}

	4% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	5% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	6% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	8.5% {
		background-position: center 0px;
		opacity: 0
	}

	9% {
		background-position: center -54px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	10% {
		opacity: 1
	}

	13% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	14% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	15% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	17.5% {
		background-position: center -54px;
		opacity: 0
	}

	18% {
		background-position: center -108px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	19% {
		opacity: 1
	}

	22% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	23% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	24% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	26.5% {
		background-position: center -108px;
		opacity: 0
	}

	27% {
		background-position: center -162px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	28% {
		opacity: 1
	}

	31% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	32% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	33% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	35.5% {
		background-position: center -162px;
		opacity: 0
	}

	36% {
		background-position: center -216px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	37% {
		opacity: 1
	}

	40% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	41% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	42% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	44.5% {
		background-position: center -216px;
		opacity: 0
	}

	45% {
		background-position: center -270px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	46% {
		opacity: 1
	}

	49% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	50% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	51% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	53.5% {
		background-position: center -270px;
		opacity: 0
	}

	54% {
		background-position: center -324px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	55% {
		opacity: 1
	}

	58% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	59% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	60% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	62.5% {
		background-position: center -324px;
		opacity: 0
	}

	63% {
		background-position: center -378px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	64% {
		opacity: 1
	}

	67% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	68% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	69% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	71.5% {
		background-position: center -378px;
		opacity: 0
	}

	72% {
		background-position: center -432px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	73% {
		opacity: 1
	}

	76% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	77% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	78% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	80.5% {
		background-position: center -432px;
		opacity: 0
	}

	81% {
		background-position: center -486px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	82% {
		opacity: 1
	}

	85% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	86% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	87% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	89.5% {
		background-position: center -486px;
		opacity: 0
	}

	90% {
		background-position: center -540px;
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 0
	}

	91% {
		opacity: 1
	}

	94% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8)
	}

	95% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	96% {
		-webkit-transform: scale(0.8,0.8);
		transform: scale(0.8,0.8);
		opacity: 1
	}

	98.5% {
		background-position: center -540px;
		opacity: 0
	}

	100% {
		opacity: 0
	}
}

@keyframes counter10secs {
	0% {
		background-position: center 0px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	1% {
		opacity: 1
	}

	4% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	5% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	6% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	8.5% {
		background-position: center 0px;
		opacity: 0
	}

	9% {
		background-position: center -54px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	10% {
		opacity: 1
	}

	13% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	14% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	15% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	17.5% {
		background-position: center -54px;
		opacity: 0
	}

	18% {
		background-position: center -108px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	19% {
		opacity: 1
	}

	22% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	23% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	24% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	26.5% {
		background-position: center -108px;
		opacity: 0
	}

	27% {
		background-position: center -162px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	28% {
		opacity: 1
	}

	31% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	32% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	33% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	35.5% {
		background-position: center -162px;
		opacity: 0
	}

	36% {
		background-position: center -216px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	37% {
		opacity: 1
	}

	40% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	41% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	42% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	44.5% {
		background-position: center -216px;
		opacity: 0
	}

	45% {
		background-position: center -270px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	46% {
		opacity: 1
	}

	49% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	50% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	51% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	53.5% {
		background-position: center -270px;
		opacity: 0
	}

	54% {
		background-position: center -324px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	55% {
		opacity: 1
	}

	58% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	59% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	60% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	62.5% {
		background-position: center -324px;
		opacity: 0
	}

	63% {
		background-position: center -378px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	64% {
		opacity: 1
	}

	67% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	68% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	69% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	71.5% {
		background-position: center -378px;
		opacity: 0
	}

	72% {
		background-position: center -432px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	73% {
		opacity: 1
	}

	76% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	77% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	78% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	80.5% {
		background-position: center -432px;
		opacity: 0
	}

	81% {
		background-position: center -486px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	82% {
		opacity: 1
	}

	85% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	86% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	87% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	89.5% {
		background-position: center -486px;
		opacity: 0
	}

	90% {
		background-position: center -540px;
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 0
	}

	91% {
		opacity: 1
	}

	94% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9)
	}

	95% {
		-webkit-transform: scale(1,1);
		transform: scale(1,1)
	}

	96% {
		-webkit-transform: scale(0.9,0.9);
		transform: scale(0.9,0.9);
		opacity: 1
	}

	98.5% {
		background-position: center -540px;
		opacity: 0
	}

	100% {
		opacity: 0
	}
}

.DIQ {
	display: none;
}
