form[formstyle="all-bets"] #divMain {
  margin: 0;
  padding: 0;

  .title {
    margin: 16px 16px 20px 16px;
    font-size: 24px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px 16px 16px;

    > a {
      display: flex;
      align-items: center;
      position: relative;
      height: 42px;
      font-weight: 500;
      padding: 0 16px 0 44px;
      background-color: $transparent;
      color: $white;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 17px;
        width: 20px;
        height: 100%;
        background-color: $grey-100;
        mask-image: url('../../images/icons/pw365-iconpack/current/sport/regular/o/all-sports.svg');
        mask-size: 20px;
        mask-position: center;
        mask-repeat: no-repeat;
      }

      &[id$="lnkSport"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/sport/cond/o/soccer.svg');
      }

      &[id$="lnkCasino"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/casino/cond/o/casino.svg');
      }

      &[id$="lnkSkill"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/casino/cond/o/skill.svg');
      }

      &[id$="lnkPoker"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/casino/cond/o/poker.svg');
      }

      &[id$="lnkIppica"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/ippica.svg');
      }

      &[id$="lnkVirtual"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/casino/cond/o/virtuals.svg');
      }

      &[id$="lnkTotocalcio"]::before {
        mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/totocalcio.svg');
      }

      &:hover {
        background-color: $yellow-300 !important;
        color: $black;

        &::before {
          background-color: $black;
        }
      }

      &.selected {
        background-color: $yellow-400;
        color: $black;

        &::before {
          background-color: $black;
        }
      }
    }
  }

  iframe {
    padding: 12px;
    border: none;
    width: 100%;
    height: 464px;
    box-sizing: border-box;
  }
}
