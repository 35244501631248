﻿.jspContainer {
	overflow: hidden;
	position: relative;

	.jspPane {
		position: absolute;
	}
}

.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: 2px;
	height: 100%;
	background: $grey-200d;

	.jspCap {
		display: none;
	}

	.jspTrack {
		background: $grey-100d;
		position: relative;

		.jspDrag {
			background: $yellow-100d;
			position: relative;
			top: 0;
			left: 0;
			cursor: pointer;
		}
	}

	.jspArrow {
		height: 16px;
	}
}

.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: $grey-200d;

	.jspCap {
		float: left;
	}

	.jspTrack,
	.jspDrag {
		float: left;
		height: 100%;
	}

	.jspArrow {
		width: 16px;
		float: left;
		height: 100%;
	}
}

.jspArrow {
	background: #50506d;
	text-indent: -20000px;
	display: block;
	cursor: pointer;
	padding: 0;
	margin: 0;

	&.jspDisabled {
		cursor: default;
		background: #80808d;
	}
}

.jspVerticalBar .jspArrow:focus {
	outline: none;
}

.jspCorner {
	background: #eeeef4;
	float: left;
	height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
	margin: 0 -3px 0 0;
}
