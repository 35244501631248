﻿.ValidationSummary {
	padding-top: 10px;

	UL {
		margin: 0 25px;
		padding: 0;
		color: $white;
	}
}

.dvuValSummary {
	@include dvu-message-style ($font-12);
	background-color: $red-100d;	
	padding: 9px 16px 7px 12px;	

	&:before {
		content: '';
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
		background-color: $red-300d;
		width: 22px;
		height: 22px;
		top: 6px;
		left: 16px;
		position: absolute;
	}

	UL {
		color: $black;
		margin-left: 37px;
		margin-right: 0px;

		li {
			text-align: left;
			padding-bottom: 2px;
		}
	}
}

.validation-error {
	color: $white !important;
	font-size: $font-11;
}

.BtmSx {
	display: none;
}

.TitleHeader {
	@include dvu-titlebar();
	text-align:center;
}

#MainContent, div[id$='updCPH'] {
	.Riquadro, .RiquadroGuest, .RiquadroNews, .RiquadroSrc {

		.TopSX {
			h3 {
				@include dvu-titlebar();
				text-align: center;
				padding-left: 0;
			}
		}

		.CntSX, .Cnt {

			@media screen and (max-width: 1560px) {
				font-size: $font-12;
			}

			div[id$='panForm'] {
				background-color: $grey-300d;
				border-radius: 6px;
				padding: 16px 21px 12px 19px;
			}

			.SearchTitleStyle {
				display: none;
			}

			.SearchButtonsStyle {
				margin: 12px 0;
				width: 100%;

				td {
					padding: 0 8px;

					&.tdSrcDX {
						text-align: right;
					}

					&.tdSrcCX {
						text-align: center;
					}
				}

				td:not(.tdSrcSX):not(.tdSrcDX):not(.tdSrcCX) {
					display: none;
				}
			}

			.SearchContainerStyle {
				width: 100%;

				.SearchDescStyle {
					width: 250px;
					border-bottom: 1px solid $grey-200d;

					@media screen and (max-width:1750px) {
						width: 200px;
					}

					@media screen and (max-width: 1560px) {
						width: 180px;
					}

					> div {
						width: 100%;

						div {
							float: right;
							padding-right: 5px;
						}
					}

					div {
						display: inline-block;
					}
				}

				.SearchControlsStyle {
					border-bottom: 1px solid $grey-200d;
					padding: 1px 0 3px;

					input[type="checkbox"] {

						+ label {
							padding-left: 3px;
							line-height: 16px;
						}
					}
				}

				&#tblSearch {

					.textbox {
						max-width: 126px;
						width: 100%;
					}

					tr:last-child {
						.SearchDescStyle, .SearchControlsStyle {
							border: none;
						}
					}
				}

				.SearchControlsContainerStyle {
					width: 100%;
				}
			}

			#tblSearch2 {
				margin-top: 12px;
				background-color: transparent;
				padding: 0;
				font-size: $font-14;

				.SearchSectionStyle {
					height: 27px;
				}
			}
		}
	}

	.divDg, .groupsDivMain {
		margin-top: 12px;
		font-size: $font-12;

		.dgStyle {
			tr {
				height: 32px;

				&.dgAItemStyle {
					background-color: rgba($white, 0.05);
				}

				&.dgHdrStyle {
					background-color: rgba($white, 0.05);
					color: $white;
				}

				th a,
				td a {

					&:hover {
						color: $yellow-200d;
					}
				}

				a.dvuAnulla,
				a.tdSearch {
					background-color: $grey-150d;
					width: 16px;
					height: 16px;
					display: block;
					margin-left: 12px;

					&:hover {
						background-color: $grey-100d;
					}
				}

				a.tdSearch {
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
				}

				&.Void {
					a.dvuAnulla {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
						-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/search.svg');
						margin-left: 16px;
					}
				}

				&.dgAItemStyle, &.dgItemStyle {
					a.dvuAnulla {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/recycle-bin.svg');
						--webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/recycle-bin.svg');
					}
				}

				&.dgPagerStyle {
					background-color: rgba($white, 0.05);
					height: 48px;

					table {
						background-color: $grey-300d;
						border-collapse: collapse;
						margin-left: 7px;

						tr {
							height: 24px;

							td {
								a {
									text-decoration: none;
									font-weight: bold;
									border: 1px solid $white;
									display: block;
									padding: 0 3px 0 3px;

									&:hover {
										background-color: #ffffff;
										color: #54585e;
									}
								}
							}
						}
					}

					div {
						border-top: 1px solid $grey-150d;
						height: 24px;
						padding: 5px;
						font-weight: bold;
					}

					td span {
						text-decoration: none;
						color: $black;
						font-weight: bold;
						border: 1px solid $white;
						display: block;
						padding: 0 3px 0 3px;
						background-color: $yellow-300d;
					}
				}
				//odds search

				.ricercaSE {
					padding: 0 0 6px 12px;
					width: 100%;

					.ricercaEevento {
						color: $yellow-200d;
						line-height: 22px;
					}

					a {
						font-size: $font-14;

						&:hover {
							color: $yellow-200d;
						}
					}
				}

				.ricercaData {
					padding: 0 12px 0;
					white-space: nowrap;
				}

				&.dgEmptyStyle {
					text-align: center;
				}
			}

			.dgTotalsStyle {
				TD {
					border-bottom: 1px dashed #444;
				}

				.dgTotalsImpPos {
					color: $green-200d;
					text-align: right;
					padding: 0 5px;
				}

				.dgTotalsImpNeg {
					color: $red-200d;
					padding: 0 5px;
					text-align: right;
				}
			}
		}
	}
}

.tblTabs {
	td, >ul>li {
		min-width: 195px;
		height: 28px;
		text-align: center;
		vertical-align: bottom;
		font-weight: 500;

		a {
			line-height: 24px;
			border: 1px solid $grey-200d;
			width: 100%;
			display: inline-block;
			color:$white;
		}

		&.cellaSel, &.sel {
			border-radius: 6px 6px 0 0;
			background-color: $yellow-400d;
			color: black;
			padding-bottom: 2px;

			a {
				background: transparent;
				border: none;
			}
		}
	}
}