.mainlanding {
	.langlist {
		.Lang_content_wrapper {
			background: transparent;
			border: 0;
			border-radius: 6px;
			box-shadow: none;
			height: 36px;
			margin: 0 6px;
			padding: 0 5px;
			position: absolute;
			top: -54px;
			width: 60px;

			.languageFlags {
				float: left;
				width: 50px;

				div {
					height: 26px;
					line-height: 26px;
					padding: 5px 0;
					position: relative;
					text-align: left;
					width: 50px;
					z-index: 2;

					a {
						background: transparent;
						color: $white;
						cursor: pointer;
						font-family: $roboto;
						font-size: $font-14;
						font-weight: normal;
						height: 26px;
						left: inherit;
						letter-spacing: 0.28px;
						line-height: 26px;
						text-transform: capitalize;
						top: inherit;
						padding: 0 0 0 30px;
						position: relative;
						width: 20px;

						&:before {
							background-size: cover;
							border-radius: 4px;
							border: solid 1px #9fa1a5;
							content: '';
							display: block;
							height: 24px;
							left: 0;
							overflow: hidden;
							position: absolute;
							top: 0;
							width: 24px;
						}
					}

					&.en-GB {
						a {
							&:before {
								background: url('../../images/icons/pw365-iconpack/current/flags/gb.svg');
							}
						}
					}

					&.de-DE {
						a {
							&:before {
								background: url('../../images/icons/pw365-iconpack/current/flags/de.svg');
							}
						}
					}

					&.fr-FR {
						a {
							&:before {
								background: url('../../images/icons/pw365-iconpack/current/flags/fr.svg');
							}
						}
					}

					&.hr-HR {
						a {
							&:before {
								background: url('../../images/icons/pw365-iconpack/current/flags/hr.svg');
							}
						}
					}

					&.Sel {
						a {
							overflow: initial;
							pointer-events: none;
							text-overflow: inherit;
							width: 20px !important;
						}
					}
				}
			}

			#btnLangList {
				display: block;
				height: 0;
				left: inherit;
				line-height: 36px;
				position: absolute;
				right: 0;
				top: 0;
				width: 16px;
				z-index: 3;

				a {
					cursor: pointer;
					display: block;
					height: 36px;
					left: inherit;
					line-height: 8px;
					padding: 0;
					position: absolute;
					right: 0;
					top: 0;
					width: 16px;
					z-index: 3;
				}

				&_link {
					&:after {
						@include transform(rotate(45deg) translateY(-50%) !important);
						border: solid $white;
						border-width: 0 2px 2px 0;
						bottom: inherit;
						content: '';
						height: 4px;
						left: inherit;
						position: absolute;
						right: 8px;
						top: 50%;
						width: 4px;
					}
				}
			}

			&.big {
				background: $white;
				height: auto;

				#btnLangList {
					right: 4px;

					a {
						&#btnLangList_link {
							left: 4px;
							z-index: 1;

							&:after {
								border-width: 2px 0 0 2px;
								border-color: #3a3e45;
							}
						}
					}
				}

				.languageFlags {
					div {
						height: 26px;
						line-height: 26px;
						opacity: .8;
						position: relative;
						z-index: 2;

						a {
							color: #3a3e45;
							display: block;
							height: 26px;
							position: relative;
							left: inherit;
							top: inherit;
							z-index: 2;
						}

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
