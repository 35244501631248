﻿.RiquadroDXUnderCpn {
	.TopSX {
		height: auto;
		padding: 0;
	}

	.TopDX {
		height: auto;

		h3 {
			@include dvu-titlebar();
		}
	}

	.CntSX {
		background-color: $yellow-400d;

		.divScommRis {
			padding: 0 6px;

			table, tbody, tr, td, span, a {
				margin: 0;
				padding: 0;
				border: 0;
				font-size: 100%;
				vertical-align: baseline;
			}

			.OddsDetailsSottoEventi {
				border-collapse: collapse;

				tr:not(:first-child) {
					border-top: 0.5px solid rgba($grey-200d,0.4);
				}
			}
		}

		.ScommRisTRItem, .ScommRisTRAItem {
			height: 24px !important;
			line-height: 16px;

			a {
				font-weight: normal;
				line-height: 16px;
				display: inline-block;
			}

			td {
				border: none !important; //DVUDelete remove td border from betslip.scss
				vertical-align: middle;
			}

			.dvuClose {
				a {
					display: block;
				}
			}

			.SRIDCpn {
				padding-left: 2px;
			}

			.scoBtnClose {
				width: 16px;
				height: 16px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
				background-color: $grey-200d;
				display: block;
			}

			.scoBtnClose, .cpnStatus {
				margin-top: -2px;
			}
		}

		.ScoRisParziale,
		.ScoRisParzialeA {
			.tdParziale {
				border-radius: 6px;
				padding: 0;
				border: none;
				background-color: transparent;
				padding-top: 7px;

				.buttonAccept {
					width: 120px !important;
					margin-right: 7px !important;
				}

				.buttonCancel {
					width: 120px !important;
				}

				table, tbody {
					display: block;
				}

				tr {
					display: inline-block;
					width: 100%;
					font-size: $font-12;
					border: none !important;

					&:not(.scoButtons) {
						background-color: $white;
						height: 18px;

						td {
							padding: 0 6px;
							display: inline-block;
						}

						td:last-child:not(:first-child) {
							float: right;
							font-size: $font-14;
						}
					}

					&:first-child {
						border-radius: 6px 6px 0 0;
						min-height: 20px;
						height: auto;

						td {
							padding-top: 6px;
							width: 100%;
							font-size: $font-11;
						}
					}

					&.dvuScoLast {
						border-radius: 0 0 6px 6px;
					}

					&.scoButtons {
						padding: 12px 0 10px;
					}
				}
			}
		}
	}
}

.cpnStatus {
	display: block;
	width: 12px;
	height: 12px;
	border: 1px solid $white;
	border-radius: 3px;

	&.type0, &.type3 {
		background-color: $red-300d;
	}

	&.type1 {
		background-color: $green-300d;
	}

	&.type2 {
		background-color: $yellow-300d;
	}

	&.type4 {
		background-color: $grey-200d;
	}
}
