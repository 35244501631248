﻿#LWMainItems.HomeDXContent {

	.LWItem,
	.LWAItem {
		padding: 6px 0px;
		height: 45px;
		border-bottom: 1px solid $grey-200d;

		&:last-of-type {
			border: none;
			padding-bottom: 0px;
			height: 40px;
		}

		&:first-of-type {
			padding-top: 0px;
			height: 40px;
		}

		.LWUser, .LWData {
			font-weight: 500;
			float: left;
			width: 40%;
		}

		.LWPlay, .LWImporto {
			float: right;
			font-weight: 500;
		}

		.LWUser {
			a {
				&:hover {
					color: $yellow-200d;
				}
			}
		}

		.LWPlay {
			font-weight: normal;
			font-size: $font-12;

			.LWPlayTxt {
				margin-right: 6px;
				font-weight: 500;
				font-size: $font-11;
			}
		}

		.LWData {
			font-size: $font-12;
			color: $blue-100d;
		}

		.LWImporto {
			color: $yellow-200d;

			.LWImportoTxt {
				margin-right: 6px;
				font-size: 11px;
			}
		}

		.LWData,
		.LWImporto {

			&:hover {
				color: $white;
			}
		}
	}

	ul[id$='lnkList'] {
		color: $white;
		height: 44px;
		padding: 11px 0px 8px 0px;

		li {
			@include dvu-font-medium($font-12);
			display: inline-block;
			width: calc(100% / 3);
			max-width: 82px;
			border-top: 1px solid $grey-200d;
			border-bottom: 1px solid $grey-200d;

			&:first-child {
				border-left: 1px solid $grey-200d;
			}

			&:last-child {
				border-right: 1px solid $grey-200d;
			}

			&:nth-child(2) {

				a {
					border-left: 1px solid $grey-200d;
					border-right: 1px solid $grey-200d;
				}
			}

			a {
				display: inline-block;
				margin: 5px 0px;
				width: 100%;
				height: 100%;
				text-align: center;
			}

			&.sel {
				height: 28px;
				background-color: $yellow-400d;
				color: $black;
				font-size: 14px;
				border: 1px solid $yellow-400d;
				border-radius: 6px 6px 0px 0px;
				position: relative;
				bottom: 3px;

				a {
					border: none;
				}
			}
		}
	}
}
