﻿.dvuMessages {

	.dvu-legenda {

		span {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			display: inline-block;
			margin: 0 5px;
		}

		.dvu-priority2 {
			background-color: $green-300d;
			margin-left: 20px;
		}

		.dvu-priority1 {
			content: url('../../images/IEIcons/warning.svg');
		}
	}

	.dgStyle {
		tr {
			td {
				text-align: center;
				position: relative;
			}

			&:nth-child(odd) {
				background-color: rgba($white, 0.05);
			}

			.mp_False, .mp_True {

				&:before {
					position: absolute;
					content: "";
					width: 14px;
					height: 14px;
					border: none;
					top: calc(50% - 7px);
					left: calc(50% - 7px);
				}
			}

			.mp_False {

				&:before {
					border-radius: 50%;
					background-color: $green-300d;
				}
			}

			.mp_True {

				&:before {
					background-color: $red-300d;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
					mask-size: 14px;
					-webkit-mask-size: 14px;
					mask-position: center;
					-webkit-mask-position: center;
					mask-repeat: no-repeat;
					-webkit-mask-repeat: no-repeat;
				}
			}

			.msgDelConfirmBtn {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
				width: 20px;
				height: 20px;
				background-color: $red-300d;
				display: inline-block;

				&:hover {
					background-color: $red-200d;
					cursor: pointer;
				}
			}
		}
	}
}

.dvuReadMessages {
	background-color: $grey-300d;
	border-radius: 6px;
	padding: 16px 21px 12px 19px;
	line-height:18px;

	a {
		color: $yellow-400d;
	}
}