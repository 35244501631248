@import "visual-uplift-personal-menu-animations";

.personalMenu {
	position: relative;
	z-index: 2;
	width: auto;
	height: 45px;
	color: $grey-300d;
	font-size: $font-14;
	display: inline-block;
	top: 32px;

	.saldoTotal {
		p {
			display: inline-block;
			margin: 0;
			font-weight: normal;
		}

		&.bn-init {
			visibility: hidden;

			&.bn-init:after {
				content: '';
				display: inline-block;
				visibility: visible;
				position: absolute;
				right: 42px;
				top: 6px;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				animation: notification-loader-amount 1.5s infinite linear;
			}
		}
	}

	&__button {
		@include transition(all 0.35s ease);
		cursor: pointer;
		display: inline-block;
		font-weight: bold;
		height: 44px;
		letter-spacing: normal;
		line-height: 16px;
		min-width: 26px;
		overflow: hidden;
		padding: 0 45px 0 12px;
		position: relative;
		text-align: right;
		z-index: 2;

		&:before {
			@include horizontal-align-absolute;
			@include transition(all 0.35s ease);
			background: $grey-500d;
			content: '';
			display: inline-block;
			height: 24px;
			right: 0px;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/account.svg');
			top: 9px;
			width: 24px;
			left: auto;
		}

		span {
			@include transition(all 0.35s ease);
			display: inline-block;
			margin-top: 16px;
			line-height: 14px;
			position: relative;
			z-index: 1;
			font-size: $font-14;
		}

		.conto {
			font-weight: normal;
		}

		&__wrapper {
			text-align: right;
			display: inline-block;
		}

		&:hover {
			&:before {
				background: $grey-300d;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					mask-image: none;
					background: none;
					background-color: $transparent;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 22px;
					background-image: url('../../images/IEIcons/Account.svg');
				}
			}
		}

		&.activeActive {
			animation-delay: 0.34s;
			background: $white;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			height: 44px;
		}

		&__loader {
			display: none;
			height: 15px;
			line-height: 15px;
			position: absolute;
			top: 15px;
			width: 70%;
			right: 40px;
			z-index: 1;

			&__indicator {
				border: $grey-150d;
				border-top-color: $grey-300d;
				height: 10px;
				top: 3px;
				left: 50%;
				width: 10px;
			}
		}
	}

	&__has-items {
		&:after {
			@include transition(all 0.35s ease);
			content: '';
			border: 2px solid $yellow-400d;
			border-radius: 5px;
			background: $red-300;
			display: block;
			height: 9px;
			position: absolute;
			right: 13px;
			top: 6px;
			width: 9px;
		}

		&.activeActive {
			&:after {
				background: $transparent;
				border-color: $transparent;
			}
		}
	}

	&__body {
		@include transition(opacity, visibility 0.35s ease);
		animation-delay: 0.34s;
		background: $white;
		border-radius: 4px 0 4px 4px;
		color: $grey-300d;
		display: inline-block;
		overflow: hidden;
		padding: 3px 15px 0;
		position: absolute;
		right: 0;
		top: 44px;
		visibility: hidden;
		z-index: 1;

		&.activeActive {
			padding: 3px 15px 16px;
			visibility: visible;
			z-index: 11;
			width: 492px !important;
			height: auto !important;
		}
	}

	.hidden {
		display: none !important;
	}

	&__overlay {
		display: none;

		&.activeActive {
			background: rgba(0, 0, 0, .7);
			display: block;
			bottom: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}
}
