﻿.mainMenu .menu {
	height: 52px;

	.primaryMenu {		

		> ul > li {
			float: left;
			line-height: 53px;
			padding: 0 11px;
			position: relative;

			&:first-child {
				padding-left: 0;
			}

			a {
				@include dvu-font-regular($font-14);
				color: $white;
				opacity: 0.7;
				letter-spacing: 1px;
				text-transform: capitalize;

				@media screen and (max-width:1600px) {
					letter-spacing: normal;
				}

				&:hover {
					opacity: 1;
				}
			}

			ul {
				display: none;
				position: absolute;
				left: 0;
				top: 53px;
				z-index: 2000;
				line-height: 24px;
				background-color: rgba($grey-500d, 0.9);
				text-transform: capitalize;
				padding: 0 11px;

				li {
					white-space: nowrap;
					padding: 0px;
					float: none;
					display: block;
				}
			}

			&:hover {
				ul {
					display: block;
				}
			}

			li {
				a.menuItemSelected {
					opacity: 1;
					position: relative;
					padding-bottom: 15px;
					border-bottom: none;
				}
			}
		}
	}
}

#MainContent {
	.PromotionsVuoto {
		display: block;
		text-align: center;
		color: $grey-150d;
		padding: 12px 0 25px;
	}

	table[id$="rblVoucherStatus"] td {
		width: 100px;
	}

	div[id$="panel_VoucherDetails"] {
		.legend-item {
			display: inline-block;
		}

		table table {
			tr {
				height: 32px;

				.cellaSx {
					padding-left: 20px;
				}

				.cellaDx {
					padding-left: 7px;
				}

				&:nth-of-type(odd) {
					background-color: rgba($white, 0.03);
				}
			}
		}

		+ .SearchButtonsStyle {
			input[id$="btnBack"] {
				@include dvu-button-green-small-m();
			}
		}
	}

	.docPager {
		line-height: 32px;
		background: $grey-300d;
		margin: 12px 0 3px;
		padding: 0 8px;
		font-size: $font-12;

		.btnDocPrev,
		.btnDocNext {
			background-color: $grey-150d;
			width: 30px;
			height: 30px;
			display: block;

			&:hover {
				background-color: $grey-100d;
			}

			&[disabled="disabled"] {
				opacity: 0.6;
				pointer-events: none;
			}
		}

		.btnDocPrev {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-left.svg');
			float: left;
		}

		.btnDocNext {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-right.svg');
			float: right;
		}
	}

	.ContenitoreDocument {
		position: relative;
		padding: 3px 0;
		overflow: hidden;

		.HeaderSxDocument {
			display: inline-block;
			position: absolute;
			left: 0;

			&.TipoDoc {
				&_1, &_7, &_9, &_11, &_12 {
					bottom: 30px;
				}
			}

			img {
				width: 80px;
			}
		}

		.HeaderDxDocument {
			width: 100%;
			line-height: 16px;

			.HeaderTitoloDocument {
				padding-left: 100px;
			}

			.HeaderTestoDocument {
				padding-left: 100px;
				font-size: $font-12;
				min-height: 40px;
			}

			.downloadBtn {
				text-align: right;
				background-color: rgba($white, 0.05);
				line-height: 24px;

				a {
					padding-right: 12px;

					&:before {
						content: '';
						display: inline-block;
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/download.svg');
						-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/download.svg');
						background-color: $green-300d;
						width: 16px;
						height: 16px;
						position: relative;
						top: 2px;
						margin-right: 5px;
					}

					&:hover {
						&:before {
							background-color: $green-200d;
						}
					}
				}
			}
		}
	}

	.divBtnNewUser {
		display: none;
	}

	.backReprint {
		height: 36px !important;
		font-size: 16px;
		padding: 10px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

#popupRePrint {
	height: 250px;
	width: 400px;
	z-index: 12000;
	border-radius: 6px;
	overflow:hidden;

	.RiquadroNews,
	.RiquadroNews .pnlRePrint {
		width: 100%
	}

	#iframeRePrint{
		height:250px !important;
	}
}

.betRefuseTable,
.betRefuseMsg{
	height:200px;
}

.mainFooter {
	height: 124px;

	.footerLic{
		height:75px
	}
}

.bodyLanding{
	.header{
		display:inline-block;
	}
}

#divInserimentoScommesse {
	.CouponMainAccepted {

		.btnCoupon {
			&.sx {
				font-size: $font-12;
				height: 36px !important;
			}
		}

		&.CpnPuls {
			input[id$="btnScoAncora"] {				
				font-size: $font-12;
			}
		}
	}
}

.mainSubheader {	
	.bottom {		
		height: 53px;
	}
}

.personalMenu__body {
	&__notifications {
		margin-bottom: 30px;

		> div {
			width: 50%;
		}
	}
}