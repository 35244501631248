﻿.oddsLessThanContent {
	padding: 8px 5px;
	position: relative;

	.Title {
		@include dvu-titlebar();
		text-align: center;
	}

	#oddsLessThanProgress {
		position: absolute;
		width: 100%;
		display: none;
		top: 0;
		left: 0;
		z-index: 300;
		background-color: transparent;
		opacity: 0.7;
	}

	.oddLessThanSelector {
		margin: 26px 0 24px;
		text-align: center;
		font-size: $font-12;

		.oddsLessThanSlider {
			display: inline-block;
			margin: 0 70px;
		}

		.oddsTimeSlider {
			display: inline-block;
			margin: 0 70px;
		}
	}

	#oddsLessThanSliderHdr,
	#oddsTimeSliderHdr {
		width: auto;
	}

	#oddsLessThanSliderHdr DIV:first-child {
		margin: 0;
	}

	.oddsLessThanSports {
		margin-bottom: 8px;

		#sportsLessThan {
			list-style-type: none;
			margin: 0;
			padding: 0;

			LI {
				position: relative;
				float: left;
				@include dvu-button-small-s();
				background: $grey-300d;
				font-size: $font-11;
				width: auto;
				padding: 0;
				margin: 0 6px 6px 0;

				A {
					width: auto;
					font-weight: normal;
					line-height: 28px;
					display:inline-block;
					padding: 0 30px 0 6px;
					text-transform:capitalize;
				}

				span{
					position:absolute;
					top:8px;
					right:6px;
				}

				&.sel {
					background: $yellow-400d;
					color: $grey-300d;

					&:hover {
						background: $yellow-300d;
					}
				}
			}
		}
	}

	#oddsLessThanContent {
		clear: both;
		font-size: $font-12;

		.oddsLessThanEmpty {
			display: block;
			text-align: center;
			padding-bottom: 25px;
			color: $grey-150d;
			padding-top: 12px;
		}

		.sport {
			display: block;
			padding: 40px 0 5px 7px;
		}

		.header {
			height: 20px;
			display: block;
			background-color: rgba($white, 0.05);
			line-height: 20px;

			$oddSize: 50px;

			.headerColNum_1 {
				width: $oddSize;
				float: right;
				text-align: center;
			}

			.headerColNum_2 {
				width: calc(50px * 2);
				float: right;
				text-align: center;
			}

			.headerColNum_3 {
				width: calc(50px * 3);
				float: right;
				text-align: center;
			}

			.headerColNum_4 {
				width: calc(50px * 4);
				float: right;
				text-align: center;
			}

			.headerColNum_5 {
				width: calc(50px * 5);
				float: right;
				text-align: center;
			}

			.headerColNum_6 {
				width: calc(50px * 6);
				float: right;
				text-align: center;
			}

			.headerColNum_7 {
				width: calc(50px * 7);
				float: right;
				text-align: center;
			}

			.headerColNum_8 {
				width: calc(50px * 8);
				float: right;
				text-align: center;
			}

			.headerColNum_9 {
				width: calc(50px * 9);
				float: right;
				text-align: center;
			}

			.headerColNum_10 {
				width: calc(50px * 10);
				float: right;
				text-align: center;
			}

			.headerColNum_11 {
				width: calc(50px * 11);
				float: right;
				text-align: center;
			}

			.headerColNum_12 {
				width: calc(50px * 12);
				float: right;
				text-align: center;
			}
		}

		.oddsLessThan {

			.item {

				&:first-child {

					.odds .odd div:first-child {
						display: block;
						position: absolute;
						top: -20px;
						left: 0;
						height: 14px;
						line-height: 14px;
						border: 0;
						color: $grey-100d;
						background-image: none;
						background-color: transparent;
						overflow: hidden;
						width: 100%;
						font: $font-12;
						cursor:default;
					}
				}

				.odds {
					text-align: center;
					float: right;
					background-color: rgba($grey-200d, 0.3);
					height: 100%;

					.odd {

						&.Min DIV {
							color: $white;
							font-weight: bold;
							opacity: 1;
						}

						DIV:first-child {
							display: none;
						}

						DIV {
							cursor: pointer;
						}

						&:not(.Min) {
							DIV {
								opacity: 0.5;
							}
						}
					}
				}

				.date {
					height: 18px;
					line-height: 18px;
					white-space: nowrap;
					overflow: hidden;
					color: $blue-100d;
				}

				.time {
					height: 18px;
					line-height: 18px;
					white-space: nowrap;
					overflow: hidden;
					color: $blue-100d;
				}

				.stats {
					width: 58px;
				}

				.eventName {
					width: 700px;
					overflow: hidden;

					@media screen and (max-width: 1760px) {
						width: 500px;
					}

					@media screen and (max-width: 1420px) {
						width: 445px;
					}
				}
			}
		}
	}
}
