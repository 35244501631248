﻿.AccessLogs {

	.SearchDescStyle, .SearchControlsStyle {
		border: none !important;
	}

	.tblDateSearch {
		width: 75%;

		.dateHeader {
			width: 8%;
		}

		.tdDate{
			width:30%;
		}
	}
}
