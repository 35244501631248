.lost-password__allert {
	&.alertable {
		@include horizontal-align;
		background: $grey-900;
		border: 0;
		border-radius: 4px;
		margin: 0 auto;
		padding: 10px;
		position: fixed;
		top: 30vh;
		width: 390px;
		z-index: 9999999;

		> h2 {
			font-size: $font-16;
			font-weight: normal;
			line-height: 1.19;
			letter-spacing: 0.32px;
			text-align: center;
		}
	}

	&__message {
		color: $white;
		font-size: $font-12;
		font-weight: normal;
		margin-bottom: 20px;
		margin-top: 20px;
		text-align: center;
		word-break: break-word;

		a {
			@include transition(all 0.35s ease);
			color: $yellow-300;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__buttons {
		margin: 0;

		a {
			&.modal-reject {
				color: $grey-150;
				cursor: pointer;
				display: block;
				font-size: 20px;
				height: 14px;
				line-height: 14px;
				position: absolute;
				right: 4px;
				text-align: center;
				top: 4px;
				width: 14px;
				z-index: 100;
			}
		}
	}
}
.alertable.termsGratta {
	.alertable-message{
		h4{
			font-weight: 600;
		}
	}
}