﻿$wbMulti: wbMulti;
$subitem: __subitem;
$animationDuration: 0.25s;

.welcomeBonusMultiselectTitleWrapper {
	position: relative;
	top: 0;
}

div[id$='trWelcomeBonusMultiselectList'],
div[id$='trWelcomeBonusCasino'] {
	height: initial;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 14px;
	max-width: 630px;

	input[type="button"] {
		display: none;
	}
}

.wbMulti {
	&__item {
		display: flex;
		flex-direction: column;
		border: 1px solid $black;
		margin-bottom: 10px;
		cursor: pointer;
		overflow: hidden;
		border-radius: 24px;

		img {
			width: 100%;
			min-width: 100%;
			opacity: 0.7;
			transition: opacity 2s;
		}

		&--optout {
			border: none;

			.#{$wbMulti}#{$subitem}--textshort {
				position: relative;
				text-transform: initial;
				font-weight: normal;
				color: $white;
				font-size: $font-10;
				font-family: 'Roboto';
				line-height: 1.25;

				p {
					&:hover {
						color: $yellow-400;
					}
				}
			}

			.#{$wbMulti}#{$subitem}--textoverimage {
				min-height: 30px;
			}

			&.expanded {
				.#{$wbMulti}#{$subitem}--textshort {
					color: white;

					p {
						color: $yellow-400;
						font-size: $font-10;
					}
				}
			}
		}
	}

	&__item.expanded {
		border-color: white;

		&.transition-shrink {
			max-height: 94px;
			animation-name: acc;
			animation-duration: $animationDuration;
			animation-direction: normal;
			animation-timing-function: ease-out;
		}

		img {
			opacity: 1;
		}

		.#{$wbMulti}#{$subitem}--textshort {
			font-size: $font-32;
			line-height: 1.25;
		}
	}

	&__item.expanded > &__subitem {
		&--bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: $grey-400;

			span,
			a {
				color: $yellow-300;
				width: 100%;
				text-align: left;
				font-size: $font-10;
				line-height: 1.25;
				margin-top: 10px;
			}
		}
	}

	&__item.expanded &__subitem {

		&--multimage {
			> .#{$wbMulti}#{$subitem}--imagelarge {
				height: initial;
				transform: scaleY(100%);
			}

			> .#{$wbMulti}#{$subitem}--imagesmall {
				display: none;
				transform: scaleY(0);
			}

			> .#{$wbMulti}#{$subitem}--textshort {
				font-size: 49px;
			}
		}
	}

	&__subitem {
		&--top {
			display: flex;
			flex-direction: row;
			align-items: center;

			input[type="radio"] {
				position: absolute;
				left: 15px;
				top: initial;

				&.campagnaChoice{
					&::after{
						mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/o/success.svg");
						background-color: white;
						mask-size: 24px;
						height: 24px;
						width: 24px;
					}

					&::before{
						mask-image: none;
						mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg");
						background-color:$yellow-300d;
						mask-size: 24px;
						height: 24px;
						width: 24px;
						top:0;
						left:0
					}
				}
			}
		}

		&--imagelarge {
			display: block;
			transform: scaleY(0);
			transform-origin: top center;
			height: 0;
		}

		&--imagesmall {
			display: block;
			transform: scaleY(100%);
			transform-origin: top center;
		}

		&--bottom {
			display: none;
			padding: 10px;

			p {
				font-size: $font-10;
				line-height: 1.25;
				color: $white;
				text-align: left;
				font-family: 'Roboto';
				font-weight: normal;

				span,
				a {
					color: $yellow-300 !important;
				}
			}
		}

		&--textoverimage {
			width: 100%;
			align-items: center;
			justify-content: flex-start;
			display: flex;
			position: relative;
		}

		&--textshort {
			position: absolute;
			left: 44px;
			text-transform: uppercase;
			font-size: 29px;
			line-height: 1.25;
			font-weight: bold;
			text-align: left;

			p {
				color: $white;

				span,
				a {
					color: $yellow-300;
				}
			}
		}
	}
}

.transition-expand {
	animation-name: acc;
	animation-duration: $animationDuration;
	animation-direction: reverse;
	animation-timing-function: ease-out;
}

@keyframes acc {
	0% {
		max-height: 600px;
	}

	100% {
		max-height: 94px;
	}
}
