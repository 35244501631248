.NormalPageContent {
  background-color: $grey-300d;
  border-radius: 6px;
  padding-bottom: 12px;

  .tablePwdRecovery {
    .cellaInfo {
      background-color: $grey-200d;
      border-radius: 6px 6px 0 0;
      line-height: 16px;
      padding-left: 7px;
      margin-bottom: 12px;
      text-align: left;

      b {
        font-size: $font-14;
        line-height: 25px;
      }
    }

    .cellaSx {
      padding-top: 16px;
      text-align: right;
      width: 45%;
    }

    .cellaDx {
      padding-top: 16px;
      padding-left: 20px;

      select {
        width: 192px;
      }
    }

    td:not(.cellDx):not(.cellaSx) {
      padding-right: 12px;
    }

    div[id$='ctrlMessageError_panel_response'] {
      padding: 12px 0px 0px 12px;
    }
  }
}

div[id$="upDomandaSicurezza"] {
  .Msg {
    width: 500px;
  }

  .timeoutMessage {
    margin-top: 10px;
    width: 500px;
  }

  div[id$="resendEmailPanel"] {
    a[id$="txtResendEmailAfterSuccessRecoverPwd"] {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
