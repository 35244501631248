.personalMenu__body {
	&__notifications {
		width: 100%;
		border-bottom: 1px solid $grey-100d;
		border-top: 1px solid $grey-100d;
		height: 44px;
		margin-bottom: 15px;

		> div {
			@include transition(all 0.35s ease);
			float: left;
			line-height: 36px;
			text-align: center;
			position: relative;
			width: 33.33%;
			top: 3px;

			> span {
				display: inline-block;				
				position: relative;
				width:100%;
				height:100%;

				a {
					@include transition(all 0.35s ease);
					color: $grey-300d;
					font-size: $font-12;
					display: inline-block;
					font-weight: normal;
					letter-spacing: 0.24px;					
					position: relative;
					width:100%;
					height:100%;

					&:before {
						@include transition(all 0.35s ease);
						background: $black;
						top: 6px;
						content: '';
						display: inline-block;
						height: 18px;
						left: 26px;
						position: absolute;
						width: 18px;
					}
				}

				[id$="lblBonusCount"],
				[id$="lblNumeroMessaggi"] {
					@include mvu-counter($green-400, $white);
					background: $green-300d;
					border: 2px $white solid;
					height: 16px;
					line-height: 13px;
					min-width: 16px;
					left: 37px;
					padding: 0 3px;
					top: 0px;
					width: auto;
					font-weight: normal;
					bottom: 10px;
					z-index: 0;
				}
			}

			&:hover {
				background: $yellow-400d;
				cursor: pointer;

				span {

					[id$="lblBonusCount"],
					[id$="lblNumeroMessaggi"] {
						border: 2px $yellow-400d solid !important;
					}
				}
			}

			&[id$="myBonuses"] {
				float: left;

				a {
					&:before {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/bonus-alt.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/solid/bonus-alt.svg') no-repeat center !important;
							mask-image: none;
						}
					}

					&.empty {
						&:before {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/bonus-alt.svg');

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/bonus-alt.svg') no-repeat center !important;
								mask-image: none;
							}
						}
					}
				}
			}
		}

		&__betslip {

			span {
				a {
					&:before {
						-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/myBets.svg');
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/myBets.svg');
						-webkit-mask-position: center;
						mask-position: center;
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/myBets.svg') no-repeat center !important;
							mask-image: none;
						}
					}
				}
			}

			&:not(:first-child) {
				&:before {
					border-right: 1px solid $grey-200;
					content: '';
					height: 28px;
					position: absolute;
					top: 5px;
					left: -.5px;
				}
			}

			&:after {
				border-right: 1px solid $grey-200;
				content: '';
				height: 28px;
				position: absolute;
				top: 5px;
				right: -.5px;
			}
			
			&:hover {
				&:before {
					display: none;
				}
			}
		}

		&__messages {
			span {
				a {
					&:before {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/letter.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/solid/letter.svg') no-repeat center !important;
							mask-image: none;
						}
					}

					&.empty {
						&:before {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/letter.svg');

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/letter.svg') no-repeat center !important;
								mask-image: none;
							}
						}
					}
				}
			}
		}

		&__userlist {
			span {
				a {
					&:before {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/account.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							background: transparent url('../../images/icons/pw365-iconpack/current/system/regular/o/account.svg') no-repeat center !important;
							mask-image: none;
						}
					}
				}
			}
		}
	}
}
