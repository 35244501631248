﻿#MainContent {
	@include dvu-font-regular($font-14);
}

.LastMinutePage, #NESportCnt, #oddsLessThanContent {
	.stats {
		height: 30px;
		line-height: 36px;

		.statsbtn {
			width: 16px;
			height: 16px;
			margin: auto;
			cursor: pointer;
			margin-top: 3px;
			background-color: $grey-100d;
			mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/solid/stats.svg');
			-webkit-mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/solid/stats.svg');
			mask-size: 16px;
			-webkit-mask-size: 16px;
			mask-position: center;
			-webkit-mask-position: center;
			mask-repeat: no-repeat;
			-webkit-mask-repeat: no-repeat;

			&:hover {
				background-color: $yellow-200d;
			}
		}
	}

	.item {
		text-transform: none;
		clear: both;
		position: relative;
		height: 48px;

		&:nth-of-type(odd) {
			background: rgba($white, 0.05);
		}

		> div:not(.odds):not(.dataHeader):not(.eventHeader):not(.statsHeader) {
			display: inline-block;
			padding: 0 26px 0 16px;
			overflow: hidden;
		}

		&:first-child {
			margin-top: 30px;
		}

		.data {
			height: 18px;
			line-height: 18px;
			color: $blue-100d;
		}

		.time {
			height: 18px;
			line-height: 18px;
			color: $blue-100d;
		}

		.event {
			color: $yellow-200d;
			line-height: 24px;
			height: 24px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.subevent {
			font-size: $font-14;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 16px;
		}

		&:hover {
			.subevent {
				color: $yellow-200d;
			}
		}

		.odd {
			text-align: center;
			float: left;
			width: 50px;
			height: 48px;
			line-height: 23px;
			font-size: $font-13;
			padding: 6px;
			position: relative;

			.g0, .g1, .g3 {
				display: block;
				line-height: 42px;
				height: 100%;
			}
			/*UNICA*/
			.g0 {
				span {
					display: block;
					border-bottom: 3px solid $unica;
					line-height: 34px;
				}
			}
			/*TERNI*/
			.g3 {
				span {
					display: block;
					border-bottom: 3px solid $tripla;
					line-height: 34px;
				}
			}

			.g, .TQ {
				border: none;
				background-color: transparent;
				cursor: default;

				&:hover {
					border: none;
				}
			}

			span {
				display: block;
				line-height: 34px;
			}

			&:not(.sel) {
				.g0, .g1, .g3 {
					&:not(.TQ) {
						&:hover {
							color: $yellow-400d;
						}
					}
				}
			}

			&.sel {
				.g0, .g1, .g3 {

					span {
						color: $black;
						background-color: $yellow-400d;
						width: 36px;
					}
				}

				.g0, .g3 {
					line-height: 40px;
				}

				&:hover {
					span {
						background-color: $yellow-300d;
					}
				}

				.g1 {
					padding: 0;
				}
			}

			@media screen and (max-width:1800px) {
				width: 45px;

				&.sel {
					.g0, .g1, .g3 {
						span {
							width: 35px;
						}
					}
				}
			}

			@media screen and (max-width:1550px) {
				width: 40px;

				&.sel {
					.g0, .g1, .g3 {
						span {
							width: 28px;
						}
					}
				}
			}
		}
	}
}

#popupStats {
	display: none;
	position: fixed !important;
	top: 0px;
	left: 0px;
	width: 80%;
	height: 95%;
	max-width: 1200px;
	max-height: 800px;
	z-index: 9999999;

	.RiquadroPopRiserva {
		width: 100%;
		height: 100%;
		padding: 0;

		.Cnt {
			width: 100%;
			height: 100%;
			padding: 0px;
			border-radius: 5px;
			overflow: hidden;

			#pnl, .pnl {
				width: 100%;
				height: 100%;

				.divTitle {
					background: $grey-400d;
					text-align: left;

					h3 {
						@include dvu-titlebar();
						width: 90%;
						display: inline-block;
					}

					#popupStatsClose {
						width: 20px;
						height: 20px;
						float: right;
						background-color: $grey-150d;
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
						margin: 5px;
					}
				}
			}

			#iframeStats {
				width: 100%;
				height: calc(100% - 21px);

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-track {
					background: $grey-100d;
				}

				&::-webkit-scrollbar-thumb {
					height: 46px;
					border-radius: 8px;
					background-color: $yellow-200d;
				}
			}
		}
	}
}

#oddsAsyncLgnd {
	display: none;
	height: 42px;
}

.legendaOdds {
	font-size: $font-11;
	border-radius: 5px;

	.divLegendGiocab {

		div:first-child {
			width: 16px;
			height: 15px;
			display: inline-block;
			float: left;
			background-color: $grey-300d;

			&.g0 {
				/*UNICHE*/
				&::after {
					content: '';
					border-bottom: 2px solid $unica;
					width: 16px;
					display: block;
					position: relative;
					top: 16px;
				}
			}
			/*DOPPIE*/
			&.g3 {
				&::after {
					content: '';
					border-bottom: 2px solid $tripla;
					width: 16px;
					display: block;
					position: relative;
					top: 16px;
				}
			}
		}

		.giocabTxt {
			float: left;
			white-space: nowrap;
			line-height: 18px;
			padding: 0 16px 0 8px;
		}
	}
}

#oddsAsyncMsg_Empty {
	display: none;
	margin-top: 16px;

	.Riquadro {
		.TopDX {
			display: none;
		}
	}
}

#oddsAsyncMsg_Many {
	display: none;
}
