﻿.LiveBettingBox {
	.item {
		display: none;
		height: 75px;
		position: relative;

		&.stop {
			height: 85px;

			.Phase {
				background-color: transparent;
				color: $white;
			}
		}

		&.run{
			height:88px;
		}

		.Event {
			font-weight: 500;
			letter-spacing: 0.28px;
			width:157px;

			a {
				&:hover {
					color: $yellow-200d;
				}
			}

			.s {
				width: 10px;
				height: 23px;
				line-height: 23px;
				float: left;
				display: block;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
			}

			.c1, .c2 {
				max-width: 46%;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 23px;
				line-height: 23px;
				display: block;
				float: left;
				overflow: hidden;
			}
		}
		.Start {
			position: absolute;
			left: 10px;
			top: 37px;
		}

		.Timer {
			position: absolute;
			width: 60px;
			height: 20px;
			line-height: 1.17;
			right: 1px;
			top: 6px;
			font-size: $font-12;
			color: $blue-100;
			text-align: right;
		}

		.Result {
			position: absolute;
			width: 60px;
			height: 20px;
			right: 0;
			top: 21px;
			font-size: $font-20;
			color: $white;
			font-weight: 500;
			text-align: right;
			font-stretch: normal;
			line-height: 1.1;
			letter-spacing: 0.5px;
		}

		.Phase {
			overflow: hidden;
			white-space: nowrap;
			font-size: $font-12;
			position: absolute;
			width: 64px;
			height: 22px;
			border-radius: 4px;
			top: 23px;
			color: $black;
			background-color: $green-200d;
			padding: 4px 5px 4px 6px;
			line-height: 1.17;
			letter-spacing: 0.24px;
		}

		.BetType {
			text-align: right;
			position: absolute;
			height: 14px;
			line-height: 1.17;
			left: 88px;
			top: -1px;
			width: 57px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: 500;
			font-size: 12px;
			letter-spacing: 0.24px;
		}

		.Odds {
			clear: both;
			position: absolute;
			top: 28px;
			left: 0px;
			pointer-events: none;

			div:nth-child(2) {
				margin-left: 0px;
			}
		}

		.Odd {
			margin-top: 25px;
			margin-left: 6px;
			width: 74px;
			float: left;
			height: 24px;
			position: relative;
			border-radius: 4px;
			background-color: #54585e;
			pointer-events: initial;

			.up {
				background-color: #54585e;

				&:after {
					content: "";
					position: absolute;
					right: 2px;
					height: 0;
					border-style: solid;
					top: -3px;
					border-width: 0 8px 8px 0;
					border-color: transparent #15ae10 transparent transparent;
				}
			}

			.down {
				background-color: #54585e;

				&:after {
					content: "";
					position: absolute;
					right: 2px;
					height: 0;
					border-style: solid;
					bottom: -1px;
					border-width: 0 0 8px 8px;
					border-color: transparent transparent #cd2626;
				}
			}

			&:hover {
				background-color: $grey-150d;
			}

			&.new {
				background-color: $grey-150;
			}

			span {
				font-weight: 500;
				display: block;
				position: absolute;
				top: 5px;
				left: 8px;
				line-height: 1.15;
				color: #fff;
				width: 65px;
				font-size: $font-13;
			}

			a {
				display: block;
				position: absolute;
				right: 4px;
				top: 5px;
				padding-right: 6px;
				font-family: $roboto;
				font-size: $font-13;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.15;
				letter-spacing: 0.26px;
				width: 70px;
				text-align: right;
			}

			span,
			a {
				&:hover {
					background-color: $transparent;
				}
			}
		}

		.icon {
			display: none;
		}

		.hold {
			height: 34px;
			width: 154px;
			position: absolute;
			top: 51px;
			left: 40px;
			border: 1px solid $grey-150d;
			border-radius: 6px;
			line-height: 18px;
			text-align: right;
			z-index: 3;
			font-family: $roboto;
			color: $white;
			font-size: $font-14;
			text-transform: uppercase;
			padding-right: 33px;
			padding-top: 7px;

			&:after {
				content: '';
				display: inline-block;
				width: 22px;
				height: 22px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
				-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
				background-color: $white;
				position: absolute;
				top: 5px;
				left: 14px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					mask-image: none;
					background: none;
					background-color: transparent;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('../../images/IEIcons/info.svg');
					position: absolute;
					top: 5px;
					left: 14px;
				}
			}
		}

		.coming {
			height: 34px;
			width: 154px;
			position: absolute;
			top: 26px;
			left: 40px;
			border: 1px solid $grey-150d;
			border-radius: 6px;
			line-height: 18px;
			text-align: right;
			z-index: 3;
			font-family: $roboto;
			color: $black;
			font-size: $font-14;
			text-transform: uppercase;
			padding-right: 33px;
			padding-top: 7px;
			background-color: $white;

			&:after {
				content: '';
				display: inline-block;
				width: 22px;
				height: 22px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
				-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
				background-color: $grey-150d;
				position: absolute;
				top: 5px;
				left: 14px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					mask-image: none;
					background: none;
					background-color: transparent;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('../../images/IEIcons/info.svg');
					position: absolute;
					top: 5px;
					left: 14px;
				}
			}
		}

		.sep {
			display: none;
		}
	}

	#contentNext .item {
		height: 62px;

		.Start {
			position: absolute;
			width: 227px;
			height: 20px;
			line-height: 1.17;
			top: 5px;
			font-size: $font-12;
			color: $blue-100;
			text-align: right;
		}

		.Event {
			z-index: 10;
			position: absolute;
			top: 0;
			left: 5px;
			width: 134px;
			height: 23px;
			font-family: $roboto;
			font-size: $font-14;
			font-weight: 500;
			line-height: 1.14;
			letter-spacing: 0.28px;

			.s {
				display: inline-block;
				float: none;
				text-overflow: ellipsis;
			}

			.c1, .c2 {
				display: inline-block;
				float: none;
			}
		}
	}
}