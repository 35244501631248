﻿.autoesclusione-advanced {
	display:block;

	td {
		display:inline-block;
		width:100%;
		padding-top:16px;
	}

	.cellaInfo {
		background-color: #3a3e45;
		border-radius: 6px;
		padding: 16px 12px;
		font-size: $font-14;
		line-height: 16px;
		letter-spacing: 0.3px;

		p {
			padding: 3px 0;
		}

		h2 {
			margin: 15px 0 5px;
			font-weight: bold;
		}

		ul {
			padding-left: 15px;
			list-style: disc;
		}
	}
}

//autoexluded user home page
//DVUEnabled inline style requires !important. Delete all inline styles after full dvu release.
.dvuExcluded {
	.TopSX {
		background: none !important;
		color: $white;

		h3 {
			@include dvu-titlebar();
			color: $white !important;
		}
	}

	.CntSX {
		background: none;
		background-color: $grey-300d;
		border-radius: 6px;
		padding: 16px 12px 12px;

		table tr {
			background: none !important;
		}

		#excl_PC_ctrlAutoesclusione_ctrlMessageAutoexcluded_panel_response {
			.Msg {
				width: 850px;
			}
		}

		.dvuButtonContainer {
			padding: 12px;

			&[id$="trWithdrawal"] {
				padding-top: 24px;
			}
		}

		.autoexclusion_links {
			a {
				@include dvu-button-green-small-m();
			}
		}
	}
}

.PPSMenu_Saldo {
	margin-top: 16px;
	text-align:right;
}
