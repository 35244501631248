﻿.bodyLanding {
	background-color: $grey-400d;
	margin: 0;

	.standardLanding {
		width: 100%
	}
}

.mainlanding {
	margin: 0 auto;
	position: relative;

	.header {
		display: inline-block;
		box-sizing: content-box;
	}

	.LandingHeader {
		.LandingHeaderContent {
			.headerMenu {
				ul {
					margin-top: 16px;

					> li {
						.lbNEW {
							@include dvu-new-label;
						}
					}
				}
			}

			.headerLogin {
				a {
					@include dvu-button-green-large-s();
				}
			}
		}
	}

	.landingSection {
		.landingSectionContent {
			.landingSectionText {
				.sectionButton {
					a {
						@include dvu-button-green(42px, 160px, $font-16, 1.0, 0.32px);
						padding: 12px 0;
						margin-top: 40px;
					}
				}
			}
		}
	}

	.LandingDivPuls {
		height: 49px;
		position: absolute;
		top: 13px;
		right: 33px;
		text-align: right;
		z-index: 10;

		.it-IT, .en-GB, .sr-Latn-CS, .de-DE, .bg-BG, .fr-FR, .es-ES, .pl-PL, .es-PE, .es-EC, .sq-AL, .en-AU, .ro-RO, .es-AR, .zh-CN {
			width: 27px;
			height: 18px;
			display: inline-block;
			background-position: center 0;
			border: 0px;
			margin: 0 2px 10px 5px;
		}
	}

	.lastwinBox {
		position: absolute;
		right: 45px;
		top: 482px;
		z-index: 10;
		line-height: 38px;
		height: 38px;
		padding: 0 17px 0 40px;
		font-size: 14px;
		color: $white;
		border-radius: 8px;
		font-family: $roboto;
		font-weight: bold;

		.LWValuta {
			position: absolute;
			left: 7px;
			top: -7px;
			width: 27px;
			height: 41px;
			font-size: 0;
			color: $black;
			line-height: normal;
		}

		.LastWinTxt {
			float: right;

			.lastWinTit, .lastWinPlay, .lastWinVal, .lastWinDate {
				display: none;
			}
		}

		.LWDati {
			float: left;
			line-height: 34px;
			padding-right: 5px;

			.lastWinUser {
				text-transform: uppercase;
			}

			.lastWinNat, .lastWinCity {
				display: none;
			}
		}

		.HomeDXTitle, .LWNumeri, .LWRegister {
			display: none;
		}
	}

	.LandingRegistration {
		display: block;
		font-family: 'Roboto';
		width: 63%;
		position: relative;
		margin: 0 auto;
		z-index: 100;

		.lndgMaintext {
			display: inline-block;
			width: 65%;
			position: relative;
			bottom: 14%;
			z-index: 100;

			span {
				text-shadow: 0 3px 6px rgba(22, 56, 121, 0.51);
				font-family: $roboto;
				font-size: 58px;
				font-weight: 900;
				color: $white;
			}

			p {
				color: $white;
				text-shadow: 0 3px 6px rgba(0, 0, 0, 0.56);
				font-family: $roboto;
				font-size: 21px;
				font-weight: 500;
				margin: 0;
			}
		}

		.lndgRegistration {
			background-color: rgba(255, 255, 255, 0.9);
			border-radius: 6px;
			position: relative;
			display: inline-block;
			width: 32%;
			left: 2.7%;
			z-index: 102;

			input {
				width: calc(80% - 10px);
				line-height: 36px;
				position: relative;
				border-radius: 4px;
				border: solid 1px #868a91;
				background-color: $white;
				padding: 0;
				display: block;
				margin: 0 auto;
				padding-left: 10px;
				height: auto;
				color: $black;
			}

			input:-internal-autofill-selected {
				-webkit-box-shadow: 0 0 0 100px #E8F0FE inset !important;
				-webkit-text-fill-color: $black;
				font-size: 26px;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:first-line {
				-webkit-box-shadow: 0 0 0 100px #E8F0FE inset !important;
				-webkit-text-fill-color: $black;
				font-size: 19px;
			}

			input:-webkit-autofill:hover {
				transition-duration: 0s;
				transition-property: all;
				transition-timing-function: ease;
			}

			input:focus {
				color: $black;
				border: 1px solid black;
			}

			.lrTitle {
				text-align: center;
				height: 40px;
				background-repeat: no-repeat;
				background-size: 36px;
				background-position: 93% 80%;
				line-height: 40px;
				padding-top: 5px;
				font-family: $roboto;
				font-size: 21px;
				font-weight: bold;
				color: #171a1f;
				letter-spacing: 0.38px;
				text-transform: uppercase;
				text-shadow: initial;
			}

			.lrTitlePromo {
				text-align: center;
				padding-bottom: 15px;
				text-shadow: initial;
				font-size: $font-16;
				color: #171a1f;
				font-weight: normal;
			}

			.lrSubmit {
				padding: 10px 0 15px 0;
				outline: none;

				input[id$="_btnGetRegistration"] {
					@include dvu-button-green(68px, 485px, $font-24, 1.0, 0.32px);
					box-shadow: none;

					&:hover {
						box-shadow: none;
					}
				}

				@media screen and (max-width: 1280px) {
					input[id$="_btnGetRegistration"] {
						@include dvu-button-green(60px, 100%, $font-20, 1.0, 0.32px);
						line-height: 60px;
					}
				}
			}

			.lrName, .lrSurname, .lrEmail {
				padding-bottom: 10px;

				.lrValidation {
					display: block;
					width: 100%;
					text-align: center;

					span {
						font-size: $font-13;
						color: $black !important;
						font-weight: 500;
						top: 4px;
						position: relative;
						text-align: center;
					}
				}
			}

			.lrPrivacyPolicy {
				.lrValidation {
					.validation-error {
						top: 5px !important;
						//DVU Delete "!important" when delete "top: 0px !important;"
						//for .mainlanding .LandingRegistration .lndgRegistration .lrValidation span in LandingPage.Main pagina
					}
				}
			}
		}
	}

	.footerLicRight {
		ul {
			li {
				a {
					display: inline-block;
				}
			}
		}
	}
}

.LandingDivPuls {
	UL {
		list-style-type: none;
		margin: 0 10px 0 0;
		padding: 0;

		LI {
			display: inline;
		}
	}

	.text {
		color: #9f9f9f;
		font-family: $roboto;
		font-size: $font-12;
		padding: 5px 15px 5px 0;
	}

	.de-DE {
		background-position: center -18px;
	}

	.it-IT {
		background-position: center -36px;
	}

	.es-ES {
		background-position: center -270px;
	}

	.fr-FR {
		background-position: center -72px;
	}

	.sr-Latn-CS {
		background-position: center -90px;
	}

	.bg-BG {
		background-position: center -108px;
	}

	.pl-PL {
		background-position: center -126px;
	}

	.es-PE {
		background-position: center -144px;
	}

	.es-EC {
		background-position: center -162px;
	}

	.sq-AL {
		background-position: center -198px;
	}

	.en-AU {
		background-position: center -234px;
	}

	.ro-RO {
		background-position: center -252px;
	}

	.es-AR {
		background-position: center -270px;
	}

	.zh-CN {
		background-position: center -288px;
	}
}
