@keyframes notification-loader-amount {
    0% {
        box-shadow: 7px 0 $transparent, -7px 0 $black;
        background: $transparent;
    }

    33% {
        box-shadow: 7px 0 $transparent, -7px 0 $black;
        background: $black;
    }

    66% {
        box-shadow: 7px 0 $black,-7px 0 $transparent;
        background: $black;
    }

    100% {
        box-shadow: 7px 0 $black,-7px 0 $transparent;
        background: $transparent;
    }
}
