﻿.thunderbiteGameContainer {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /*16:9 aspect ratio*/

	#ifrGame {
		position: absolute;
		width: 100%;
		height: 100%;
		border: none;
	}
}
