﻿.btnCancel, .buttonCancel, .cancelButton {
	&:not([id$='btnRifiuta']) {
		@include dvu-button-link-small-m($dark-theme: false);
	}
}

.btnLogin, .myBetsButton, .transactionListButton {
	@include dvu-button-small-m();
}

input[type='submit'] {
	&:not([id$='btnCancella']):not(.dvu-buttonDefault):not([id$='btnRifiuta']):not([id$='btnScoAncora']) {
		@include dvu-button-green-small-m();
	}

	&[id$='btnCancella'] {
		@include dvu-button-small-m();
	}

	&[id$='btnRifiuta'] {
		@include dvu-button-red-small-m();
	}
}

.okButton, .buttonAccept, .buttonSave, .user-avanti, .depositNowBtn {
	@include dvu-button-green-small-m();
}

.okButtonLarge {
	@include dvu-button-green-large-s();
}