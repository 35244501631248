﻿#MainContent {
	.Riquadro {
		.CntSX {
			.dvu-betlist {
				[id$="panForm"] {

					#tblSearch {
						padding: 0px;

						input {
							&:-webkit-autofill::first-line {
								font-size: 11px !important;
							}
						}

						.SearchControlsStyle {
							table {
								table-layout: fixed;
							}

							table[id$='rblStatoPagamento'] {
								display: block;

								tr {
									display: block;

									td {
										display: inline-block;
										padding-right: 15px;
									}
								}
							}
						}
					}

					.SearchButtonsStyle {

						td:empty {
							padding: 0px;
						}
					}
				}

				.SearchContainerStyle {

					&#tblSearch2 {
						margin-bottom: 0px;
					}

					table[id$="rblStatoPagamento"] td label {
						padding-right: 40px;
					}
				}

				.divDg {
					.dgStyle {
						table-layout: fixed;

						.dgEmptyStyle {
							text-align: center;
						}

						tr.dgHdrStyle, tr.dgItemStyle, tr.dgAItemStyle, tr.dgTotalsStyle, tr.Void {
							td, th {
								padding-left: 6px;
								padding-right: 6px;
								word-break: break-word;

								&:empty {
									padding: 0px;
								}

								&.h-matches {
									width: 100%;
								}

								&.matches {
									text-align: start;

									a {
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
										display: inline-block;
										max-width: 100%;
									}
								}

								&.h-code, &.h-admcode {
									width: 35%;
								}

								&.code, &.admcode {
									font-family: "Roboto Condensed", sans-serif;
								}

								&.h-user {
									width: 50%;
								}

								&.h-game-type {
									width: 110px;
								}

								&.game-type {
									position: relative;

									.divCmb {
										width: 12px;
										height: 12px;
										overflow: hidden;
										cursor: pointer;
										mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg");
										background-color: $grey-150d;
										display: block;
										position: absolute;
										top: 0;
										bottom: 0;
										right: 0;
										margin: auto 6px auto 0;

										&:hover {
											background-color: $grey-100d;
										}
									}
								}

								&.h-date-time, &.h-image-button {
									width: 80px;
								}

								&.h-status {
									width: 60px;
								}

								&.h-status-2 {
									width: 80px;
								}

								&.date-time {
									span {
										color: $blue-100d;
										font-family: "Roboto Condensed", sans-serif;
									}
								}

								&.h-ammount {
									width: 25%;
								}

								&.h-currency {
									width: 15px;
								}

								a.dvuAnulla {
									margin: 0 auto;
								}

								&hidden {
									visibility: hidden;
								}

								&.cashout-th {
									width: 150px;
								}
							}

							.dvu-cell-money, .dgTotalsImpPos, .dgTotalsImpNeg, .dgTotalsImpNeutral {
								padding-right: 1px;
								text-align: right;
							}

							.dvu-cell-currency {
								padding-left: 1px;
							}

							.dgTotalsImpPos.dgTotalsCloseToLeft, .dgTotalsImpNeg.dgTotalsCloseToLeft, .dgTotalsImpNeutral.dgTotalsCloseToLeft {
								padding-left: 0px;
								padding-right: 6px;
							}

							.dgTotalsImpNeutral {
								color: $grey-150d;
							}
						}

						tr.dgItemStyle, tr.dgAItemStyle {
							background-color: transparent;
							border-bottom: 1px solid $grey-300d;

							&:hover {
								background-color: rgba($white, 0.1);
							}
						}

						tr.Void, tr.Void:hover {
							background-color: $yellow-400d;
							color: $black;
							border-bottom: 1px solid $grey-300d;

							td.date-time {
								span {
									color: $black;
								}
							}

							a {
								&:hover {
									color: $white;
								}
							}
						}
					}

					.dgHdrImporti {
						padding: 0 1px 0 5px;
					}

					a:hover {
						color: $yellow-200d;
					}
				}

				#tblSearch2 {
					display: none;
				}

				.Legend {
					border-spacing: 0px;
					margin-top: 20px;
					color: $grey-100d;
					font-size: 12px;

					.SearchDescStyle, .SearchControlsStyle {
						border-top: 1px solid $grey-300d;
						border-bottom: 1px solid $grey-300d;
						padding: 2px 0;
					}

					.SearchControlsContainerStyle {
						float: right;
						width: unset;

						td:nth-child(2n+1) {
							white-space: nowrap;
							width: 1px;
						}

						td:nth-child(2n) {
							padding-left: 20px;
							padding-right: 6px;
						}
					}
				}

				.dgTotalsStyle {
					td {
						border-bottom: 1px solid $grey-300d;
						color: $grey-150d;
					}
				}

				.dgPagerStyle {
					height: 24px;

					td {
						height: 24px;
						padding: 0px;
						position: relative;

						table {
							margin: auto;
							background-color: transparent;

							span, a {
								border: none;
								border-radius: 4px;
								height: 16px;
								min-width: 16px;
								text-align: center;
								line-height: 16px;
								margin: 0 2px;
							}

							span, a:hover {
								background-color: $grey-200d;
								color: $white;
							}

							a {
								background-color: transparent;
								color: $grey-150d;
							}
						}

						div {
							position: absolute;
							top: 0;
							right: 0;
							padding: 0 6px;
							border: none;
							line-height: 24px;
							font-weight: unset;
							color: $grey-100d;
						}
					}
				}
			}

			.dvu-betdetail {
				.NormalPageContent {
					background: none;
					padding: unset;

					.detScoEsitoPer {
						color: $red-200d;
						font-weight: normal;
					}

					.detScoEsitoVin {
						color: $green-300d;
						font-weight: normal;
					}

					.detScoEsitoTotalCashout {
						color: $green-300d;
						font-weight: normal;
					}

					.detScoEsitoIncorso {
						color: $yellow-300d;
						font-weight: normal;
					}

					.cellaSx, .cellaDx {
						padding-top: 7px;
						padding-bottom: 7px;
						vertical-align: top;
						line-height: 14px;
					}

					.cellaDx {
						.legend-item {
							float: left;
						}

						span {
							line-height: 14px;
						}
					}

					.cellsDx {
						tr {
							height: 28px;
							border-bottom: 1px solid $grey-200d;

							.Quota_New {
								color: $yellow-300d;
							}
						}
					}

					.detScoVP, .detScoIG {
						color: $yellow-300d;
					}
				}

				.dgSubHdrStyle {
					@extend .dgHdrStyle
				}

				.divDg {
					margin: unset;

					> table:first-child {
						tr {
							height: 28px;
						}
					}

					.dgStyle {
						tr.dgSubHdrStyle {
							th:first-child {
								border-top-left-radius: 0px;
							}

							th:last-child {
								border-top-right-radius: 0px;
							}
						}

						tr.dgSubHdrStyle, tr.dgItemStyle, tr.dgAItemStyle, tr.GridDetailsFooterStyle {
							td, th {
								padding-left: 6px;
								padding-right: 6px;
							}
						}
					}
				}

				.SearchButtonsStyle {
					td {
						&.tdSrcDX, &.tdSrcSX {
							width: 150px;
						}

						input[type="button"] {
							@include dvu-button-small-m();

							&[id$="btnPrintCouponPayoutReceipt"] {
								width: 192px;
							}
						}
					}
				}

				.SearchContainerStyle {
					padding-top: 0px;
					padding-left: 0px;
					padding-right: 0px;
					background: none;
					font-size: 14px;

					.SearchControlsStyle {
						border-top: 2px solid $grey-200d;
					}
				}

				tr[id$="trRiservaDetails"] {
					table {
						.cellaDx, .cellaSx {
							padding-left: 6px;
							padding-right: 6px;

							&:empty {
								padding: 0px;
							}
						}
					}
				}

				tr[id$="trQuotaModificaRiserva"] {
					.cellaSx {
						padding-top: 14px;
					}

					.cellsDx {
						padding-top: 7px !important;
					}
				}

				tr {
					td {
						br {
							display: none; //hide line breaks, spacing is handled with CSS
						}
					}
				}

				tr[id$="rowBtnPayoutWithErb"] {
					input[id$="btnPayoutWithErb"] {
						@include dvu-button-green-small-l();
					}
				}

				.dvu-section-title {
					.SectionTitle {
						line-height: 14px;
						padding-top: 14px;
						padding-bottom: 14px;
						font-size: 14px;
						color: $yellow-300d;
						border-bottom: 1px solid $grey-200d;
					}

					& + tr {
						.cellaDx, .cellaSx {
							&[style*='padding'] {
								padding: unset !important; //unsetting stato ADM inline padding
								padding-top: 14px !important;
								padding-bottom: 7px !important;
							}

							vertical-align: top;
							padding-top: 14px;
							padding-bottom: 7px;

							.divBetDtSelectAnagrafica {
								width: 100%;

								a {
									width: 16px;
									height: 16px;
									background-color: $yellow-300d;
									background-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/print.svg');
									background-size: 12px;
									background-repeat: no-repeat;
									background-position: center;
									border-radius: 4px;
									display: block;
									float: right;

									&:hover {
										background-color: $yellow-200d;
									}
								}
							}

							.btnSbloccaAnagrafica {
								position: relative;
								top: -5px;

								&:before {
									content: '';
									mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/check.svg');
									background-color: $green-300d;
									display: inline-block;
									width: 18px;
									height: 18px;
									position: relative;
									top: 3px;
								}
							}
						}
					}
				}
			}
		}

		.dvu-betlist, .dvu-betdetail {
			input[type="button"], input[type="submit"] {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.legend-item {
	width: 14px;
	height: 14px;
	border-radius: 7px;
	display: block;
	margin: auto;

	&.esito-1,
	&.esito-5,
	&.stato-erb-riserva-accepted,
	&.esito-dettaglio-10,
	&.esito-dettaglio-3,
	&.esito-dettaglio-4,
	&.stato-riserva-1,
	&.stato-riserva-3,
	&.voucher-detail-1,
	&.pagata-agente-True {
		background-color: $tripla;
	}

	&.esito-2, &.esito-dettaglio-1, &.esito-dettaglio-11, &.voucher-detail-2 {
		background-color: $quintupla;
	}

	&.esito-3, &.esito-dettaglio-0, &.stato-riserva-2 {
		background-color: $unica;
	}

	&.esito-4, &.stato-erb-riserva-rejected, &.esito-dettaglio-2, &.stato-riserva-4, &.stato-riserva-99_error {
		background-color: $grey-150d;
		position: relative;

		&::after, &::before {
			display: block;
			position: absolute;
			content: "";
			background-color: white;
			width: 2px;
			height: 10px;
			top: 2px;
		}

		&::after {
			transform: translateX(6px) rotate(45deg);
		}

		&::before {
			transform: translateX(6px) rotate(-45deg);
		}
	}

	&.esito-6,
	&.esito-da-validare {
		background-color: $grey-150d;
		position: relative;

		&::after {
			display: block;
			position: absolute;
			content: "";
			background-color: white;
			width: 10px;
			height: 10px;
			top: 2px;
			left: 2px;
			mask-image: url("../../images/icons/pw365-iconpack/current/system/regular/solid/lock.svg");
		}
	}

	&.esito-accettazione-valutazione, &.stato-erb-riserva-pending, &.stato-riserva-0, &.stato-riserva-99 {
		background-color: #e89431;
	}

	&.esito-dettaglio-10, &.esito-dettaglio-11 {
		border-radius: 0px;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		height: 7px;
		transform-origin: 50% 100%;
		transform: translatey(-3.5px) rotate(-45deg);
	}

	&.esito-dettaglio-4 {
		&::after {
			content: "1";
			color: white;
			line-height: 14px;
			display: block;
			text-align: center;
			font-weight: bolder;
			font-size: 12px;
		}
	}

	&.imgBanker {
		width: 16px;
		height: 16px;
		background-color: $yellow-300d;
		color: black;
		border-radius: 4px;
		line-height: 16px;
		font-weight: bold;
	}

	&.pagata-agente-False,
	&.pagata-agente-True {
		position: relative;

		&:after {
			content: '';
			width: 10px;
			height: 10px;
			background-color: $white;
			position: absolute;
			display: block;
			top: 2px;
			left: 2px;
		}
	}

	&.pagata-agente-False {
		background-color: $grey-150d;

		&:after {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/cond/solid/warning.svg');
		}
	}

	&.pagata-agente-True {
		&:after {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/cond/solid/check.svg');
		}
	}
}

/*IE quickfix for display:grid*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	#tblMainContent > tbody > tr > td:only-child {
		padding-left: 258px;
		padding-right: 258px;
	}
}
