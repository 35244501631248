﻿table[id$='rblTipoImporto'] {
	display:block;

	tr {
		display:block;

		td {
			display:inline-block;
			width:100px;
		}
	}
}

table[id$='chklTipoCausale']{
	display:block;

	tr{
		display:inline-block;
		width:100px;
	}
}

tr[id$='filtroSoggetto'] {
	.SearchControlsStyle {
		.SearchControlItem {
			width: 60%;

			table tr td:first-child {
				width: 27%;
			}
		}
	}
}

[id$='ddlUtente'] {
	width: 266px;
}

.userPopup {
	mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/account.svg');
	width: 18px;
	height: 18px;
	display: block;
	background-color: $grey-150d;

	&:hover {
		background-color: $yellow-200d;
	}

	img {
		display: none;
	}
}

.NormalPageContent.Transactions {
	padding-bottom: 0;
	overflow: hidden;

	.SearchButtonsStyle {
		padding: 0 20px;

		.tdSrcDX {
			text-align: left !important;
			border-top: 1px solid #707070;
			padding: 25px 0 13px 0 !important;
		}
	}
}

.transactionDetail {
	.SectionTitle {
		display: none;
	}

	table tr {
		height: 32px;

		.cellaSx {
			padding-left: 20px;
		}

		.cellaDx {
			padding: 0 7px;
			text-align: left;

			a[id$='lnkADM'] {
				color: $yellow-400;
				border-bottom: 1px solid $yellow-400;
			}
		}

		&:nth-of-type(odd) {
			background-color: rgba($white, 0.03);
		}

		&:nth-of-type(even) {
			background-color: transparent !important;
		}
	}

	table {
		tr[id$='trHighWin'] {
			.cellaDx{
				color:$red-400;
			}
		}
	}

	&.withdrawal {
		width: 47%;
		float: right;
		margin: 30px 20px 30px 0;

		.transactionBaseDetail {
			font-size: $font-12;
		}

		.cellaDx {
			text-align: right;
			padding-right: 20px;
		}
	}

	.transactionBaseDetail {
		width: 100%;

		.cellaSx {
			color: $grey-100;
			width: 40%;
		}

		.status {
			.cellaSx {
				color: $white;
			}

			.cellaDx {
				font-size: $font-18;
			}

			&.s2 {
				.cellaDx {
					color: $yellow-200;
				}
			}

			&.s3 {
				.cellaDx {
					color: $green-200;
				}
			}

			&.s4 {
				.cellaDx {
					color: $red-200;
				}
			}
		}
	}
}

.WithdrawalProgressBarGeneral {
	overflow: hidden;
	width: 50%;
	float: left;

	.WithdrawalProgressBarSteps {
		width: 100%;
		padding: 30px 20px 18px;
		overflow: hidden;
	}

	.progressBarStep {
		color: $white;
		width: 100%;
		overflow: hidden;

		.progressIcon {
			width: 34px;
			height: 34px;
			border-radius: 50%;
			background-color: $green-200;
			display: inline-block;
			position: relative;
			float: left;

			&:after {
				content: '';
				width: 24px;
				height: 24px;
				position: absolute;
				background-color: $white;
				left: 5px;
				top: 5px;
			}
		}

		.date {
			font-size: 8px;
			color: $grey-100;
			display: inline-block;
			width: calc(100% - 40px);
			padding: 0 0 2px 15px;
		}

		.stepName {
			font-size: $font-12;
			letter-spacing: 0.23px;
			display: inline-block;
			width: calc(100% - 40px);
			padding-left: 15px;

			span {
				display: inline-block;
				vertical-align: middle;
				line-height: normal;
			}
		}

		&.Start {
			.progressIcon {
				&:after {
					mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/o/money-out.svg');
				}
			}
		}

		&.InProcess {
			.progressIcon {
				width: 12px;
				height: 12px;
				margin: 1px 0 0 10px;

				&:after {
					content: none;
				}
			}

			.stepName {
				padding-left: 22px;
			}
		}

		&.Done {
			.progressIcon {
				&:after {
					mask-image: url('../../Images/Icons/pw365-iconpack/current/system/regular/o/withdrawal_done.svg');
				}
			}
		}

		&[data-status="2"] {
			&.InProcess {
				.progressIcon {
					background-color: $yellow-300;
				}
			}

			&.Done {
				color: $grey-300;

				.progressIcon {
					background-color: $grey-300;

					&:after {
						background-color: $grey-100;
					}
				}
			}
		}

		&[data-status="4"] {
			&.Done {
				.progressIcon {
					background-color: $red-200;
				}
			}
		}
	}

	.divDelimiter {
		width: 100%;
		height: 40px;
		padding: 3px 0 3px 16px;

		span {
			background-color: $green-200;
			width: 1px;
			height: 100%;
			display: block;
		}

		&[data-status="2"] {
			span {
				background-color: $grey-300;
			}
		}

		&[data-status="4"] {
			span {
				background-color: $red-200;
			}
		}
	}

	.withdrawalButton {
		padding: 25px 0;
	}
}
