#FastGamesSliderWrapper {
	position: relative;
	width: 100%;
	margin: 15px 0px 8px 0px;
	padding: 0px;
	border: none;
	background-image: none;
	background-color: transparent;
	overflow: hidden;
	transition: height 0.4s linear;

	&.pierino {
		&.expanded {
			&.single-game {
				height: 601px;
			}

			&.multi-game {
				height: 653px;
			}
		}
	}

	&.setteemezzo {
		&.expanded {
			&.single-game {
				height: 601px;
			}

			&.multi-game {
				height: 653px;
				border-bottom: 2px solid $yellow-400d;
			}
		}
	}

	&.instant_roulette_fr {
		&.expanded {
			&.single-game {
				height: 648px;
			}

			&.multi-game {
				height: 699px;
				border-bottom: 2px solid $yellow-400d;
			}
		}
	}

	&.collapsed {
		height: 219px;
	}

	.hide {
		display: none;
	}

	.initGame {
		border: 0px;
		padding: 0px;
		width: 0;
		height: 0;
	}

	.HomeDXTitleFastGames {
		@include dvu-titlebar();
	}

	#FastGamesSliderWrapperHandle {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 38px;
		width: 100%;
		z-index: 10;
		background-color: transparent;
		background-image: none;
		padding: 0px;
		margin: 0px;
		cursor: pointer;
		transition: none;

		> div {
			pointer-events: none;
		}

		.button {
			position: relative;
			top: 38px;
			margin: 0;
			padding: 0;
			width: 100%;
			background-image: url('../../images/fastgames_btn_widget_roulette.png');
			background-size: cover;
			background-position: center;
			background-color: transparent;
			border: none;
			border-radius: 6px;
			outline: none;
			transition: opacity 0.1s linear;
		}

		&.collapsed .button {
			opacity: 0;
			height: 0px;
		}

		&.expanded .button {
			opacity: 1;
			height: 181px;
		}
	}

	#FastGamesSliderIframe {
		position: static;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 6px;
		padding: 0;
		margin: 0;
		opacity: 1;
		transition: height 0.4s linear;
		background-color: transparent;
	}
}

/*#region FG widget (pagina) for anonymous users*/
.fastGamesWidget.HomeDXContent {
	position: relative;
	width: 100%;
	margin-top: 15px;
	padding: 0px;
	border: none;
	border-radius: 0px;
	overflow: hidden;
	background-image: none;
	background-color: transparent;

	.HomeDXTitleFastGames {
		@include dvu-titlebar();
		text-indent: 0px;
	}

	.slider {
		width: 100%;
		height: auto;
		border: none;
		border-radius: 6px;
		overflow: hidden;
	}

	.fgBtnLogin {
		@include dvu-button-green(30px, 150px, $font-14, 30px, 0.5px);
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -75px;
		/*disabling hover effect*/
		&:hover {
			background: $green-300d;
			cursor: default;
		}
	}
}
/*#endregion*/