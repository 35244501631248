﻿#NESportCnt {
	position: static;
	margin: 0 0 8px 0;

	.NESportTitle {
		@include dvu-titlebar();
	}

	.itemSport {
		@include dvu-font-regular($font-12);
		border-radius: 6px;
		background-color: $grey-300d;
		color: $white;
		letter-spacing: 0.24px;
		line-height: 1.17;
		margin-bottom:8px;

		.ImgSport,
		.header {
			display: none;
		}

		.sport {
			padding: 0 0 0 14px;
			height: 28px;
			color: $yellow-200d;
			line-height: 28px;
			text-align: left;
		}

		.items {

			.item {
				margin: 0px;
				height: 48px;
				text-transform: none;

				&:nth-of-type(odd) {
					background-color: rgba($white, 0.05);
				}

				.dateTime,
				.eventData {
					float: left;
					padding-right: 6px !important;
				}

				.dateTime{
					padding-top:5px !important;
				}

				.data,
				.time {
					font-stretch: condensed;
				}

				.stats {
					float: left;
					width: 16px;
					padding: 5px 16px 0 8px !important;

					.statsbtn {
						height: 100%;
					}
				}

				.event,
				.subevent {
					width: 420px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					display: block;

					@media screen and (max-width:1870px) {
						width: 350px
					}

					@media (min-width:1691px) and (max-width:1745px) {
						width: 300px;
					}

					@media (min-width:1650px) and (max-width:1689px) {
						width: 400px;
					}

					@media (min-width:1491px) and (max-width:1649px) {
						width: 300px;
					}

					@media screen and (max-width:1490px) {
						width: 180px;
					}
				}

				.subevent {
					line-height: 1.14;

					a {
						text-decoration: none;

						&:hover {
							color: $yellow-200d;
						}
					}
				}

				.odds {
					float: right;

					.odd {
						height: 48px;
						background-color: rgba($grey-200d, 0.3);

						.TQ {
							display: none;
						}

						a {
							line-height: 36px;
						}

						&.pos2,
						&.pos51 {
							background-color: transparent;
						}

						&.sel {
							.g0, .g3 {
								line-height: 34px;
							}
						}
					}
				}

				&:first-child .odds .odd .TQ {
					position: absolute;
					display: flex;
					top: -28px;
					left: 0;
					height: 28px;
					line-height: normal;
					width: 98%;
					color: $grey-100d;
					letter-spacing: 0.3px;
					justify-content: center;
					align-items: center;

					&:hover {
						border: none;
					}
				}
			}
		}
	}
}
