﻿/* in this file, just grid definitions and element placement inside grid*/
@supports (grid-area: auto) {
	#aspnetForm {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: "header" "main-content" "footer-upper" "footer-lower";
		justify-items: center;
	}

	.header {
		grid-area: header;
	}

	.mainSubheader {
		/*grid-area: 2 / 1 / 3 / 2;*/
		grid-area: main-content;
	}

	.divMainHome,
	.divMainLive {
		grid-area: main-content;
	}

	#divFooter {
		grid-area: footer-upper;
	}

	.mainFooter {
		grid-area: footer-lower;
	}

	#tblMainContent {
		& > tbody {
			& > tr {
				display: grid;
				grid-gap: 6px;
				grid-template-columns: 258px 1fr 258px;
				grid-template-rows: 1fr;
				grid-template-areas: "column-left column-center column-right";
				align-items: start;
			}
		}

		.tdSX {
			grid-area: column-left;
			/*grid-area: 1 / 1 / 1 / 2;*/
		}

		.tdCN,
		.tdCNMaxAccount {
			grid-area: column-center;
			/*grid-area: 1 / 2 / 1 / 3;*/
		}

		.tdDX {
			grid-area: column-right;
			/*grid-area: 1 / 3 / 1 / 4;*/
		}

		&.tblMainAccount {
			@media screen and (max-width:1690px) {
				& > tbody {
					& > tr {
						grid-template-columns: 0px 1fr 0px;
					}
				}
			}
		}
	}

	#tblMainLive {
		& > tbody {
			& > tr {
				display: grid;
				grid-gap: 6px;
				grid-template-columns: 1fr 258px;
				grid-template-rows: 1fr;
				grid-template-areas: "column-main column-right";
				align-items: start;
			}
		}

		.tdCNLive {
			grid-area: column-main;
		}

		.tdDX {
			grid-area: column-right;
		}
	}

	.divNewUserWide {
		.Riquadro {
			display: grid;
			grid-gap: 6px;
			grid-template-columns: 258px 1fr 258px;
			grid-template-rows: 1fr;
			grid-template-areas: "column-left column-center column-right";
			align-items: start;

			& > .TopSX {
				grid-area: column-left;
			}

			& > .CntSX {
				grid-area: column-center;
			}

			& > .BtmSX {
				grid-area: column-right;
			}
		}
	}
}
