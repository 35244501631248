﻿.sliderContainer {
	border-radius: 6px;
	overflow: hidden;
	display: block;
	margin-top: 10px;

	div, li {
		width: 49px;
		height: 28px;
		font-size: 11px;
		background-color: $grey-300d;
		display: inline-block;
		line-height: 28px;
		text-align: center;
		text-transform: lowercase;
		border-right: 1px solid $grey-150d;

		&:hover {
			background-color: $grey-200d;
			cursor: pointer;
		}

		&:first-letter {
			text-transform: uppercase;
		}

		&.sel {
			background-color: $yellow-400d;
			color: $grey-300d;

			&:hover {
				background-color: $yellow-300d;
			}
		}

		&:last-child {
			border: none;
		}
	}
}
