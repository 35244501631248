.menu {
	border-radius: 6px;
	background: $yellow-300-orange-400;
	clear: both;
	height: 32px;
		
	.statsReg {
		position: relative;
		float: right;
		min-width: 84px;
		bottom: 33px;
	}
	
	.transactionListButton {
		position: relative;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		float: left;

		li {
			float: left;
			padding: 8px 9px;
			text-transform: capitalize;

			a {
				@include transition(all 0.35s ease);
				color: $black;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				line-height: 1.25;

				&:hover {
					color: white;
				}

				&.menuItemSelected {
					color: $black;
				}
			}
			//label NEW added as custom class over WA
			.lbNEW {
				@include dvu-new-label;
			}
		}
	}
}
