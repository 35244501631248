﻿div[id$='panelUser'] {
	background: $grey-300d;
	border-radius: 0 0 6px 6px;
	padding-top: 16px;

	.textbox {
		height: 42px;
	}

	.dropdown {
		height: 42px;
		width: 192px;

		&[id$='ddlInternationalCountryCode'] {
			width: 60px;
		}
	}

	table {
		tr {
			height: 30px;

			td:first-child {
				width: 40% !important;
				text-align: right;
			}

			&.SectionResidence,
			&.SectionDomicile,
			&.SectionBirth,
			&.SectionDocument,
			&.SectionMiscellaneous {
				height: 34px;
				font-size: $font-16;

				td {
					text-align: center;
					vertical-align: bottom;
					padding-bottom: 7px;
					background-color: $grey-300d;
				}
			}

			.unlockEmailBtn,
			.unlockDomicileBtn,
			.unlockPhoneBtn {
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				top: 11px;
				right: 0px;
				cursor: pointer;
				z-index: 10;
				background-color: $grey-150d;
				border: none;
				outline: none;
				text-indent: -99999px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/edit.svg');
				--webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/edit.svg');

				&:hover {
					background-color: $yellow-200d;
				}
			}

			.updateEmailBtn, .updatePhoneBtn, .updateDomicileBtn {
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/save.svg');
				-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/save.svg');
			}

			.cellaDx {
				text-align: left;

				.divUserEmail {
					width: 215px !important;
				}

				.divUserPhone {
					width: 215px;

					input[id$='txtTelefono'] {
						width: 129px
					}
				}
			}

			.divUserDomicile {
				margin: 0 auto;
				width: 247px !important;
				top: -28px;
			}

			.lastTD {
				width: 37%;
			}

			&.SectionModificaPassword,
			&.SectionModificaDomandaDiSicurezza {
				h4 {
					background-color: $grey-200d;
					border-radius: 6px 6px 0 0;
					font-size: $font-16;
					line-height: 25px;
					padding-left: 7px;
					margin-bottom: 12px;
					text-align: left;
				}
			}

			.cellaInfo {
				text-align: center;
				padding-bottom: 12px;
			}

			.validation-error {
				display: block;
			}
		}
	}
  .CalendarExternalTableStyle {
    tr {
      height: unset;

      td:first-child {
        width: unset !important;
        text-align: unset;
      }
    }
  }
}

div[id$='panelPwd'] {
	font-size: $font-12;

	[id$='btnResetPwd'],
	[id$='btnResetSecurityQuestion'] {
		@include dvu-button-small-m();
		float: left;
	}

	.dropdown {
		width: 192px;
	}

	table {
		margin-bottom: 16px;
		border-collapse: separate;
		display: block;
		width: 100%;
		text-align: center;

		tbody, tr {
			display: block;
			width: 100%;
		}

		tr {
			background: $grey-300d;
			width: 100%;

			td {
				display: inline-block;
			}

			&:first-child {
				border-radius: 0px 6px 0px 0px;

				td {
					padding-top: 16px;
				}
			}

			&:last-child {
				td:not(.cellaSx):not(.cellaDx):not(.cellaInfo) {
					padding: 0 12px 12px 12px;
				}

				border-radius: 0 0 6px 6px;
			}

			td:first-child:not(.cellaInfo):not(.cellaValidate) {
				width: 15%;
				text-align: right;
			}

			.cellaDx {
				text-align: left;
			}

			&.SectionModificaPassword, &.SectionModificaDomandaDiSicurezza {
				background: transparent;
				text-align: left;
				white-space: nowrap;

				td {
					padding-top: 0;
					width: 100% !important;
				}

				h4 {
					border-radius: 6px 6px 0 0;
					font-size: $font-16;
					line-height: 25px;
					padding-left: 7px;
					text-align: left;
				}

				& + tr {
					border-radius: 6px 6px 0 0;
				}
			}

			b {
				float: left;
			}
		}

		&:last-child {
			margin: 0;
		}

		tr.buttonRow {
			background: $grey-300d;

			td {
				width: 100% !important;
			}

			& + tr {
				display: none; //DVUDelete modifica security password spacer
			}
		}

		&[id$='trSecurityQA'] {
			tr:last-child {
				border-radius: 6px !important;
				overflow: hidden;
			}
		}
	}

	.cellaInfo {
		text-align: center;
		padding: 16px 0;
		width: 40%;
		text-align: left;

		span{
			display: block;
			padding: 8px;
			font-weight: bold;
		}
	}

	.cellaValidate{
		.validation-hide{
			display: none;
		}
	}

	div[id$='msgPwdUpdated'] {
		margin-top: -20px;
		background: $grey-300d;
		padding-bottom: 1px;
		margin-bottom: 16px;
		border-radius: 0px 0px 6px 6px;
	}

	div[id$='msgSQAUpdated'] {
		margin-top: -20px;
	}
}

div[id$='panelAntieffrazione'] {
	background-color: $grey-300d;
	border-radius: 0 6px 6px 6px;
	padding: 0 12px 12px 12px;

	.cellaInfo {
		font-size: $font-14;
		padding: 16px 0 12px;
	}

	.cellaDx, .cellaSx {
		padding-bottom: 12px;
	}
}

.userGestioneSito {
	background-color: $grey-300d;
	border-radius: 0 0 6px 6px;
	padding: 16px 12px 12px;

	.section {
		@include dvu-titlebar();
	}

	.lclText {
		width: 50%;
		line-height:16px;
	}

	.spacer5 {
		height: 10px;
	}

	.cellaInfoMsg {
		color: $yellow-300d;
	}

	.divButton {
		text-align: right;
		padding: 0 20px 10px 20px;
	}

	.rblBackground {
		margin: 0 auto 0 auto;
	}

	.rblBackground td input[type="radio"] {
		height: 20px;
		line-height: 20px;
		margin: 0;
		padding: 5px 0 0 5px;
		border: 0;
		background-color: red;
		display: none;
	}

	.rblBackground td label:hover {
		opacity: 1;
	}

	.rblBackground td label:hover img {
		opacity: 1;
	}

	.rblBackground td label {
		position: relative;
		display: block;
		border: 1px solid #444444;
		padding-top: 20px;
		border-radius: 5px;
		width: 160px;
		overflow: hidden;
		cursor: pointer;
		opacity: 0.8;
		box-shadow: 0 0 10px 0px #000;
	}

	.rblBackground td label img {
		opacity: 0.8
	}

	.rblBackground td span {
		height: 19px;
		line-height: 19px;
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 155px;
		padding-left: 5px;
		border-bottom: 1px solid #444444;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.rblBackground td input[type="radio"]:checked + label {
		color: #ffbb03;
		border: 1px solid #ffbb03;
		opacity: 1;
	}

	.rblBackground td input[type="radio"]:checked + label span {
		border-bottom: 1px solid #ffbb03;
	}

	.rblBackground td input[type="radio"]:checked + label img {
		opacity: 1;
	}
}

.kyc-title.netverify {
	@include dvu-titlebar();
}

div[id$='GDPRPanel'] {
	background-color: $grey-300d;
	border-radius: 0 6px 6px 6px;
	padding: 12px;

	.editGdpr {
		tbody {
			tr:first-child {
				p {
					padding-bottom: 12px;
				}
			}
		}

		p {
			padding: 3px 0;
		}

		a {
			&:not(.button) {
				color: $yellow-200d !important;

				&:hover {
					color: $white !important;
				}
			}

			&.button {
				@include dvu-button-green-small-m();
			}
		}
	}
}

div[id$='UserAMLPanel'] {
	background-color: $grey-300d;
	border-radius: 0 6px 6px 6px;
	padding: 12px;

	h4{
		padding-bottom:16px;
	}

	tr {
		.cellaSx, .cellaDx{
			font-size:$font-12;
			line-height:18px;
		}

		.cellaDx {
			span{
				color:$yellow-300d;
			}
		}
	}
}
