﻿.kyc-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	color: $black;
	z-index: 100000;
	text-align: center;

	&--close > input {
		background-color: $grey-300d !important;
		position: absolute;
		top: 4px;
		right: 6px;
		mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
		-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
		width: 16px;
		height: 16px;

		&:hover {
			cursor: pointer;
			background-color: $grey-500d;
		}
	}

	.kyc-notification--title {
		font-weight: bold;
		font-size: 16px;
		padding-bottom: 12px;
	}

	.kyc-title.netverify {
		padding-left: 10px;
	}
}

.kyc-popup--message, .kyc-notification--message {
	font-size: 14px;
	color: $grey-300d !important; //DVUDELETE important
	line-height: 16px;
	text-overflow: ellipsis;
	text-align: center;
	letter-spacing: 0.24px;

	a, .link {
		font-weight:bold;
		color:$yellow-400d;

		&:hover{
			color: $yellow-300d;
		}
	}
}

.kyc-popup--message span,
.kyc-popup--message .days {
	font-size: $font-32;
	font-weight: bold;
	margin: 10px 0 15px 0;
	color: $red-200d;
	line-height:32px;
}

.kyc-popup--image {
	display: block;
	clear: both;
	margin-left: auto !important;
	margin-right: auto !important;
	background: none;
	height: 88px;
	width: 88px;
	background-color: $red-200d;
	mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/hourglass.svg');
	-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/hourglass.svg');
	mask-repeat:no-repeat;
}