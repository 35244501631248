// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300,400,500,600,700|Roboto:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=latin-ext");
$roboto: "Roboto", sans-serif;
$roboto-condensed: "Roboto Condensed", sans-serif;
$openSans: 'Open Sans', sans-serif;

// Colors
$white: #fff;
$black: #000;
$transparent: transparent;
$white-080: #ffffffcc; //transparency 80%

$grey-100: #B9BDC4;
$grey-150: #a4a9ac;
$grey-200: #868A91;
$grey-300: #54585E;
$grey-400: #3A3E45;
$grey-450: #34363C;
$grey-500: #22262B;
$grey-600: #171A1F;

//desktop
$grey-700: #b9bdc4;
$grey-800: #d1d6de;
$grey-900: #242424;
$grey-1000: #0D0F12;
$grey-1100: #ededed;
$grey-1200: #eeeeee;
$grey-1300: #c0c0c0;
$grey-1400: #d5d5d5;


$red-100: #ffcfcf;
$red-200: #dd4848;
$red-300: #c62b2b;
$red-400: #ca141b;

$green-100: #c0e5b6;
$green-200: #58b73f;
$green-300: #3b9b22;
$green-400: #27960d;
//desktop
$green-500: #a0d743;
$green-600: #a1d44e;
$green-700: #7dc011;
$green-800: #7bbb0c;
$green-900: #5b9400;

$blue-100: #b8dcf3;
$blue-200: #367ae8;
$blue-300: #2261de;

$yellow-100: #ffeead;
$yellow-200: #ffe480;
$yellow-300: #f8cb24;
$yellow-400: #fab615;

$orange-100: #eba217;
$orange-300: #d26a01;
$orange-400: #e79211;

/*DESKTOP COLORS*/
$grey-100d: #B9BDC4;
$grey-150d: #868A91;
$grey-200d: #54585E;
$grey-300d: #3A3E45;
$grey-350d: #34363C;
$grey-400d: #22262B;
$grey-500d: #171A1F;
$grey-600d: #0D0F12;

$yellow-100d: #ffeead;
$yellow-200d: #ffe480;
$yellow-300d: #f8cb24;
$yellow-400d: #fab615;

$green-100d: #c0e5b6;
$green-200d: #58b73f;
$green-300d: #3b9b22;

$red-100d: #ffcfcf;
$red-200d: #dd4848;
$red-300d: #c62b2b;

$blue-100d: #b8dcf3;
$blue-200d: #367ae8;
$blue-300d: #2261de;

$unica: 	#e8c131;
$tripla:	#12b11e;
$quadrupla: #0292ff;
$quintupla: #d34d3b;
/*END*/

//Gradient colors
$grey-100-white: linear-gradient(to bottom, $grey-100, $white);
$white-grey-100: linear-gradient(to bottom, $white, $grey-100);
$grey-200-400: linear-gradient(to bottom, $grey-200, $grey-400);
$grey-300-500: linear-gradient(to bottom, $grey-300, $grey-500);
$grey-300-600: linear-gradient(to bottom, $grey-300, $grey-600);
$grey-500-600-700-800: linear-gradient(to bottom, $grey-100, $grey-100 49%, $grey-700 50%, $grey-800);
$grey-1100-1200-1300-1400: linear-gradient(to bottom, $grey-1100, $grey-1200 49%, $grey-1300 50%, $grey-1400);
$grey-1100-1100-1100-1100: linear-gradient(to bottom, $grey-1100, $grey-1100 49%, $grey-1100 50%, $grey-1100);

$red-200-300: linear-gradient(to bottom, $red-200, $red-300);
$red-300-200: linear-gradient(to bottom, $red-300, $red-200);

$green-200-300: linear-gradient(to bottom, $green-200, $green-300);
$green-300-200: linear-gradient(to bottom, $green-300, $green-200);
$green-500-600-700-800: linear-gradient(to bottom, $green-500, $green-600 49%, $green-700 50%, $green-800);
$green-500-500-500-500: linear-gradient(to bottom, $green-500, $green-500 49%, $green-500 50%, $green-500);

$blue-200-300: linear-gradient(to bottom, $blue-200, $blue-300);
$blue-300-200: linear-gradient(to bottom, $blue-300, $blue-200);

$yellow-300-200: linear-gradient(to bottom, $yellow-200, $yellow-300);
$yellow-300-200: linear-gradient(to bottom, $yellow-300, $yellow-200);

$yellow-300-orange-100: linear-gradient(to bottom, $yellow-300, $orange-100);
$yellow-300-orange-400: linear-gradient(to bottom, $yellow-300, $orange-400);

//Font sizes
$font-9: 9px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-17: 17px;
$font-18: 18px;
$font-19: 19px;
$font-20: 20px;
$font-24: 24px;
$font-32: 32px;