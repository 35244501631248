﻿.BannerBase {
	position: relative;
	z-index: 2;
	overflow: hidden;

	a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 8;
		display: block;
		height: 100%;
		background-color: $grey-300d;
		overflow: hidden;

		//revert to default from pointer cursor if there is no URL; pointer is set in visual-uplift-prematch-all-quotes.scss
		&:not([href]) {
			cursor: default !important;
		}

		img {
			display: block;
			width: 100%;
		}

		&:first-of-type {
			position: relative;
		}

		&.active {
			z-index: 10;
		}

		&.last-active {
			z-index: 9;
		}
	}
}

.BannerVertical {
	border-radius: 6px;
	margin-top: 8px;
}

.NewsBannerSX {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;

	&.BannerBase A {
		img {
			max-height: 100px;
		}

		span {
			@include dvu-font-regular($font-20);
			position: absolute;
			left: 0px;
			bottom: 0px;
			right: 0px;
			padding: 5px 12px;
			text-shadow: 0 1px 2px $black;
			line-height: normal;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.SXCercaContent {
	padding: 11px 12px;
	background-color: $grey-300d;
	overflow: hidden;

	.TxtCerca {
		width:100%;

		&:-webkit-autofill::first-line {
			font-size: 11px !important;
		}
	}

	DIV {
		width: 100%;
	}

	.BtnCerca {
		display: none;
	}
}

.SportSelector {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: $grey-300d;
	color: $white;
	position: relative;
	padding:3px 12px 12px;

	.Title {
		color: $grey-100d;
		margin: 0 6px;
		padding: 6px 0 3px 0;
		font-size: $font-11;
	}

	#sportTimeSliderHdr, #oddsPanelTimeSliderHdr {
		overflow: hidden;
		border: 1px solid $grey-150d;

		div{
			width: calc(100% / 5);
		}
	}
}

#divMenuSportGSX, #divMenuSportSX {
	overflow: hidden;
	margin: 8px 0;
	@include dvu-font-regular($font-14);
	letter-spacing: 0.8px;
	font-weight: 300;

	.Cnt {
		padding: 0;

		.OddsLastMinute,
		.OddsLessThanSport,
		.VirtualGames,
		.Live,
		.VociMenuFisseSportG,
		.VociMenuSportG,
		.VociMenuSport {
			a {
				display: block;
				text-decoration: none;
				color: $white;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-left: 34px;
				height: 32px;
				line-height: 32px;
				border-bottom: 1px solid $grey-500d;
				width: 100%;
				text-transform: lowercase;
				background: $grey-200d;

				span:first-letter, &:first-letter {
					text-transform: uppercase;
				}

				&:hover {
					background: $grey-150d;
				}
			}

			.VMFSVirtualGames {
				border: none;
			}

			&.Top {
				@include dvu-titlebar();
			}
		}

		.OddsLastMinute,
		.OddsLessThanSport,
		.VirtualGames,
		.Live {

			a {
				padding-left: 12px;
			}
		}

		.VociMenuFisseSportG {
			position: relative;
		}

		.VociMenuSportG {
			position: relative;

			a {
				text-transform: none;
			}

			span {
				margin: 0;
			}
		}

		.sportWidgetMainHeader {
			overflow: hidden;
			margin-bottom: 8px;
			border-radius: 6px;

			a {
				padding-left: 12px;
			}
		}

		.sportWidgetList {
			border-radius: 0px 0px 6px 6px;
			overflow: hidden;

			#divMenuSportGSXSports {
				border-radius: 6px 6px 0px 0px;
				overflow: hidden;
			}

			#menuSports {
				border-radius: 6px 6px 0px 0px;
				overflow: hidden;
			}

			a {
				&:after {
					content: '';
					display: inline-block;
					background: $grey-100d;
					position: absolute;
					right: 6px;
					top: 9px;
					width: 12px;
					height: 12px;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background-color: $transparent;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 9px;
					}
				}
			}
		}

		.divSportList {

			.ImgSport {
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				border: 0;
				padding: 0;
				width: 16px;
				height: 16px;
				overflow: hidden;
				top: 7px;
				left: 12px;
				z-index: 1;

				IMG {
					width: 0px;
					height: 0px;
				}
			}

			.NomeSport {
				position: relative;

				> a {
					padding-right: 24px;

					&:after {
						mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							mask-image: none;
							background-image: url('../../images/IEIcons/arrow.svg');
						}
					}
				}
			}

			#divMenuSportGSXAntepost {
				.VociMenuTitleAntepost {
					height: 28px;
					line-height: 28px;
					width: 100%;
					padding-left: 10px;
					white-space: nowrap;
					overflow: hidden;
					z-index: 3;
					pointer-events: none;
					border-bottom: 1px solid $grey-500d;
					background: $grey-200d;
					@include dvu-font-regular($font-14);

					&:hover {
						color: $white;
					}
				}

				.VociMenuSportG {
					background: $grey-300d;

					&:hover {
						background: $grey-200d;
					}

					.NomeSportA {
						text-transform: capitalize;

						span {
							display: inline-block;
						}

						a {
							&:before {
								content: 'Antepost - ';
								font-size: 11px;
							}

							&:after {
								mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									mask-image: none;
									background-image: url('../../images/IEIcons/arrow.svg');
								}
							}
						}
					}

					&:last-child {
						border: none;
					}
				}
			}

			&.LiveBetting {
				a {
					//color: #ffbb00;
				}
			}
		}

		ul#menuSports {
			list-style-type: none;
			margin: 0;
			padding: 0;
			width: 100%;

			.divNSport {
				&:first-letter {
					text-transform: uppercase;
				}
			}

			li {
				position: relative;

				.ImgSport {
					background-repeat: no-repeat;
					background-size: contain;
					position: absolute;
					border: 0;
					padding: 0;
					width: 16px;
					height: 16px;
					overflow: hidden;
					top: 7px;
					left: 12px;
					z-index: 1;

					IMG {
						width: 0px;
						height: 0px;
					}
				}

				.divNSport {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&.VociMenuSport {
					position: relative;

					a {
						text-transform: none;
						padding-right: 24px;
					}

					> a {
						&:after {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								mask-image: none;
								background-image: url('../../images/IEIcons/arrow.svg');
							}
						}
					}

					&.sel {
						> a {
							&:after {
								mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg');

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									mask-image: none;
									background-image: url('../../images/IEIcons/arrow_up.svg');
								}
							}
						}
					}
				}

				ul li {
					position: relative;

					> a {
						background-color: $grey-300d;
						padding-left: 12px;

						&:hover {
							background-color: $grey-200d;
						}

						&:after {
							mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-down.svg');

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								mask-image: none;
								background-image: url('../../images/IEIcons/arrow.svg');
							}
						}
					}

					&.sel {
						> a {
							&:after {
								mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-up.svg');

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									mask-image: none;
									background-image: url('../../images/IEIcons/arrow_up.svg');
								}
							}
						}
					}

					ul li {
						&.VociMenuSport {
							a {
								&:after {
									content: none;
								}
							}

							.VoceSel {
								color: $yellow-300d;

								&:after {
									content: '';
									background: $yellow-300d;
									mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/angle-right.svg');

									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										background-color: $transparent;
										background-position: center;
										background-repeat: no-repeat;
										background-size: 9px;
										mask-image: none;
										background-image: url('../../images/IEIcons/yellow_arrow.svg');
										transform: rotate(-90deg);
									}
								}
							}
						}

						a {
							background-color: $grey-400d;
							padding-left: 34px;

							&:hover {
								background-color: $grey-300d;

								.pulsPref {
									background-color: $grey-400d;
								}
							}
						}
					}
				}
			}

			> li:last-child {
				> a {
					border: none;
				}

				&.sel {
					> a {
						border-bottom: 1px solid $grey-500d;
					}
				}
			}
		}
	}

	.BackToGroups {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.btnBackToGroups {
		background-repeat: no-repeat;
		height: 25px;
		line-height: 25px;
		text-align: center;
		display: block;
		padding: 0;
		border: 0;
		margin: 0;
		text-decoration: none;
		color: #888888;
		font-size: 11px;

		&:hover {
			color: #ccc;
		}
	}

	.Btm {
		.Btm {
			height: 3px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		height: 0;
	}

	.SportMenuPromo {
		width: 175px;
		height: 20px;
		overflow: hidden;
		margin-top: 7px;
	}

	.ImgGroup {
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 22px;
		height: 22px;

		IMG {
			width: 22px;
			height: 22px;
		}
	}

	.ImgEvent {
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 22px;
		height: 22px;

		IMG {
			width: 22px;
			height: 22px;
		}
	}

	.pulsPref {
		position: absolute;
		left: 12px;
		top: 6px;
		height: 16px;
		width: 16px;
		cursor: pointer;

		&:before {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/favorites.svg');
			height: 16px;
			width: 16px;
			background: $grey-100d;
			content: "";
			display: block;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-color: $transparent;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 16px;
				mask-image: none;
				background-image: url('../../images/IEIcons/favorites.svg');
			}
		}

		&:hover {

			&:before {
				background: $yellow-200d;
			}
		}

		.infoMsg {
			@include dvu-tooltip-default-simple($font-14);
			width: 150px;
			position: absolute;
			bottom: 0px;
			left: 16px;
			z-index: 100;
			outline: none;
		}

		&.sel {
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/favorites.svg');
			background-color: $yellow-300d;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-color: $transparent;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 16px;
				mask-image: none;
				background-image: url('../../images/IEIcons/favorites_yellow.svg');
			}
		}
	}
}