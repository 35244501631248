﻿.divMainFavourites {
	padding: 0 0 1px 0;
	margin: -5px 0 8px 0;
	border-radius: 5px;
	position: relative;
	@include dvu-font-regular($font-14);
	letter-spacing: 0.8px;
	font-weight: 300;

	.empty {
		padding: 6px 8px 8px 8px;
		color: #d6d6d6;
		text-align: center;
		line-height: 14px;
	}

	.title {
		@include dvu-titlebar();
	}

	.content {
		position: relative;
	}

	.item {
		position: relative;
		height: 31px;
		line-height: 27px;

		a {
			display: block;
			text-decoration: none;
			color: $white;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 32px;
			height: 31px;
			line-height: 32px;
			border-bottom: 1px solid $grey-500d;
			width: 100%;
			background: $grey-300d;

			&:hover {
				color: $yellow-200d;
			}
		}

		&:last-child {
			background-image: none;

			a {
				border-radius: 6px;
			}
		}

		.del {
			position: absolute;
			left: 6px;
			top: 6px;
			height: 20px;
			width: 20px;
			cursor: pointer;
			border-bottom: none;
			padding-left: 0px;
			background-color: $grey-400d;
			border-radius: 4px;
			text-align: center;

			&:after {
				content: '';
				display: inline-block;
				width: 12px;
				height: 12px;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/favorites.svg');
				-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/favorites.svg');
				background-color: $yellow-300d;
				position: relative;
				bottom: 5px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-color: transparent;
				}
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-position: center;
				background-repeat: no-repeat;
				mask-image: none;
				background-image: url('../../images/IEIcons/favorites_yellow.svg');
			}

			&:hover {

				&:after {
					background-color: $grey-100d;
					mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/favorites.svg');
					-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/favorites.svg');
				}
			}
		}
	}

	.deleteAll {
		@include dvu-button-small-m();
		width: 120px;
		position: relative;
		left: 6px;
		top: 6px;
		margin-bottom: 12px;
		cursor: pointer;
	}

	.selectAll {
		@include dvu-button-small-m();
		width: 120px;
		position: relative;
		left: 6px;
		top: 6px;
		margin-bottom: 12px;
		cursor: pointer;

		&:hover:active {
			font-weight: normal;
			opacity: 0.7;
		}
	}
}
