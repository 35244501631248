.timeoutMessage {
  font-size: $font-14;
  line-height: 16px;
  background: $white;
  color: $black;
  position: relative;
  margin: auto;
  border-radius: 6px;
  padding: 10px 10px 10px 42px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 42px;
    height: 100%;
    mask-size: 22px;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  &.success {
    background: $green-100;

    &:before {
      background: $green-300;
      mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/success.svg');
    }
  }

  &.warning {
    background: $yellow-100;

    &:before {
      background: $yellow-400;
      mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
    }
  }

  &.error {
    background: $red-100;

    &:before {
      background: $red-300;
      mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg');
    }
  }

  &.info {
    background: $white;

    &:before {
      background: $grey-200;
      mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/info.svg');
    }
  }
}
