﻿#centralCasinoGamesWidget {
	background-color: $transparent;
	border-radius: 0px;
	margin: 0 0 8px 0;

	.HomeCNTitleCentralCasinoGames {
		@include dvu-titlebar();
		border: none;
	}

	.centralCasinoGamesContainer {
		display: flex;
		position: relative;
		justify-content: space-between;
		margin: 0px auto 6px auto;
		padding: 0px;
		width: 100%;
		height: auto;
		overflow: hidden;
		text-align: unset;
		z-index: 0;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
		}

		&:last-of-type {
			margin-bottom: 0px;
		}

		a {
			display: inline-block;
			width: calc(12.5% - 6px);
			height: auto;
			margin: 0px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				width: calc(12.2% - 6px);
				margin-right: 6px;

				&:last-of-type {
					margin-right: 0px;
				}
			}

			&:hover {
				box-shadow: inset 0px 0px 0px 3px $yellow-400d;
			}

			img {
				display: inline-block;
				position: relative;
				width: 100%;
				height: auto;
				z-index: -1;
			}
		}
	}
}
