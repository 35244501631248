﻿div[id$='updpnlMainAutolimitation'] {
	font-size: $font-12;

	.autolimitationInfo {
		text-align: center;
		color: $grey-150d;
	}

	.validation-error {
		margin-left: 12px;
	}

	.panel2Autolimitation {
		overflow: hidden;
		margin-bottom: 12px;

		h4 {
			border-radius: 6px 6px 0 0;
			font-size: $font-16;
			line-height: 25px;
			padding: 12px 7px 0;
			text-align: left;
		}

		.autolimitationWrapper {
			background: $grey-300d;
			border-radius: 6px;
			padding: 12px;
			overflow: hidden;

			span {
				&[id$='lblAmountRevokeRequestWager'],
				&[id$='lblPeriodRevokeRequestWager'],
				&[id$='lblRevokeRequestAmountLosses'],
				&[id$='lblRevokeRequestPeriodLosses'] {
					display: inline-block;
					text-align: right;
					width: 45%;
					padding: 10px 3px;
				}
			}

			&.wrapperRevoke{
				.euro, .days{
					padding-left:3px;
				}
			}
		}

		.changeDepositLimit {
			display: none;
		}

		td {
			text-align: left;
		}

		td:first-child {
			width: 45%;
			text-align: right;
		}

		.limitationMessage {
			line-height: 25px;
			text-align: center;
			display: inline-block;
			width: 100%;
		}

		.limitationWarning {
			color: $black;
			text-align: left;
			display: block;
			max-width: 800px;
			background-color: $yellow-100d;
			margin: 0 auto;
			padding: 10px;
			padding-left: 45px;
			line-height: 16px;
			font-size: $font-14;
			border-radius: 6px;
			position: relative;

			&:before {
				mask-image: url(../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg);
				-webkit-mask-image: url(../../images/icons/pw365-iconpack/current/system/regular/solid/warning.svg);
				background-color: $yellow-400d;
				content: '';
				display: inline-block;
				height: 22px;
				position: absolute;
				right: 0px;
				top: 10px;
				width: 22px;
				left: 10px;
			}
		}

		.clear {
			height: 12px;
		}

		input[type="submit"] {
			width:192px !important;
			float: right;
			margin-right: 12px !important;
		}

		&[id$='pnlDepositRevokeRequest'] {
			span {
				float: left;
				line-height: 28px;
				padding-right: 3px;

				&[id$='lblRevokeRequestAmountDeposits'] {
					width: 45%;
					display: inline-block;
					text-align: right;
				}
			}

			input[type="text"] {
				float: left;
			}
		}
	}
}

.user-avanti{
	margin-left: calc(50% - 63px);
}
