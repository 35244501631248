.mainSubheader {
	position: absolute;
	border: 0;
	border-radius: 0;
	height: 128px;
	padding: 0;
	width: 100%;
	z-index: -1;

	.top {
		height: 76px;
		background: $yellow-400d;
	}

	.bottom {
		background: $grey-500d;
		border-radius: 0;
		height: 80px;
	}
}

@supports (grid-area: auto) {
	.mainSubheader {
		border: 0;
		border-radius: 0;
		padding: 0;
		position: relative;
		top: 0;
		width: 100%;
	}
}