﻿.Riquadro.Group {
	.TopSX {
		display: none;
	}
}

#MainContent {
	#groupsDivMain {
		margin-top: unset;
	}
}

#groupsDivMain {
	a {
		&:hover {
			cursor: pointer;
			color: $yellow-200d;
		}
	}

	.BannerGruppi {
		border-radius: 6px;

		a {
			img {
				max-height: 250px;
			}

			span {
				display: none;
			}
		}
	}

	.groupSport {
		border-bottom: 1px solid $grey-200d;
		margin-top: 16px;

		.Sport {
			display: inline-block;

			.chkAllSport {
				position: relative;
				top: 11px;
				left: 10px;
			}

			a {
				@include dvu-titlebar();
				position: relative;
				margin-left: 16px;
				padding: 0px;

				span {

					&:hover {
						color: $yellow-200d;
					}
				}
			}
		}

		.divBtnSx {
			display: inline-block;
			right: 6px;
			top: 8px;
			font-size: 0px;
			width: 50px;
			text-align: right;
			float: right;
			padding: 6px 6px 0 0;

			IMG {
				margin-left: 2px;
				border: 0;
				display: none;
			}

			INPUT {
				margin: 0;
				padding: 0;
			}

			a {
				@include dvu-button-play();

				&.lkPr {
					float: left;
					background-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/print.svg');
					background-position: center;
				}
			}
		}
	}

	.groupsMainTbl {

		.groupsTblGroups {

			DIV:not(.GroupSep):not(.ImgGroup):not(.leagueItem) {
				padding: 0px 0 16px 10px;
				background-color: $grey-300d;
				border-radius: 6px;
				display: grid;
				grid-template-columns: repeat(5,1fr);

				@media (min-width:1486px) and (max-width: 1630px) {
					grid-template-columns: repeat(4,1fr);
				}

				@media screen and (max-width: 1485px) {
					grid-template-columns: repeat(3,1fr);
				}
			}

			.leagueItem {
				padding-top: 16px;

				.checkboxGroups {
					display: inline-block;
					position: static;
					width: 100%;

					a {
						padding: 1px 0 0 6px;
						display: inline-block;
						line-height: 16px;
						width: calc(100% - 20px);
						font-size:$font-14
					}

					.chkLeague {
						position: static;
					}

					input {
						top: 4px;
						position: absolute;
					}
				}
			}

			.groupsGroup {
				@include dvu-titlebar();
				padding-top: 8px;

				.ImgGroup {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					display: inline-block;
					border: 1px solid $grey-500d
				}
			}

			.wdZero {
				width: 26px;
				padding-right: 6px;

				A {
					@include dvu-button-play();
					display: block;
				}
			}
		}
	}
	
	.noEvents {
		text-align: center;
		color: $grey-150d;
		font-weight: 500;
		font-size: $font-13;
		padding-top: 25px;
	}
}
