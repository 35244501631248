﻿#optiRealPopupDimmer {
	opacity: 0.6 !important;

	& + div {
		width: 90%;
		max-width: 850px !important;
		height: 90%;
		max-height: 455px !important;
		border-radius: 6px;
		overflow: hidden;
		color: $grey-300d;

		& > div {
			width: 100%;
			height: 100%;

			& > div:nth-of-type(1) {
				top: 5px !important;
				right: 5px !important;
				width: 15px;
				height: 15px;
				background-color: $grey-150d;
				mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
				mask-position: center;
				mask-repeat: no-repeat;

				& > img {
					opacity: 0;
				}
			}

			& > div:nth-of-type(2) {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;

				& > .optiHeader {
					flex: 0 0 48px;
					border-bottom: 1px solid rgba($grey-100d, 0.3);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					font-weight: 500;
					line-height: normal;
				}

				& > .optiContentScrollContainer {
					flex: 1 1 auto;
					margin: 10px 4px;

					.jspPane {
						min-height: 100%;
						display: flex;

						.optiContent {
							margin: 0px 18px 0px 20px;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: center;
							width: 100%;

							&.center {
								justify-content: center;
							}

							& > img {
								width: 100%;
								border-radius: 6px;
							}

							& > p {
								width: 100%;
								text-align: center;
							}

							& > :nth-child(n) {
								margin-top: 5px;
								margin-bottom: 5px;
							}

							& > :first-child {
								margin-top: 0;
							}

							& > :last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				& > .optiFooter {
					flex: 0 0 64px;
					border-top: 1px solid rgba($grey-100d, 0.3);
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 5px;

					& > a {
						@include dvu-button-green-small-m();
						text-transform: none;
						line-height: 1;
						padding-left: 6px;
						padding-right: 6px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;

						&:first-letter {
							text-transform: none;
						}
					}

					& > :nth-child(n) {
						margin-left: 2.5px;
						margin-right: 2.5px;
					}

					& > :first-child {
						margin-left: 0;
					}

					& > :last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}
