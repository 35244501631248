﻿//DVU NOTE: This file should be refactored, from css to sass.
.NewUserFormRegistration {
  div[id$="calendarWrapper"] {
    width: 280px;
}}

.calendarIco {
	background-color: $white;
	mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/calendar-dates.svg');
	-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/o/calendar-dates.svg');
	mask-size: 16px;
	-webkit-mask-size: 16px;
	mask-position: 5px;
	-webkit-mask-position: 5px;
	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
  position: relative;
  display: inline-block;
  right: 35px;
  vertical-align: middle;

	&:hover {
		background-color: $yellow-200d;
	}

	img {
		opacity: 0;
    display: inline-block;
    width: 26px;
	}
}

.popupControl {
	z-index: 10;
}

.CalendarExternalTableStyle {
	background-color: $white;
	font-weight: bold;
	color: $black;
	border-radius:6px;

	table{
		border: 0 !important;
	}
}

.CalendarLinkButStyle,
.CalendarLinkButStyleW {
	font-weight: bold;
	color: $black;
}

.CalendarLinkButStyleW {
	color: $black;
}

.CalendarLinkButStyle:hover,
.CalendarLinkButStyleW:hover {
	font-weight: bold;
	color: $black;
	text-decoration: none;
}

.CalendarLinkButStyleW:hover {
	color: $yellow-400d;
}

.CalendarStyle {

	tr:first-child {
		td:first-child {
			background-color: $yellow-400d !important;
		}
	}

	tr:nth-child(n+3) td:not(.CalendarTodayStyle) {

		&:hover {
			background-color: $yellow-100d;
		}
	}
}

.CalendarSelectedDayStyle {
	//
}

.CalendarTodayStyle {
	background-color: #ffbb03;
}

.CalendarSelectorStyle {
	background-color: #ffbb03;
}

.CalendarWeekendDayStyle {
	//
}

.CalendarNextPrevStyle {

	a {

		&:hover {
			color: $white !important;
		}
	}
}

.CalendarDayHeaderStyle {
	background-color: $grey-100d;
	color: $black;
}

.CalendarTitleStyle {
	background-color: $yellow-400d;
	color: #000;
	font-weight: bold;
}
